import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import {getData} from "@/helpers/getData";

export default {
  [actionTypes.PAGE_INFO] (context, form = {}){
      /**
       * Sent User Login Request
       */
      return getData({
          method: 'GET',
          url: 'page/type',
          data: form
      }, context).then(response => {
          if (response.data) {
              context.commit(mutationTypes.PAGE_INFO, response.data)
          } else if (response) {
              context.commit(mutationTypes.PAGE_INFO, response)
          }
          /**
           * Return Response
           */
          return response
      })
  },

}
