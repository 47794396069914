<template>
  <div class="pagination">
      <div class="pagination__inner">
        <div class="pagination__switcher pagination__switcher--left" @click="switchNum(false)">
            <img src="../../assets/icons/pagination-left-disable.png">
        </div>
        <div class="pagination__numbers">
            <router-link :to="`${url}?page=${number}`" v-for="(number,i) in numbers" :key="i" @click="paginate(number,i + 1)" class="pagination__item" :class="isChoosen(number) && 'pagination__item--active'">
                <img src="../../assets/icons/pagination-dots.png" v-if="!!!number"/>
                <span :ref="`page-link-${number}`" v-else>{{ number }}</span>
            </router-link>
        </div>
        <div class="pagination__switcher pagination__switcher--right" @click="switchNum(true)">
            <img src="../../assets/icons/pagination-right-active.png">
        </div>
    </div>
  </div>
</template>

<script>
import url from "@/mixins/url";

export default {
    props:{
        totalPages: {
            type: Number
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },
    mixins: [
      url
    ],
    data(){
        return{
            numbers: [],
            choosenNumber: this.currentPage,
            choosenIndex: this.currentPage
        }
    },
    computed: {
      url () {
        return this.goToUrl(this.$route.name);
      },
      currentPages(){
          return this.$route.query.page;
      }
    },
    methods:{
        isChoosen(number){
            return this.choosenNumber === number;
        },
        switchNum(action){
            if(action){
                if(this.choosenNumber < this.totalPages){
                    this.paginate(this.choosenNumber + 1,this.numbers.indexOf(this.choosenNumber + 1) + 1);
                }
            }else if(this.choosenNumber > 1){
                    this.paginate(this.choosenNumber - 1,this.numbers.indexOf(this.choosenNumber + 1) - 1);
            }
        },
        paginate(number,i){
            //increase
            if(this.totalPages > 7){
                if(number > 4 && number > this.choosenNumber && number < this.totalPages - 1 && this.numbers[this.numbers.length - 2] === 0){
                    this.numbers.splice(i,2,...[number + 1,number + 2]);
                    if(!(this.totalPages - this.numbers[i - 1] <= 3)){
                        this.numbers.splice(i + 2,1,0);
                    }
                    if(i === 6){
                        this.numbers.splice(1,3,0);
                        this.numbers.splice(8,1,this.totalPages);
                    }else{
                        this.numbers.splice(1,2,0);
                        this.numbers.splice(9,1,this.totalPages);
                    }
                    if(this.numbers[this.numbers.length - 2] === this.totalPages){
                        this.numbers.splice(this.numbers.length - 1,1);
                        this.numbers.splice(2,0,number - 2);
                    }
                //reduce
                }else if(number < this.choosenNumber && number !== 0 && this.choosenNumber !== this.totalPages){
                    if(number == 4){
                        this.numbers.splice(i - 2,1,...[2,3]);
                        this.numbers.splice(i + 2,1);
                        this.numbers.splice(this.numbers.length - 3,1);
                    }else if(number > 4){
                        if(i === 3){
                            this.numbers.splice(i + 1,2);
                            this.numbers.splice(i - 1,0,...[number - 2,number - 1]);
                            this.numbers.splice(i + 2,1,number + 1);
                            if(this.numbers[this.numbers.length - 2] !== 0){
                                this.numbers.splice(this.numbers.length - 1,1,this.totalPages);
                                this.numbers.splice(this.numbers.length - 1,0,0);
                            }
                        }
                        this.numbers[1] = 0;
                        this.numbers[0] = 1;
                    }
                }
            }
            if(number !== 0){
                this.choosenNumber = null;
                this.choosenNumber = number;
                this.choosenIndex = null;
                this.choosenIndex = i;
            }
            this.$refs[`page-link-${number}`].click();
        },
        reducePagination(index){
            if(this.totalPages > 7){
                this.numbers = this.numbers.splice(0,10);
                this.numbers.splice(index,4);
                this.numbers.splice(index,0,0);
                this.numbers.splice(6,1,this.totalPages);
            }
        },
        createArray(){
            for(let i = 0; i < this.totalPages; i++){
                this.numbers.unshift(this.totalPages - i);
            }
        }
    },
    mounted(){
        this.createArray();
        this.reducePagination(5);
    }
}
</script>

<style scoped lang="scss">
.pagination{
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    &__inner{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__switcher{
        width: 40px;
        display: flex;
        cursor: pointer;
        &--left{
            justify-content: flex-start;
        }
        &--right{
            justify-content: flex-end;
        }
    }
    &__numbers{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // width: 100%;
        font-size: 14px;
        font-weight: 400px;
        font-family: 'Helvetica Neue';
        // max-width: 350px;
    }
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        color: white;
        cursor: pointer;
        margin-left: 10px;
        &--active{
            background: white;
            color: black;
        }
    }
}
</style>
