<template>
  <div class="list__item">
    <div class="list__label is-flex text-uppercase">
      {{ label }}

      <button v-if="toggleable" type="button" class="button button--link text-uppercase margin-left-auto" @click="toggle">
        {{ toggleLabel }}
      </button>
    </div>
    <div class="list__value">{{ value }}</div>

    <template v-if="visible && toggleable">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import emitter from "@/plugins/bus";

export default {
  props: {
    label: {
      type: String
    },

    value: {
      type: String
    },

    toggleOff: {
      type: String
    },

    toggleOn: {
      type: String
    },

    toggleable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      visible: false
    }
  },

  computed: {
    toggleLabel () {
      return this.visible ? this.toggleOn : this.toggleOff
    }
  },

  methods: {
    toggle () {
      this.visible = !this.visible
    }
  },

  mounted() {
    emitter.on('closeListToggleable', () => {
      this.visible = false
    })
  }
}
</script>
