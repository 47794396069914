<template>
  <div class="card">
    <div class="card__body">
      <carousel :options="options" :grid="1">
        <slide
            v-for="item in items"
            :key="item"
            :slide="item"
        >
          <div class="card__overlink">
            <router-link :to="item.buttonUrl" />
          </div>

          <template v-if="!isVideo(item)">
            <img :src="getImage(item)">
          </template>

          <template v-else>
            <video-player
                :url="item.image"
            />
          </template>

          <h3 class="card__title" v-if="item.title">{{  item.title }}</h3>
          <template v-if="item.buttonTitle">
            <a :href="item.buttonUrl" class="button button--light">{{ item.buttonTitle }}</a>
          </template>
        </slide>
      </carousel>
    </div>
    <!-- /.card__body -->
  </div>
  <!-- /.card -->
</template>

<script>
// components
import Carousel from "@/components/Sliders/Slider/Carousel"
import Slide from "@/components/Sliders/Slider/Slide"
import VideoPlayer from "@/components/Video/VideoPlayer"

export default {
  components: {
    Carousel,
    Slide,
    VideoPlayer
  },

  props: {
    items: Array
  },

  data: () => {
    return {
      options: {
        autoPlay: true,
        autoPlaySeconds: 5,
        arrows: false,
        paginationProgress: false
      },
    }
  },

  methods: {
    getImage (slide) {
      return slide.image
    },

    isVideo (slide) {
      return slide.image.includes('.mp4') || slide.image.includes('.webm')
    }
  }
}
</script>
