<template>
  <GoogleMap
      api-key="AIzaSyAON4jF8mLwGb0WZ1yQIO0IAVPUPtj07h8"
      class="google-map"
      :center="center"
      :styles="mapStyle"
      :disableDefaultUi="true"
      :zoom="10"
  >
    <Marker v-for="(item,key) in coordinates" :key="key" :options="{ position: item.location}" />
  </GoogleMap>
</template>

<script>

import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  components: {
    GoogleMap,
    Marker
  },

  props: {
    coordinates: {
      type: Array
    }
  },

  data () {
    return {
      center: {lat: 41.73075606950895, lng: 44.7679654866979},
      mapStyle: [
        {elementType: 'geometry', stylers: [{color: '#323648'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#1F1F22'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#49494D'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'poi',
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#2A2C38'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#2A3040'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#212a37'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#746855'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#1f2835'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#2f3948'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#17263c'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#17263c'}]
        }
      ]
    }
  }
}
</script>
