<template>
  <video
      autoplay
      muted
      loop
      playsinline
      ref="customVideo"
      class="custom-video"
      @canplay="onCanPlay"
  >
    <source
        :src="videoSrc"
        type="video/mp4"
    >
  </video>
</template>

<script>
export default {
  props: {
    options: {
      type: Object
    },

    url: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      stateCanPlay: false,
      defaultOptions: {
        width: '800px',
        height: '600px'
      },
      videoSrc: ''
    }
  },

  watch: {
    'stateCanPlay' (status) {
      if (status) {
        this.$refs.customVideo.play()
      }
    },

    'url' (value) {
      this.videoSrc = value
      this.$refs.customVideo.load()
    }
  },

  methods: {
    /**
     * Can play state
     */
    onCanPlay () {
      this.stateCanPlay = true
    }
  },

  mounted() {
    this.videoSrc = this.url
  }
}
</script>
