<template>
  <template v-if="url">
    <template v-if="isExternal(url)">
      <a :href="url" target="_blank">
        <slot />
      </a>
    </template>

    <template v-else>
      <router-link :to="url">
        <slot />
      </router-link>
    </template>
  </template>
</template>

<script>
import {validURL} from "@/helpers/url";

export default {
  props: {
    url: {
      type: String
    }
  },

  methods: {
    isExternal (url) {
      return validURL(url)
    },
  }
}
</script>
