<template>
  <div class="page page--gallery has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('gallery.title') }}</h1>

      <carousel :options="options" v-if="slides.length">
        <slide
          v-for="(slide, index) in slides"
          :key="index"
          :slide="index"
        >
          <img v-lazy="slide.image">
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Sliders/Slider-3d/Carousel"
import Slide from "@/components/Sliders/Slider-3d/Slide"
import {getData} from "@/helpers/getData";
import {getStaticPage} from "@/helpers/module";
import {GALLERY_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Gallery',

  components: {
    Carousel,
    Slide
  },

  data: () => {
    return {
      slides: []
    }
  },
  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      const responseData = getStaticPage(data, false);

      responseData.gallery.map((galleryData) => {
        galleryData.fields.images.value.map((imageUrl) => {
          this.slides.push({
            image: imageUrl.full_src ? imageUrl.full_src.full_src : ''
          });
        });
      });
    },

    onError (e) {
      console.log(e)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(GALLERY_ROUTE).then(response => {
      next(vm => {
          vm.$store.commit(PAGE_INFO, response.data.page_data)
          vm.setData(response)
          vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(GALLERY_ROUTE).then(response => {
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
