<template>
  <div class="header__burger" v-if="visible">
    <div class="header__burger-header">
      <router-link :to="homeRoute">
        <img src="/assets/betlive-logo.svg" class="header__brand" alt="სლოტ კლუბი - სამორინე Betlive Club">
      </router-link>

      <button class="header__burger-close" @click="close">
        <i class="icon icon-close"></i>
      </button>
    </div>

    <div class="header__burger-body">
      <!--<div class="header__burger-search">
        <form class="form">
          <div class="form__addon">
            <i class="icon icon-loupe"></i>
          </div>
          <input type="text" name="search" class="form__control form__control--pill" placeholder="Search...">
        </form>
      </div>-->

      <ul class="nav nav--vertical header__burger-nav">
        <header-burger-menu-dropdown>
          <template v-slot:parent>
            <router-link :to="aboutRoute" class="nav__link">
              <i class="iconly iconly-boorkmark"></i> {{ $t('menu.about') }}
            </router-link>
          </template>

          <template v-slot:child>
            <ul class="nav">
              <li class="nav__item">
                <router-link :to="careerRoute" class="nav__link">{{ $t('menu.career') }}</router-link>
              </li>
              <!-- /.nav__item -->

              <!--<li class="nav__item">
                <router-link :to="providersRoute" class="nav__link">{{ $t('menu.providers') }}</router-link>
              </li>-->
            </ul>
          </template>
        </header-burger-menu-dropdown>

        <li class="nav__item">
          <router-link :to="newsRoute" class="nav__link">
            <i class="iconly iconly-document"></i>{{ $t('menu.news') }}
          </router-link>
        </li>
        <!-- /.nav__item -->

        <!--<li class="nav__item">
          <router-link :to="careerRoute" class="nav__link">
            <i class="iconly iconly-message"></i> {{ $t('menu.career') }}
          </router-link>
        </li>-->
        <!-- /.nav__item -->

        <li class="nav__item">
          <router-link :to="galleryRoute" class="nav__link">
            <i class="iconly iconly-category"></i> {{ $t('menu.gallery') }}
          </router-link>
        </li>
        <!-- /.nav__item -->

        <li class="nav__item">
          <header-burger-menu-dropdown>
            <template v-slot:parent>
              <router-link :to="offersRoute" class="nav__link">
                <i class="iconly iconly-prom"></i> {{ $t('menu.promotions') }}
              </router-link>
            </template>

            <template v-slot:child>
              <ul class="nav">
                <li class="nav__item">
                  <router-link :to="offersRoute" class="nav__link">{{ $t('menu.offers') }}</router-link>
                </li>
                <!-- /.nav__item -->

                <li class="nav__item">
                  <router-link :to="leaderboardRoute" class="nav__link">{{ $t('menu.leaderboard') }}</router-link>
                </li>
                <!-- /.nav__item -->
              </ul>
            </template>
          </header-burger-menu-dropdown>
        </li>
        <!-- /.nav__item -->

        <li class="nav__item">
          <router-link :to="cardRoute" class="nav__link">
            <i class="iconly iconly-doc"></i> {{ $t('menu.clubCard') }}
          </router-link>
        </li>

        <li class="nav__item">
          <router-link :to="blogRoute" class="nav__link">
            <i class="iconly iconly-doc"></i> {{ $t('menu.blog') }}
          </router-link>
        </li>
        <!-- /.nav__item -->
      </ul>

      <header-translation />
    </div>
  </div>
</template>

<script>
//components
import HeaderBurgerMenuDropdown from "@/components/Partials/Header/HeaderBurgerMenuDropdown"
import HeaderTranslation from "@/components/Partials/Header/HeaderTranslation"

// helpers
import emitter from "@/plugins/bus"
import url from "@/mixins/url";

export default {
  mixins: [
    url
  ],

  components: {
    HeaderBurgerMenuDropdown,
    HeaderTranslation
  },

  data () {
    return {
      visible: false
    }
  },

  watch: {
    '$route' () {
      this.visible = false
    },

    'visible' (value) {
      if (value) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      } else {
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
      }
    }
  },

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    homeRoute () {
      return this.goToUrl('')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    aboutRoute () {
      return this.goToUrl('about-us')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    newsRoute () {
      return this.goToUrl('news')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    galleryRoute () {
      return this.goToUrl('gallery')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    offersRoute () {
      return this.goToUrl('promotions/offers')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotsRoute () {
      return this.goToUrl('promotions/jackpots')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    careerRoute () {
      return this.goToUrl('careers')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    cardRoute () {
      return this.goToUrl('club-card')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    leaderboardRoute () {
      return this.goToUrl('branch/leaderboard')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    providersRoute () {
      return this.goToUrl('provider')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    blogRoute () {
      return this.goToUrl('blog')
    },
  },

  methods: {
    close () {
      this.visible = false
    }
  },

  mounted() {
    emitter.on('showBurgerMenu', () => this.visible = true)
  }
}
</script>
