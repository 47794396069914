import { createRouter, createWebHistory } from 'vue-router'
import {getCurrentLocale, getTranslations, routeMiddleware} from '@/helpers/translation'
import Cookie from 'js-cookie'

// layouts
import Default from '@/layouts/Default.vue'
import WithoutFooter from '@/layouts/WithoutFooter.vue'

// pages
import Home from '@/views/Home.vue'
import jackpotsPromotion from '@/views/Promotions/Jackpots'
import jackpotShowPromotion from '@/views/Promotions/JackpotShow'
import offersPromotion from '@/views/Promotions/Offers'
import offerSingle from '@/views/Promotions/OfferSingle'
import Faq from '@/views/Faq.vue'
import Careers from '@/views/Careers.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import MyProfile from "@/views/Account/MyProfile"
import MyCard from "@/views/Account/MyCard"
import MyCardOffers from "@/views/Account/MyCardOffers"
import ManageBalance from "@/views/Account/ManageBalance"
import Gallery from "@/views/Gallery"
import ClubCard from "@/views/ClubCard"
import About from "@/views/About"
import Leaderboard from "@/views/Leaderboard/Leaderboard"
import News from "@/views/News/News"
import PageNotFound from "@/views/PageNotFound"
import TermsConditions from "@/views/TermsConditions"
import BranchLeaderboard from "@/views/Leaderboard/BranchLeaderboard"
import Player from "@/views/Player"
import Stream from "@/views/Streams/Stream"
import StreamBranch from "@/views/Streams/Branches"
import Provider from "@/views/Provider/Provider"
import Device from "@/views/Device"
import Blog from '@/views/Blog/Blog.vue'
import BlogInner from '@/views/Blog/BlogInner.vue'
import i18n from "@/i18n";

const routes = [
  {
    path: '/',
    redirect: '/' + getCurrentLocale + '/'
  },

  {
    path: '/:locale',
    component: {
      template: '<router-view />'
    },
    children: [
      {
        path: '404',
        name: '404',
        component: PageNotFound,
        meta: {
          layout: Default,
          title: 'homePage'
        }
      },

      {
        path: '/:pathMatch(.*)*',
        redirect: '404'
      },

      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          layout: Default,
          title: 'homePage'
        }
      },

      {
        path: 'account',
        name: 'MyProfile',
        component: MyProfile,
        meta: {
          layout: Default,
          auth: true,
          title: 'accountPage'
        }
      },

      {
        path: 'account/card',
        name: 'MyCard',
        component: MyCard,
        meta: {
          layout: Default,
          auth: true,
          title: 'accountCardPage'
        }
      },

      {
        path: 'account/card/offers',
        name: 'MyCardOffers',
        component: MyCardOffers,
        meta: {
          layout: Default,
          auth: true,
          title: 'accountCardPage'
        }
      },

      {
        path: 'account/balance',
        name: 'ManageBalance',
        component: ManageBalance,
        meta: {
          layout: Default,
          auth: true,
          title: 'accountBalancePage'
        }
      },

      {
        path: 'promotions/jackpots',
        name: 'Jackpots',
        component: jackpotsPromotion,
        meta: {
          layout: Default,
          title: 'jackpotsPage'
        }
      },

      {
        path: 'promotions/jackpots/:branchId',
        name: 'Jackpot',
        component: jackpotShowPromotion,
        meta: {
          layout: Default,
          title: 'jackpotShowPage'
        }
      },

      {
        path: 'promotions/offers',
        name: 'Offers',
        component: offersPromotion,
        meta: {
          layout: Default,
          title: 'offersPage'
        }
      },

      {
        path: 'promotions/offers/:id',
        name: 'OffersSingle',
        component: offerSingle,
        meta: {
          layout: WithoutFooter,
          title: 'offerSinglePage'
        }
      },

      {
        path: 'privacy',
        name: 'Privacy',
        component: PrivacyPolicy,
        meta: {
          layout: Default,
          title: 'privacyPage'
        }
      },

      {
        path: 'terms-conditions',
        name: 'TermsCondition',
        component: TermsConditions,
        meta: {
          layout: Default,
          title: 'TermsConditionPage'
        }
      },

      {
        path: 'faq',
        name: 'FAQ',
        component: Faq,
        meta: {
          layout: Default,
          title: 'faqPage'
        }
      },

      {
        path: 'careers',
        name: 'Careers',
        component: Careers,
        meta: {
          layout: Default,
          title: 'careersPage'
        }
      },

      {
        path: 'gallery',
        name: 'Gallery',
        component: Gallery,
        meta: {
          layout: Default,
          title: 'galleryPage'
        }
      },

      {
        path: 'club-card',
        name: 'Club Card',
        component: ClubCard,
        meta: {
          layout: Default,
          title: 'clubCardPage'
        }
      },

      {
        path: 'about-us',
        name: 'About',
        component: About,
        meta: {
          layout: Default,
          title: 'aboutPage'
        }
      },

      // {
      //   path: 'leaderboard',
      //   name: 'Leaderboard',
      //   component: Leaderboard,
      //   meta: {
      //     layout: Default,
      //     title: 'leaderboardPage'
      //   }
      // },
      {
        path: 'branch/leaderboard',
        name: 'branchLeaderboard',
        component: BranchLeaderboard,
        meta: {
          layout: Default,
          title: 'branchLeaderboardPage'
        }
      },
      {
        path: 'branch/leaderboard/:branchId',
        name: 'branchLeaderboardInside',
        component: Leaderboard,
        meta: {
          layout: Default,
          title: 'branchLeaderboardPage'
        }
      },

      {
        path: 'news',
        name: 'News',
        component: News,
        meta: {
          layout: Default,
          title: 'newsPage'
        }
      },
      {
        path: 'provider',
        name: 'Provider',
        component: Provider,
        meta: {
          layout: Default,
          title: 'providerPage'
        }
      },

      {
        path: 'player',
        name: 'Player',
        component: Player,
        meta: {
          layout: Default,
          title: 'playerPage'
        }
      },

      {
        path: 'stream',
        name: 'Stream',
        component: StreamBranch,
        meta: {
          layout: Default,
          title: 'streamBranchesPage'
        }
      },

      {
        path: 'stream/:branchId',
        name: 'StreamShow',
        component: Stream,
        meta: {
          layout: Default,
          title: 'streamShow'
        }
      },

      {
        path: 'app',
        name: 'Device',
        component: Device,
        meta: {
          layout: Default,
          title: 'app'
        }
      },

      {
        path: 'app;:qrId',
        name: 'DeviceShow',
        component: Device,
        meta: {
          layout: Default,
          title: 'app'
        }
      },
      {
        path: 'blog',
        name: 'blog',
        component: Blog,
        meta: {
          layout: Default,
          title: 'blog'
        }
      },
      {
        path: 'blog/:id',
        name: 'blogInner',
        component: BlogInner,
        meta: {
          layout: Default,
          title: 'blog inner'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const locale = to.params.locale
  return getTranslations(locale).then(data => {
    i18n.global.setLocaleMessage(locale, data.data.text)

    next()
  })
})

router.beforeEach((to, from, next) => {
  return routeMiddleware(to, from, next)
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    if (Cookie.get('token')) {
      next()
    } else {
      next('/' + getCurrentLocale + '/');
    }
  } else {
    next();
  }
})

export default router
