<template>
  <div class="header__translation" v-click-away="hide">
    <ul class="nav nav--horizontal has-line">
      <li class="nav__item is-dropdown is-clickable" :class="showTranslations" @click="toggleTranslations">
        <button type="button" class="nav__link is-flex align-items-center">
          <i class="icon icon-earth-grid"></i> {{ $t(currentLocaleName) }}
        </button>

        <div class="nav__dropdown header__dropdown">
          <ul class="nav">
            <template v-for="(locale, index) in locales" :key="index">
              <li class="nav__item" v-if="current !== locale.locale">
                <router-link :to="{ path: replaceLocale(locale.locale) }" class="nav__link">{{ $t(locale.name) }}</router-link>
              </li>
              <!-- /.nav__item -->
            </template>
          </ul>
        </div>
      </li>
      <!-- /.nav__item -->
    </ul>
  </div>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import * as Cookie from "js-cookie";

export default {
  mixins: [VueClickAway],

  data () {
    return {
      current: 'en',
      visible: false,
      locales : [
        {
          name: 'header.georgiaLocale',
          locale: 'ka'
        },

        {
          name: 'header.englishLocale',
          locale: 'en'
        }
      ]
    }
  },

  watch: {
    '$route' () {
      this.$i18n.locale = Cookie.get('locale')
      this.current = this.$i18n.locale

      this.visible = false
    }
  },

  computed: {
    /**
     *
     * @returns {({name: string, locale: string}|{name: string, locale: string}|{name: string, locale: string})[]}
     */
    getLocales () {
      let list = []

      this.locales.filter(item => {
        if (item.locale !== this.current) {
          list.push(item)
        }
      })

      return list
    },

    currentLocale () {
      return this.locales.filter(item => item.locale === this.$i18n.locale)[0]
    },

    currentLocaleName () {
      if (this.currentLocale) {
        return this.currentLocale.name
      }
      return this.locales[0].name
    },

    showTranslations () {
      return this.visible ? 'active' : ''
    }
  },

  methods: {
    toggleTranslations () {
      this.visible = !this.visible
    },

    replaceLocale (locale) {
      return this.$route.fullPath.replace(this.$i18n.locale, locale)
    },

    hide () {
      this.visible = false;
    }
  },

  mounted() {
    this.$i18n.locale = Cookie.get('locale')

    this.current = this.$i18n.locale
  }
}
</script>
