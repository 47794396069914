<template>
  <router-link :to="blogLink" class="blog-item">
      <div class="blog-item__image">
          <img :src="data.thumbnail_image_src"/>
          
          <!-- <img :src="data.desktop_image_src" class="desktop-blog-image"/>
          <img :src="data.mobile_image_src" class="mobile-blog-image"/> -->
      </div>
      <div class="blog-item__text">
          <div class="blog-item__date">
              <span>{{ data.created_at }}</span>
          </div>
          <p class="blog-item__title">{{ data.title }}</p>
          <div class="blog-item__description" v-html="data.short_description"></div>
      </div>
  </router-link>
</template>

<script>
import url from "@/mixins/url";

export default {
    mixins: [
        url
    ],
    props: {
        data:{
            type: Object,
            required: true
        }
    },
    computed:{
        blogLink(){
            return this.goToUrl('blog') + `/${this.data.slug}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.blog-item{
    display: grid;
    display: grid;
    grid-template-rows: 55% 45%;
    color: white;
    &__image{
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__date{
        padding: 0px 5px 0px 5px;
        border-radius: 5px;
        background: #EA5651;
        width: fit-content;
        margin-top: 10px;
        margin-block: 10px;
        span{
            font-size: 12px;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &__description{
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}
.mobile-blog-image{
    display: none;
}
@media(max-width: 768px){
    .mobile-blog-image{
        display: block;
    }
    .desktop-blog-image{
        display: none;
    }
}
</style>