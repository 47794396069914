<template>
  <div class="blog-inner">
      <div class="blog-inner__date">
         <span>{{ blog.created_at }}</span>
      </div>
      <h1 class="blog-inner__title">{{ blog.title }}</h1>
      <div class="blog-inner__image">
        <img :src="blog.desktop_image_src" class="desktop-blog-image"/>
        <img :src="blog.mobile_image_src" class="mobile-blog-image"/>
      </div>
      <div class="blog-inner__text" v-if="blog.description" v-html="filterDescription(blog.description)"></div>
  </div>
</template>

<script>
import { getData } from "@/helpers/getData"
import {PAGE_INFO} from "../../store/action-types";

export default {
    data(){
        return {
            blog: {}
        }
    },
    methods:{
        async fetchBlog(){
            await getData({
                methods: 'GET',
                url: `blog/${this.$route.params.id}`
            })
            .then(({ data }) => {
                this.$Progress.finish();
                this.blog = data.item;
            })
        },
        getIndicesOf(searchStr, str, caseSensitive) {
            const searchStrLen = searchStr.length;
            if (searchStrLen === 0) {
                return [];
            }
            let startIndex = 0, index, indices = [];
            if (!caseSensitive) {
                str = str.toLowerCase();
                searchStr = searchStr.toLowerCase();
            }
            while ((index = str.indexOf(searchStr, startIndex)) > -1) {
                indices.push(index);
                startIndex = index + searchStrLen;
            }
            return indices;
        },
        filterDescription(txt){
            let text = txt;
            const beforeIndexes = this.getIndicesOf("<h2>", text);

            beforeIndexes.forEach((index,i) => {
                let insertWith = `<div class="blog-inner__description-slider"><div class="left-slider-quote"><img width="45" src="${require('../../assets/icons/leftquotemark.png')}"/></div><div class="slider-center">`;
                let ind = index + (i * insertWith.length);
                text = text.slice(0,ind) + insertWith  + text.slice(ind);
            })

            const afterIndexes = this.getIndicesOf("</h2>", text);

            afterIndexes.forEach((index,i) => {
                let insertWith = `</div><div class="right-slider-quote"><img width="45" src="${require('../../assets/icons/rightquotemark.png')}"/></div></div>`;
                let ind = index + (i * insertWith.length);
                text = text.slice(0,ind + 5) + insertWith + text.slice(ind + 5);
            })

            return text;
        }
    },
    watch:{
        '$i18n.locale'(){
            this.fetchBlog();
        }
    },
    mounted(){
        // this.fetchBlog();
    },

    beforeRouteEnter: (to, from, next) => {
      /**
       * Send request
       */
      getData({
        method: 'GET',
        url: `blog/${to.params.id}`
      }).then(response => {
        /**
         * Enter page and set data
         */
        next(vm => {
          vm.$store.commit(PAGE_INFO, response.data.page_data)
          vm.blog = response.data.item;
          vm.$Progress.finish()
        })
      })
    },
}
</script>
<style lang="scss">

 .blog-inner__description-slider{
    width: 100%;
    background: #2A2E3B;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    h2{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 30px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
    }
    .slider-center{
        margin-top: 75px;
        margin-bottom: 80px;
    }
}
.left-slider-quote{
    position: absolute;
    left: 30px;
    top: 30px;
}
.right-slider-quote{
    position: absolute;
    right: 30px;
    bottom: 30px;
}
</style>
<style scoped lang="scss">
.blog-inner{
    width: 1080px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    &__image{
        width: 100%;
        overflow: hidden;
        border-radius: 16px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__date{
        padding: 0px 5px 0px 5px;
        border-radius: 5px;
        background: #EA5651;
        width: fit-content;
        margin-top: 10px;
        margin-block: 10px;
        span{
            font-size: 12px;
        }
    }
    &__title{
        font-size: 28px;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 30px;
        line-height: 38px;
    }
    &__text{
        margin-top: 30px;
    }
}
.mobile-blog-image{
    display: none;
}
@media(max-width: 768px){
    .mobile-blog-image{
        display: block;
    }
    .desktop-blog-image{
        display: none;
    }
}
</style>