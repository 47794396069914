import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import router from './router'
import lazyPlugin from 'vue3-lazy'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'

import './assets/scss/app.scss'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

const options = {
  color: "#db3d4e",
  failedColor: "#db3d4e",
  thickness: "2px",
  transition: {
    speed: "0.2s",
    opacity: ".1s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

export default createApp(App)
  .use(router)
  .use(VueProgressBar, options)
  .use(i18n)
  .use(store)
  .use(Vue3VideoPlayer, {
    lang: 'en-Us'
  })
  .use(lazyPlugin, {
    loading: '',
    error: ''
  })
  .mount('#app')
