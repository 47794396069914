<template>
  <div class="page page--faq">
    <div class="container">
      <h1 class="page__title">{{ $t('career.title') }}</h1>

      <accordion
          :data="data"
          title="title"
          content="content"
          :button="true"
          :button-text="$t('career.sendCv')"
      ></accordion>
      <career-modal />
    </div>
  </div>
</template>

<script>
// helpers
import { getData } from "@/helpers/getData"
import { getStaticPage } from "@/helpers/module"

// components
import Accordion from "@/components/Accordion/Accordion"
import CareerModal from "@/components/Modals/CareerModal"
import {CAREER_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Careers',

  components: {
    Accordion,
    CareerModal
  },

  data () {
    return {
      data: {}
    }
  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = getStaticPage(data, false)
    }
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(CAREER_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(CAREER_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
