export default {
  methods: {
    /**
     *
     * @param to
     * @returns {string}
     */
    goToUrl (to) {
      return '/' + this.$i18n.locale + '/' + to
    }
  }
}
