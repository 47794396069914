<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__item">
          <router-link :to="homeRoute">
            <img src="/assets/betlive-logo.svg" class="header__brand" alt="სლოტ კლუბი - სამორინე Betlive Club">
          </router-link>

          <div class="footer__copyright">
            <span class="badge badge--primary badge--pill">+25 </span>
            <p class="hide-md-and-down">{{ $t('footer.rightReserved')}}</p>
          </div>
        </div>

        <div class="footer__item">
          <ul class="nav">
            <li class="nav__item">
              <router-link :to="aboutRoute" class="nav__link">{{ $t('footer.aboutUs')}}</router-link>
            </li>
            <li class="nav__item">
              <router-link :to="careerRoute" class="nav__link">{{ $t('menu.career') }}</router-link>
            </li>
          </ul>
        </div>

        <div class="footer__item">
          <ul class="nav">
            <li class="nav__item">
              <router-link :to="termsConditionsRoute" class="nav__link">{{ $t('footer.termsConditions')}}</router-link>
            </li>
            <li class="nav__item">
              <router-link :to="faqRoute" class="nav__link">{{ $t('footer.faq')}}</router-link>
            </li>
          </ul>
        </div>

        <div class="footer__item">
          <ul class="nav">
            <!--<li class="nav__item">
              <router-link to="/" class="nav__link">{{ $t('footer.manageBalance')}}</router-link>
            </li>-->
            <li class="nav__item">
              <router-link :to="privacyPolicyRoute" class="nav__link">{{ $t('footer.privacyPolicy')}}</router-link>
            </li>
            <li class="nav__item">
              <button type="button" class="nav__link unsubscribe" @click="unsubscribe">{{ $t('footer.unsubscribe')}}</button>
            </li>
          </ul>
        </div>

        <div class="footer__item">
          <div class="footer__title">Follow us</div>

          <ul class="nav nav--horizontal nav--pill">
            <li class="nav__item padding-none">
              <a class="nav__link" :href="fbUrl" target="_blank">
                <i class="icon icon-facebook-circle"></i>
              </a>
            </li>
            <li class="nav__item padding-none">
              <a class="nav__link" :href="igUrl" target="_blank">
                <i class="icon icon-instagram"></i>
              </a>
            </li>
            <li class="nav__item padding-none">
              <a class="nav__link" :href="ytUrl" target="_blank">
                <i class="icon icon-youtube"></i>
              </a>
            </li>
            <li class="nav__item padding-none">
              <a class="nav__link" :href="inUrl" target="_blank">
                <i class="icon icon-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <unsubscribe-modal />
    <app-tape />
  </footer>
  <!-- /.footer -->
</template>

<script>
import emitter from "@/plugins/bus"
import UnsubscribeModal from "@/components/Modals/UnsubscribeModal"
import url from "@/mixins/url";
import AppTape from "@/components/Partials/AppTape"

export default {
  mixins: [
    url
  ],

  components: {
    UnsubscribeModal,
    AppTape
  },

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    homeRoute () {
      return this.goToUrl('')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    aboutRoute () {
      return this.goToUrl('about-us')
    },
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    termsConditionsRoute () {
      return this.goToUrl('terms-conditions')
    },
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    faqRoute () {
      return this.goToUrl('faq')
    },
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    careerRoute () {
      return this.goToUrl('careers')
    },
    /**
     *
     *
     * @returns {{params: {locale: *}}}
     */
    privacyPolicyRoute () {
      return this.goToUrl('privacy')
    },

    fbUrl () {
      return process.env.VUE_APP_FB_URL
    },

    ytUrl () {
      return process.env.VUE_APP_YT_URL
    },

    inUrl () {
      return process.env.VUE_APP_IN_URL
    },

    igUrl () {
      return process.env.VUE_APP_IG_URL
    }
  },

  methods: {
    unsubscribe () {
      emitter.emit('showUnsubscribeModal')
    }
  }

}
</script>
