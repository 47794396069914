<template>
  <div class="page page--promotions has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('leaderboard.title') }}</h1>
      <div class="page__body">

        <template v-if="getBranches">

          <template v-for="(branch,key) in getBranches" :key="key">
            <div class="card" style="cursor: pointer" @click="showBranchLeaderboard(branch.id)">
              <div class="card__body">
                <span class="badge badge--danger badge--pill">
                  <i class="icon icon-maps-and-flags"></i>
                </span>

                <h3 class="card__title text-center">{{ branch.title }}</h3>
                <p class="text-center" v-html="branch.address"></p>
              </div>
            </div>
          </template>
        </template>
        <!-- /.card -->
      </div>
    </div>
  </div>
</template>

<script>
import {PAGE_INFO} from "@/store/action-types";
import {BRANCH_LIST} from "@/store/mutation-types";
import router from "@/router";

export default {
  name: 'Promotions',
  data() {
    return {
      branches: []
    }
  },

  computed: {

    getBranches(){
      return this.$store.getters.branchList
    }

  },
  methods: {

    showBranchLeaderboard(branchId){
      router.push({ name: 'branchLeaderboardInside', params: { locale: this.$i18n.locale, branchId: branchId } })
    },

  },

  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(BRANCH_LIST).then(() => {
        vm.$Progress.finish()
      })
      vm.$store.dispatch(PAGE_INFO, {
        path: 'leaderboard'
      })
    });

  },
  beforeRouteUpdate () {
    this.$store.dispatch(BRANCH_LIST).then(() => {
      this.$Progress.finish()
    })
    this.$store.dispatch(PAGE_INFO, {
      path: 'leaderboard'
    })
  }
}
</script>
