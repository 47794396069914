<template>
  <transition :name="dir">
    <div
        class="carousel__slide"
        :class="slideClass"
        ref="carousel3dSlide"
        @touchstart="startTouch"
        @touchend="endTouch"
        @touchmove="touchMove"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import scroll  from "@/mixins/scroll";

export default {
  props: {
    slide: {
      type: Number
    }
  },

  mixins : [
      scroll
  ],

  data () {
    return {
      isMoving: '',
      direction: ''
    }
  },

  computed: {
    /**
     *
     * @returns {boolean}
     */
    isActive () {
      return this.slide === this.$parent.currentIndex
    },

    /**
     *
     * @returns {string}
     */
    dir () {
      return this.$parent.slideDirection;
    },

    prevSlide () {
      let slide = this.$parent.slidesLength - 1

      if (this.$parent.currentIndex > 0) {
        slide = this.$parent.currentIndex - 1
      }

      return slide
    },

    willPrevSlide () {
      let slide = this.$parent.slidesLength - 2

      if (this.$parent.currentIndex === 1) {
        slide = this.$parent.slidesLength - 1
      } else if (this.$parent.currentIndex > 1) {
        slide = this.$parent.currentIndex - 2
      }

      return slide
    },

    nextSlide () {
      let slide = this.$parent.currentIndex + 1

      if (this.$parent.slidesLength - 1 === this.$parent.currentIndex) {
        slide = 0
      }

      return slide
    },

    willNextSlide () {
      let slide = this.$parent.currentIndex + 2

      if (this.$parent.slidesLength - 2 === this.$parent.currentIndex) {
        slide = 0
      } else if (this.$parent.slidesLength - 1 === this.$parent.currentIndex) {
        slide = 1
      }

      return slide
    },

    slideClass () {
      let classes = []
      if (this.isActive) {
        classes.push('is-active')
      } else if (this.slide === this.nextSlide) {
        classes.push('is-next')
      } else if (this.slide === this.willNextSlide) {
        classes.push('will-next')
      } else if (this.slide === this.prevSlide) {
        classes.push('is-prev')
      } else if (this.slide === this.willPrevSlide) {
        classes.push('will-prev')
      }

      if (this.isActive && this.isMoving) {
        classes.push('is-moving', this.isMoving)
      }

      return classes
    }
  },

  methods: {
    getTouches (e) {
      return e.touches
    },

    startTouch (e) {
      this.disableMobileScroll();

      const touch = this.getTouches(e)

      this.xDown = touch[0].clientX
      this.yDown = touch[0].clientY
    },

    endTouch () {
      if (this.direction === 'slide-right') {
        this.$parent.changeSlide()
      } else {
        this.$parent.changeSlide('slide-left')
      }

      this.xDown = null
      this.yDown = null

      this.isMoving = ''
      this.enableScroll()
    },

    touchMove (e) {
      if (!this.xDown || !this.yDown) {
        return
      }

      let xUp = e.touches[0].clientX
      let yUp = e.touches[0].clientY

      let xDiff = this.xDown - xUp
      let yDiff = this.yDown - yUp

      if (Math.abs( xDiff ) > Math.abs( yDiff )) {
        if ( xDiff > 0 ) {
          this.isMoving = 'move-right'
          this.direction = 'slide-right'
        } else {
          this.isMoving = 'move-left'
          this.direction = 'slide-left'
        }
      }
    }
  },

  mounted() {
    //this.$refs.carousel3dSlide.style.width = this.$parent.$refs.carousel3d.clientWidth + 'px'
  }
}
</script>
