<template>
  <modal
    :title="$t('career.modalTitle')"
    custom-class="career-modal"
    v-if="visible">
    <error-text :error-text="generalError"></error-text>
    <form @keyup.enter="sendCv" class="form" v-if="!success">
      <div class="form__group" :class="validationErrors.full_name ? 'has-error' : ''">
        <input v-model="form.full_name" type="text" class="form__control" :placeholder="$t('career.fullName')">
      </div>

      <validation-texts :errors="validationErrors.full_name"></validation-texts>

      <div class="form__group" :class="validationErrors.email ? 'has-error' : ''">
        <input v-model="form.email" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('career.email')">
      </div>

      <validation-texts :errors="validationErrors.email"></validation-texts>

      <div class="form__group" :class="validationErrors.subject ? 'has-error' : ''">
        <input v-model="form.subject" type="text" class="form__control" :placeholder="$t('career.subject')">
      </div>

      <validation-texts :errors="validationErrors.subject"></validation-texts>

      <div class="form__group" :class="validationErrors.content ? 'has-error' : ''">
        <textarea v-model="form.content" class="form__control" :placeholder="$t('career.description')"></textarea>
      </div>

      <validation-texts :errors="validationErrors.content"></validation-texts>

      <div class="career-modal__uploader" :class="validationErrors.files ? 'has-error' : ''">
        <div class="file-upload">
          <input type="file" :name="uploadFieldName" accept="application/pdf" ref="fileInput" @change="filesChange($event.target.name, $event.target.files)">
          <button type="button" class="button button--dashed" @click="openUpload">
            <i class="icon icon-paper-clip"></i> Upload CV
          </button>
        </div>

        <template v-if="fileName">
          <div class="file-upload__name">
            <i class="icon icon-paper-clip"></i> {{ fileName }}

            <button type="button" class="file-upload__close" @click="removeFile">
              <i class="icon icon-close"></i>
            </button>
          </div>
        </template>

        <button type="button" class="button button--danger submit" @click="sendCv">{{  $t('career.buttonTitle') }}</button>
      </div>

      <validation-texts :errors="validationErrors.files"></validation-texts>
    </form>

    <template v-else>
      <div class="modal__success">
        <i class="icon icon-checked"></i>
        <div class="modal__title">Thanks for getting Interested</div>
        <button type="button" class="button button--danger" @click="close">
          Close Window
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// helpers
import { getData } from '@/helpers/getData'

// components
import Modal from '@/components/Modals/Modal'
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getValidationsTextWithKey} from "@/helpers/responseError";
import form from "@/mixins/form";

export default {
  components: {
    ValidationTexts,
    ErrorText,
    Modal
  },

  mixins: [
    form
  ],

  data () {
    return {
      visible: false,
      success: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files',
      fileName: '',
      files: [],
      form: {
        career_id: null,
        email: '',
        full_name: '',
        subject: '',
        content: '',
        files: []
      },
      validationErrors: {},
      generalError: '',
      successMessage: ''
    }
  },

  methods: {
    async sendCv () {
      this.generalError = ''
      this.validationErrors = {}

      /**
       * Send request
       */
      getData({
        method: 'POST',
        url: 'career/store',
        data: this.form
      }).then((response) => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status > 400) {
          this.generalError = response.data.message;
          return;
        }

        this.successMessage = response.message;
        this.success = true
      })
    },

    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },

    filesChange(fieldName, fileList) {
      if (fileList[0].type !== 'application/pdf') {
        return false
      }

      this.getBase64(fileList[0]).then(res => {
        this.form.files = [{file: res}]
      })

      let name = fileList[0].name

      if (name.length > 20) {
        name =  name.replace('.pdf', '')
        name =  name.substring(0, 20) + '.pdf'
      }

      this.fileName = name
    },

    removeFile () {
      this.fileName = ''
      this.form.files = []
    },

    openUpload () {
      this.$refs.fileInput.click()
    },

    resetFields () {
      this.form = {
          career_id: null,
          email: '',
          full_name: '',
          subject: '',
          content: '',
          files: []
      }
      this.fileName = ''
      this.files = []
      this.success = false
    },

    close () {
      this.visible = false

      this.resetFields();
    }
  },

  mounted() {
    emitter.on('showCvModal', (data) => {
      this.resetFields();
      this.form.career_id = data.career_id;
      this.visible = true
    })
  }
}
</script>
