/**
 *
 * @param errors
 * @returns {{}}
 */
export const getValidationsTextWithKey = ( errors ) => {
    var validationErrors = {};

    Object.keys(errors).map((key) => {
        validationErrors[key] = errors[key];
    });

    return validationErrors;
}
