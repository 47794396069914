<template>
  <transition :name="dir">
    <div class="carousel__slide" :class="slideClass" ref="carousel3dSlide">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    slide: {
      type: Number
    }
  },

  computed: {
    /**
     *
     * @returns {boolean}
     */
    isActive () {
      return this.slide === this.$parent.currentIndex
    },

    /**
     *
     * @returns {string}
     */
    dir () {
      return this.$parent.slideDirection;
    },

    prevSlide () {
      let slide = this.$parent.slidesLength - 1

      if (this.$parent.currentIndex > 0) {
        slide = this.$parent.currentIndex - 1
      }

      return slide
    },

    nextSlide () {
      let slide = this.$parent.currentIndex + 1

      if (this.$parent.slidesLength - 1 === this.$parent.currentIndex) {
        slide = 0
      }

      return slide
    },

    slideClass () {
      let classes = []

      if (this.isActive) {
        classes.push('is-active')
      } else if (this.slide === this.nextSlide) {
        classes.push('is-next')
      } else if (this.slide === this.prevSlide) {
        classes.push('is-prev')
      }

      return classes
    }
  },

  methods: {

  },

  mounted() {
    this.$refs.carousel3dSlide.style.width = this.$parent.$refs.carousel3d.clientWidth + 'px'
  }
}
</script>
