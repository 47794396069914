<template>
  <div class="page page--static">
    <div class="container">
      <h1 class="page__title">{{ getTitle }}</h1>

      <div v-html="getText"></div>
    </div>
    <!-- /.container -->
  </div>
  <!-- /.page -->
</template>

<script>
// helpers
import { getData } from "@/helpers/getData"
import { getStaticPage } from "@/helpers/module"
import {PRIVACY_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Privacy',

  data () {
    return {
      data: {}
    }
  },

  computed: {
    /**
     *
     * Get page data
     */
    page () {
      if (this.data.data) {
        return getStaticPage(this.data, 'privacy_policy')[0];
      }

      return {};
    },

    /**
     *
     * Get title
     */
    getTitle () {
      if (this.page && this.page.fields) {
        return this.page.fields.privacy_policy_title.value
      }

      return null
    },

    /**
     *
     * Get text
     */
    getText () {
      if (this.page && this.page.fields) {
        return this.page.fields.privacy_policy_text.value
      }

      return null
    }
  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = data
    }
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(PRIVACY_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate (to, from, next) {
    /**
     * Send request
     */
    getData(PRIVACY_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  }
}
</script>
