import {LOCAL_STORAGE_USER_DATA} from "@/store/constants";

export default {
  userToken: state => state.userToken,
  userRegisterForm: state => state.userRegisterForm,
  userConfirmation: state => state.userConfirmation,
  userLogout: state => state.userLogout,
  userData: state => state.userData,

  /**
   * Get User balance.
   */
  userBalances: () => {
    var userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
    if (userData) {
      return userData.balances ? userData.balances : {}
    }

    return {};
  },

}
