<template>
  <div class="carousel carousel-3d" ref="carousel3d">
    <div class="carousel-3d__track" ref="carouselTrack">
      <slot></slot>
    </div>

    <navigation
        v-if="options.arrows"
    ></navigation>
  </div>
  <!-- /.carousel -->
</template>

<script>
// mixins
import baseMixin from "@/mixins/base"

// components
import Navigation from "@/components/Sliders/Slider-3d/Navigation"

export default {
  components: {
    Navigation
  },

  mixins: [
    baseMixin
  ],

  data () {
    return {
      options: {
        autoPlay: false,
        autoPlaySeconds: 3.5,
        arrows: true,
        paginationProgress: false
      },
      slidesLength: 0,
      currentIndex: 0,
      interval: '',
      slideDirection: 'slide-right'
    }
  },

  watch: {
    currentIndex () {
      //this.$refs.carouselTrack.style.transform = 'translateX(-' + this.transformWidth + ')'
    }
  },

  computed: {
    /**
     *
     * @returns {HTMLCollectionOf<Element>}
     */
    getSlides () {
      return this.getByClassName('carousel3d', 'carousel__slide')
    },

    /**
     *
     * @returns {Element}
     */
    getCurrentSlide () {
      return this.getSlides[this.currentIndex]
    },

    getTwoSlide () {
      if (this.currentIndex === this.getSlides.length - 2) {
        return [this.getSlides[this.currentIndex + 1], this.getSlides[0]]
      } else if (this.currentIndex === this.getSlides.length - 1) {
        return [this.getSlides[0], this.getSlides[1]]
      }

      return [this.getSlides[this.currentIndex + 1], this.getSlides[this.currentIndex + 2]]
    },

    getTwoLeftSlide () {
      if (this.currentIndex === this.getSlides.length - 2) {
        return false
      } else if (this.currentIndex === this.getSlides.length - 1) {
        return false
      }

      return [this.getSlides[4], this.getSlides[0]]
    },

    transformWidth () {
      return this.$refs.carousel3d.clientWidth * this.currentIndex + 'px'
    },

    trackWidth () {
      return (this.getSlides.length * this.$refs.carousel3d.clientWidth) + 'px'
    },

    hasArrows () {
      return this.options.arrows
    }
  },

  methods: {
    /**
     *
     */
    changeSlide (direction = 'slide-right') {
      if (direction === 'slide-left') {
        this.currentIndex--;

        if(this.currentIndex < 0){
          this.currentIndex = this.getSlides.length - 1;
        }
      } else {
        this.currentIndex++

        if(this.currentIndex >= this.getSlides.length) {
          this.currentIndex = 0;
        }
      }

      this.slideDirection = direction
    },

    /**
     *
     */
    autoPlay () {
      this.changeSlide()

      return this.interval
    },

    median (arr) {
      const mid = Math.floor(arr.length / 2),
          nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    }
  },

  mounted() {
    this.slidesLength = this.getSlides.length

    const vm = this

    window.addEventListener('keydown', function(e) {
      if (e.code === 'ArrowRight') {
        vm.changeSlide()
      } else if (e.code === 'ArrowLeft') {
        vm.changeSlide('slide-left')
      }
    })
  }
}
</script>
