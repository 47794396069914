/**
 * User
 */
export const USER_TOKEN = 'USER_TOKEN'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_DATA = 'USER_DATA'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_REGISTER_FORM = 'USER_REGISTER_FORM'
export const USER_CONFIRMATION = 'USER_CONFIRMATION'

/**
 *
 * Localization.
 *
 * @type {string}
 */
export const LOCALIZATION_TEXT = 'LOCALIZATION_TEXT'

/**
 *
 * @type {string}
 */
export const PAGE_INFO  = 'page_info'

/**
 *
 * @type {string}
 */
export const BRANCH_LIST  = 'branch_list'
export const BRANCH_LEADERBOARD_LIST  = 'branch_leaderboard_list'

/**
 *
 * @type {string}
 */
export const PROMOTION_OFFERS_DATA  = 'promotion_offers_data'
export const PROMOTION_JACKPOT_DATA  = 'promotion_jackpot_data'
