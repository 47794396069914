<template>
  <div class="page page--provider page--offers-single" :class="{'has-bg': !image}" ref="page">
    <div
        v-if="pageCover"
        class="page-cover"
        :style="pageCover"
    ></div>
    <div
        v-if="image"
        class="page-cover-mobile"
        :style="pageCoverMobile"
    ></div>
    <div class="container">
      <p class="page__text" :style="{ 'color': colors?.contentColor }" v-html="content" />

      <drawer
          :title="$t('offers.single.termsTitle')"
          width="800px"
      >
        <template v-slot:body>
          <perfect-scrollbar ref="scrollbar">
            <div class="scroll-inner">
              <div
                  v-for="(term, index) in getTermsConditions"
                  :key="index"
                  class="list-group"
              >
                <h4 class="title">{{ term.title }}</h4>
                <div v-html="term.content" />
              </div>
            </div>
          </perfect-scrollbar>
        </template>
      </drawer>
    </div>

    <button
        class="button button--link page__button-absolute"
        :style="{ 'background-color': colors?.termBg, 'color': colors?.termColor }"
        @click="handleShowTerms"
    >
      <InfoRegular
          size="20px"
          :color="colors?.termColor"
      />
      <span>{{  $t('offers.single.termsTitle') }}</span>
    </button>

  </div>
</template>

<script>
import {getData} from "@/helpers/getData";
import {PAGE_INFO} from "@/store/action-types";
import {setPromoPageColors} from "@/helpers/color";
import Drawer from "@/components/Drawers/Drawer";
import {PerfectScrollbar} from "vue3-perfect-scrollbar";
import emitter from "@/plugins/bus";
import InfoRegular from "@/components/Icons/Info/Regular"

export default {
  components: {
    Drawer,
    PerfectScrollbar,
    InfoRegular
  },

  data: () => {
    return {
      data: {}
    }
  },

  computed: {
    title () {
      return this.data?.offer?.title
    },

    content () {
      return this.data?.offer?.content
    },

    image () {
      return this.data?.offer?.images?.[1]?.full_src
    },

    imageCover () {
      let value = null
      let coverLocale = 'backgroundImageEn'

      // TODO refactor
      if (this.$i18n.locale === 'ka') {
        coverLocale = 'backgroundImageKa'
      } else if (this.$i18n.locale === 'ru') {
        coverLocale = 'backgroundImageRu'
      }

      this.data?.offer?.images?.forEach(item => {
        if (item.type === coverLocale) {
          value =  item.full_src
        }
      })

      return value
    },

    imageMobile () {
      let value = null
      let coverLocale = 'backgroundImageMobileEn'

      // TODO refactor
      if (this.$i18n.locale === 'ka') {
        coverLocale = 'backgroundImageMobileKa'
      } else if (this.$i18n.locale === 'ru') {
        coverLocale = 'backgroundImageMobileRu'
      }
      this.data?.offer?.images?.forEach(item => {
        if (item.type === coverLocale) {
          value =  item.full_src
        }
      })

      return value
    },

    getTermsConditions () {
      if (!this.data.items) {
        return [];
      }

      return this.data.items.map((cardBenefit) => {
        const title = cardBenefit.title;
        const description = cardBenefit.sub_title;
        return {
          title: title ? title : '',
          content: description ? description : ''
        }
      });
    },

    pageCover () {
      return {
        backgroundImage: `url(${this.imageCover})`
      }
    },

    pageCoverMobile () {
      return {
        backgroundImage: `url(${this.imageMobile})`
      }
    },

    colors () {
      return this.data?.offer?.colors
    }
  },

  watch: {
    '$route' () {
      setPromoPageColors(null)
    }
  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = data?.data
      this.setBody()
    },

    setBody () {
      document.getElementsByTagName('body')[0].classList.add('hide-chat') // TODO refactor ...

      setPromoPageColors(this.colors)
    },

    handleShowTerms () {
      emitter.emit('showDrawer')
    }
  },

  mounted() {
    console.log(this.imageMobile)
  },

  beforeRouteUpdate () {
    getData({
      method: 'GET',
      url: 'promotion/offer/' + this.$route.params.id
    }).then(response => {
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.setData(response)
      this.$Progress.finish()
    })
  },

  beforeRouteEnter: (to, from, next) => {
    getData({
      method: 'GET',
      url: 'promotion/offer/' + to.params.id
    }).then(response => {
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.setData(response)
        vm.$Progress.finish()
      })
    })
  },
}
</script>
