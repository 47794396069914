<template>
  <div class="page page--error">
    <div class="page__inner">
      <div class="page__title">404</div>
      <p class="page__text">{{ $t("page.error.pageNotFound") }}</p>

      <router-link :to="homeRoute" class="button button--primary button--pill">{{ $t("page.error.buttonText") }}</router-link>
    </div>
  </div>
</template>

<script>
import url from "@/mixins/url";
import {PAGE_INFO} from "@/store/mutation-types";

export default {
  name: "PageNotFound",

  mixins: [
    url
  ],

  computed: {
    /**
     *
     * @returns {{path: *, locale: Locale}}
     */
    homeRoute () {
      return this.goToUrl('')
    }
  },

  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(PAGE_INFO, {
        path: '404_not_found'
      })
    })
  },
  beforeRouteUpdate: () => {
    this.$store.dispatch(PAGE_INFO, {
      path: '404_not_found'
    })
  }
}
</script>
