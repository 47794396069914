<template>
  <p class="text text--danger is-error">
    <template v-for="(error,key) in errors" :key="key">
      <error-text :error-text="error" :excepted="true"></error-text>
    </template>
  </p>
</template>

<script>
import ErrorText from "@/components/Error/ErrorText";
export default {
  components: {ErrorText},
  props: {
    errors: Array
  }
}
</script>
