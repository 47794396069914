<template>
  <div class="page page--faq">
    <div class="container">
      <h1 class="page__title">{{ $t('faq.title') }}</h1>

      <template v-if="getAllFaq.length">
        <accordion :data="getAllFaq">
        </accordion>
      </template>

    </div>
  </div>
</template>

<script>
// components
import Accordion from "@/components/Accordion/Accordion"
import {getData} from "@/helpers/getData";
import {FAQ_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Faq',

  data(){
    return {
      data: []
    }
  },

  components: {
    Accordion
  },
  computed: {
    getAllFaq () {
      if (this.data) {
        return this.data.map((faqData,key) => {
          var allFields = faqData.fields;
          return {
            id: key,
            title: allFields.faq_title.value,
            content: allFields.faq_text.value,
          }
        });
      }

      return [];
    }
  },
  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = data.data.items.faq;
    }
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(FAQ_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(FAQ_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
