<template>
  <div class="page page--account page--myoffers">
    <div class="container">
      <h2 class="page__title">My account</h2>

      <div class="page__body">
        <sidebar></sidebar>

        <div class="flex-1">
          <div class="page__content has-side padding-none">

            <div class="page__content-left">
              <h3 class="page__title">My card</h3>

              <div class="club-card">
                <img src="/assets/logo.svg" class="club-card__brand" alt="Betlive Club">

                <div class="club-card__number">4315 5021 24</div>
              </div>

              <div class="club-card club-card--secondary">
                <img src="/assets/logo.svg" class="club-card__brand" alt="Betlive Club">

                <div class="club-card__title">Card Holder</div>
                <div class="club-card__holder">Name Surname</div>
              </div>
            </div>

            <div class="page__content-right">
              <h3 class="page__title">Activated Offers</h3>

              <div class="offers">
                <div class="offers__empty">
                  <i class="icon icon-promotion"></i>
                  <p class="offers__text">You haven’t activated any promotions yet</p>
                </div>
              </div>
            </div>
          </div>

          <div class="page__content page__content--md">
            <h3 class="page__title">Offers</h3>

            <div class="card card--secondary">
              <div class="card__body">
                <div class="card__image"></div>
                <div class="card__info">
                  <h3 class="card__title">Get 50% Cashback</h3>
                  <p class="card__text">
                    Every week, you can Reload your bankroll for a
                    bonus up to 50% and 3.5 BTC.
                  </p>
                  <button type="button" class="button button--light">Activate</button>
                </div>
              </div>
              <!-- /.card__body -->
            </div>
            <!-- /.card -->

            <div class="card card--secondary">
              <div class="card__body">
                <div class="card__image"></div>
                <div class="card__info">
                  <h3 class="card__title">Get 50% Cashback</h3>
                  <p class="card__text">
                    Every week, you can Reload your bankroll for a
                    bonus up to 50% and 3.5 BTC.
                  </p>
                  <button type="button" class="button button--light">Activate</button>
                </div>
              </div>
              <!-- /.card__body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Account/Sidebar";
export default {
  name: 'MyProfile',
  components: {Sidebar},
}
</script>
