<template>
  <div class="page__right flex-1">
    <div class="page__right-header">
      {{ $t('account.card.myCardTitle') }}

      <button class="header__burger-close" @click="close">
        <i class="icon icon-close"></i>
      </button>
    </div>

    <div class="page__content has-side padding-none">
      <div class="page__content-left">
        <h3 class="page__title">{{ $t('account.card.myCardTitle') }}</h3>

        <div class="my-cards">
          <div class="club-card">
            <img src="/assets/betlive-logo.svg" class="club-card__brand" alt="Betlive Club">

            <div class="club-card__number">{{ cardData.card_num }}</div>
          </div>

          <div class="club-card club-card--secondary">
            <img src="/assets/betlive-logo.svg" class="club-card__brand" alt="Betlive Club">

            <div class="club-card__title">{{ $t('account.card.cardHolder') }}</div>
            <div class="club-card__holder">{{ getFullName }}</div>
          </div>
        </div>
      </div>

      <div class="page__content-right">
        <h3 class="page__title">{{ $t('account.card.activatedOffersTitle') }}</h3>

        <div class="my-offers">
          <template v-if="activateOffers && activateOffers.length">
            <template v-for="(item,key) in activateOffers" :key="key">
              <div class="card">
                <div class="card__body">
                  <div class="card__image"><img v-lazy="item.image_url"></div>
                  <div class="card__info">
                    <h3 class="card__title">{{ item.title }}</h3>
                    <p class="card__text" v-html="item.sub_title"></p>

                    <div class="card__label">
                      <i class="icon icon-tick"></i> {{$t('account.card.activatedTitle') }}
                    </div>
                  </div>
                </div>
                <!-- /.card__body -->
              </div>
            </template>
          </template>

          <template v-else>
            <div class="offers__empty">
              <i class="icon icon-promotion"></i>
              <p class="offers__text">{{ $t('account.card.youHaveNotOffers') }}</p>
            </div>
          </template>


        </div>
      </div>
    </div>

    <div class="page__content page__content--md my-offers" v-if="offersData && notActiveOffers">
      <h3 class="page__title">{{ $t('account.card.offersTitle') }}</h3>

        <template v-for="(item,key) in notActiveOffers" :key="key">
          <div class="card card--secondary">
            <div class="card__body">
              <div class="card__image"><img :src="item.image_url"></div>
              <div class="card__info">
                <h3 class="card__title">{{ item.title }}</h3>
                <p class="card__text" v-html="item.sub_title">
                </p>
                <button type="button" class="button button--light" @click="makeActiveOffer(item.id)">{{$t('account.card.activateTitle') }}</button>
              </div>
            </div>
            <!-- /.card__body -->
          </div>
        </template>
    </div>
  </div>
</template>

<script>

import {getData} from "@/helpers/getData";

export default {
  props: {
    cardData: {
      type: Object,
      default: null
    },
    offersData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      activateOffers: [],
      notActiveOffers: []
    }
  },
  created() {
    if (this.offersData && this.offersData.activated.length) {
      this.activateOffers = this.offersData.activated;
    }
    if (this.offersData && this.offersData.not_activated.length) {
      this.notActiveOffers = this.offersData.not_activated;
    }
  },
  computed: {
    getFullName () {
      return this.getCustomerData.full_name
    },
    getCustomerData () {
      return this.$store.getters.userData
    },
  },
  methods: {
    makeActiveOffer(offerId) {
      getData({
        method: 'POST',
        url: 'card/offer/activate',
        data: {
          offer_id: offerId
      }
      }).then(response => {
        if (response.data) {
          this.activateOffers = this.objectToArray(response.data.offers.activated)
          this.notActiveOffers = this.objectToArray(response.data.offers.not_activated)
        }
      })
    },
    objectToArray(obj){
      return Object.keys(obj).map((key) => obj[key]);
    },

    close () {
      this.$router.push( { path: '/', params: { locale: this.$i18n.locale } })
    }
  }
}

</script>
