<template>
  <modal
    :title="$t('login.title')"
    custom-class="login-modal"
    onclose="closeModal()"
    v-if="visible"
  >
    <div class="modal__title show-md-and-down text-center">
      Sign-in to your account
    </div>

    <error-text :excepted="excepted" :error-text="generalError"></error-text>
    <form class="form" @keyup.enter="login">
      <div class="form__group" :class="validationErrors.phone_number ? 'has-error' : ''">
        <input v-model="form.phone_number" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('login.phoneNumber')">
      </div>

      <validation-texts v-if="validationErrors.phone_number" :errors="validationErrors.phone_number"></validation-texts>

      <div class="form__group" :class="validationErrors.password ? 'has-error' : ''">
        <input v-model="form.password" type="password" class="form__control" @keypress="validateInput($event)" :placeholder="$t('login.password')">
      </div>

      <validation-texts v-if="validationErrors.password" :errors="validationErrors.password"></validation-texts>

      <button type="button" class="button button--link login-modal__forgot" @click="passwordReset">{{ $t('login.passwordReset') }}</button>
      <button type="button" class="button button--danger button--block login-modal__submit" @click="login">{{ $t('login.buttonTitle') }}</button>
    </form>

    <div class="modal__message">
      Don’t have an account? <button type="button" class="button button--link" @click="goToSignUp">Sign up</button>
    </div>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// types
import {USER_CONFIRMATION, USER_DATA, USER_LOGIN} from "@/store/action-types"
import form from "@/mixins/form";

// components
import Modal from "@/components/Modals/Modal"
import {getValidationsTextWithKey} from "@/helpers/responseError";
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";

export default {
  components: {
    ValidationTexts,
    ErrorText,
    Modal
  },

  mixins: [
      form
  ],

  data () {
    return {
      visible: false,
      form: {
        phone_number: '',
        password: ''
      },
      validationErrors: {},
      generalError: '',
      excepted: false
    }
  },

  methods: {
    async login () {
      this.clearErrorFields();

      await this.$store.dispatch(USER_LOGIN, this.form).then(response => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status > 400) {
          this.excepted = true
          this.generalError = response.data.message;
          return;
        }

        if (response.data.access_token) {
          this.$store.dispatch(USER_DATA, response.data.customer)

          this.visible = false

          this.form = {
            phone_number: '',
            password: ''
          }

          if (response.data.customer.verified_at === null) {
            this.$store.dispatch(USER_CONFIRMATION, { openManual: true })
            emitter.emit('showConfirmModal');
          }
        }
      })
    },

    resendConfirm () {
      this.visible = false;
      this.$store.dispatch(USER_CONFIRMATION, { openManual: true })
      emitter.emit('showConfirmModal');
    },

    passwordReset () {
      this.visible = false;
      emitter.emit('showPasswordResetModal');
    },

    goToSignUp () {
      this.visible = false;
      emitter.emit('showRegisterModal');
    },

    closeModal () {
      this.form = {}
      this.clearErrorFields();
    },

    clearErrorFields () {
      this.excepted = false
      this.validationErrors = {}
      this.generalError = ''
    },

  },

  mounted() {
    emitter.on('showLoginModal', () => this.visible = true)
  }
}
</script>
