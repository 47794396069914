import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import {getData} from "@/helpers/getData";

export default {
    [actionTypes.BRANCH_LIST](context, form = {}) {
        /**
         * Sent User Login Request
         */
        return getData({
            method: 'GET',
            url: 'branch',
            data: form
        }, context).then(response => {
            if (response.data) {
                context.commit(mutationTypes.BRANCH_LIST, response.data)
            }
            /**
             * Return Response
             */
            return response
        })
    },

    [actionTypes.BRANCH_LEADERBOARD_LIST](context, form = {}) {
        /**
         * Sent User Login Request
         */
        return getData({
            method: 'GET',
            url: 'branch/rating',
            data: form
        }, context).then(response => {
            if (response.data) {
                context.commit(mutationTypes.BRANCH_LEADERBOARD_LIST, response.data)
            }
            /**
             * Return Response
             */
            return response
        })
    },

}
