<template>
  <modal
    :title="getModalTitle"
    custom-class="forgot-modal"
    onclose="closeModal()"
    v-if="visible"
  >
    <div class="modal__title show-md-and-down text-center" v-if="!isSendVerifyCode">
      {{ $t('passwordReset.isForgotPassword') }}
    </div>

    <template v-if="canBack">
      <error-text :error-text="generalError"></error-text>
    </template>

    <form class="form" @keyup.enter="sendVerifyCode" v-if="!isSendVerifyCode">

      <div class="form__group" :class="validationErrors.phone_number ? 'has-error' : ''">
        <input v-model="form.phone_number" type="text" class="form__control" :placeholder="$t('passwordReset.phoneNumber')">
      </div>

      <validation-texts :errors="validationErrors.phone_number"></validation-texts>

      <div class="form__group is-last" :class="validationErrors.email ? 'has-error' : ''">
        <input v-model="form.email" type="text" class="form__control" :placeholder="$t('passwordReset.email')">
      </div>

      <validation-texts :errors="validationErrors.email"></validation-texts>

      <button type="button" class="button button--link login-modal__forgot" @click="back">
        {{ $t('passwordReset.isRememberPassword') }}
      </button>

      <button type="button" class="button button--danger button--block login-modal__submit" @click="sendVerifyCode">{{  $t('passwordReset.sendVerifyCode') }}</button>
    </form>

    <form @keyup.enter="checkVerifyCode" :style="{'display': digitsFormVisible}">
      <div class="modal__digit">
        <div class="modal__digit-message">
          {{ $t('passwordReset.sentSuccessfullyMessage') }}
        </div>

        <div class="row">
          <div class="col-12">
            <div class="modal__digit-label">{{ $t('passwordReset.resetVerificationCode') }}</div>
            <div class="form__group form__group-digits" :class="validationErrors.code ? 'has-error' : ''" ref="digitInputs">
              <input v-model="digits.first" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('first', $event)" @keyup.enter="confirm">
              <input v-model="digits.second" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('second', $event)" @keyup.enter="confirm">
              <input v-model="digits.third" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('third', $event)" @keyup.enter="confirm">
              <input v-model="digits.fourth" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('fourth', $event)" @keyup.enter="confirm">
            </div>

            <validation-texts :errors="validationErrors.code"></validation-texts>
          </div>
        </div>
      </div>

      <button type="button" class="button button--danger button--block login-modal__submit" @click="checkVerifyCode">{{  $t('passwordReset.checkCode') }}</button>
    </form>

    <form @keyup.enter="changePassword" v-if="isSendVerifyCode && isCheckedVerifyCode && !resetPassword">

      <div class="form__group" :class="validationErrors.password ? 'has-error' : ''">
        <input v-model="form.password" type="password" class="form__control" :placeholder="$t('passwordReset.password')">
      </div>

      <validation-texts :errors="validationErrors.password"></validation-texts>

      <div class="form__group" :class="validationErrors.password_confirmation ? 'has-error' : ''">
        <input v-model="form.password_confirmation" type="password" class="form__control" :placeholder="$t('passwordReset.passwordConfirmation')">
      </div>

      <validation-texts :errors="validationErrors.password_confirmation"></validation-texts>

      <button type="button" class="button button--danger button--block login-modal__submit" @click="changePassword">{{  $t('passwordReset.changePassword') }}</button>
    </form>

    <form v-if="isSendVerifyCode && isCheckedVerifyCode && resetPassword">
      <div class="modal__success">
        <i class="icon icon-checked"></i>
        <p>{{ $t('passwordReset.changedPasswordSuccessfully')}}</p>
        <button type="button" class="button button--danger" @click="redirectAfterChangedPassword">
          {{ $t('passwordReset.loginToProfile') }}
        </button>
      </div>
    </form>


  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// components
import Modal from "@/components/Modals/Modal"
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getData} from "@/helpers/getData";
import {getValidationsTextWithKey} from "@/helpers/responseError";

export default {
  components: {
    ValidationTexts,
    ErrorText,
    Modal
  },

  data () {
    return {
      loading: false,
      visible: false,
      isSendVerifyCode: false,
      isCheckedVerifyCode: false,
      resetPassword: false,
      form: {
        phone_number: '',
        email: ''
      },
      digits: {
        code: '',
        first: '',
        second: '',
        third: '',
        fourth: ''
      },
      validationErrors: {},
      generalError: '',
    }
  },

  computed: {

    canBack () {
      return !this.isCheckedVerifyCode && !this.resetPassword;
    },
    getModalTitle () {
      return this.$t('passwordReset.title');
    },

    digitsFormVisible () {
      return this.isSendVerifyCode && !this.isCheckedVerifyCode && !this.resetPassword ? 'block' : 'none'
    }
  },

  watch: {
    'resetPassword' () {
      if (this.resetPassword) {
        setTimeout(() => {
          this.redirectAfterChangedPassword();
        },5000);
      }
    },

    'visible' (){
      if (!this.visible) {
        this.resetFields();
      }
    },

    'isSendVerifyCode' (status) {
      if (status) {
        this.onDigits()
      }
    }
  },

  methods: {

    sendVerifyCode () {
      this.clearErrorFields();
      this.loadingUpdate();

      getData({
        method: 'POST',
        url: 'customer/auth/password-reset/send-verify',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }

        this.isSendVerifyCode = true;
      })

      this.loadingUpdate();

      this.$Progress.finish()
    },

    checkVerifyCode () {
      this.clearErrorFields();
      this.form.password = '';
      this.form.password_confirmation = '';
      this.loadingUpdate();

      getData({
        method: 'POST',
        url: 'customer/auth/password-reset/check-verify',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }

        this.isCheckedVerifyCode = true;
      })

      this.loadingUpdate();

      this.$Progress.finish()
    },

    changePassword () {
      this.clearErrorFields();
      this.loadingUpdate();

      getData({
        method: 'POST',
        url: 'customer/auth/password-reset/update',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }

        this.resetPassword = true;
      })

      this.loadingUpdate();
    },

    back () {
      if (!this.isSendVerifyCode) {
        this.visible = false;
        emitter.emit('showLoginModal');
      } else if (this.isCheckedVerifyCode) {
        this.isSendVerifyCode = false;
        this.isCheckedVerifyCode = false;
        this.form.code = '';
        this.form.password = '';
        this.form.password_confirmation = '';
      } else if (this.isSendVerifyCode){
        this.isSendVerifyCode = false;
        this.form.code = '';
        this.form.password = '';
        this.form.password_confirmation = '';
      }

      this.clearErrorFields();
    },

    loadingUpdate () {
      this.loading = !this.loading;
    },

    clearErrorFields () {
      this.validationErrors = {}
      this.generalError = ''
    },

    resetFields () {
      this.isSendVerifyCode = false;
      this.isCheckedVerifyCode = false;
      this.resetPassword = false;
      this.form = {};
    },

    redirectAfterChangedPassword () {
      this.visible = false;
      emitter.emit('showLoginModal');
    },

    closeModal () {
      this.resetFields();
    },

    changeDigit (input, event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.digits[input] = event.key
      }

      this.form.code = this.digits.first + this.digits.second + this.digits.third + this.digits.fourth
    },

    onDigits () {
      const vm = this
      const digitInputs = this.$refs.digitInputs.querySelectorAll('input')

      digitInputs.forEach((input, index) => {
        input.addEventListener("keyup", () => {
          if (input.value.length > 0 && index !== vm.totalDigits) {
            digitInputs[index + 1].focus();
          } else if (!input.value.length && index !== 0) {
            digitInputs[index - 1].focus();
          }
        })
      })
    }
  },

  mounted() {
    emitter.on('showPasswordResetModal', () => this.visible = true)

    const vm = this

    if (this.visible) {
      const digitInputs = this.$refs.digitInputs.querySelectorAll('input')

      digitInputs.forEach((input, index) => {
        input.addEventListener("keyup", () => {
          if (input.value.length > 0 && index !== vm.totalDigits) {
            digitInputs[index + 1].focus();
          } else if (!input.value.length && index !== 0) {
            digitInputs[index - 1].focus();
          }
        })
      })
    }
  }
}
</script>
