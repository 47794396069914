import * as types from "@/store/mutation-types";

export default {
  [types.BRANCH_LIST] (state, branchList) {
    state.branchList = branchList
  },
  [types.BRANCH_LEADERBOARD_LIST] (state, branchLeaderboardList) {
    state.branchLeaderboardList = branchLeaderboardList
  },
}
