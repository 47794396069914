<template>
  <div class="page page--news has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('news.title') }}</h1>
    </div>

    <carousel :options="options" v-if="slides.length" @change="changeSlide">
      <slide
          v-for="(slide, index) in slides"
          :key="index"
          :slide="index"
      >
        <img :src="slide.image">

        <div class="carousel__view" v-if="this.show === index" :ref="'view-' + index" :class="details ? 'carousel__view-active' : ''">
          <div class="carousel__view-header">
            <h4 class="carousel__title" v-html="slide.description"></h4>

            <button class="header__burger-close" @click="toggleDetails">
              <i class="icon icon-close"></i>
            </button>
          </div>
          <button class="carousel__view-close" @click="toggleDetails">
            <i class="icon icon-close"></i>
          </button>
          <perfect-scrollbar @ps-scroll-y="onScroll" ref="scrollbar">
            <div class="carousel__description" v-html="slide.short_description"></div>
          </perfect-scrollbar>
        </div>

        <div class="carousel__inner">
          <div class="carousel__info">
            <h4 class="carousel__title" v-html="slide.description"></h4>
            <div class="carousel__text" v-html="shortDesc(slide.short_description, 110)"></div>
          </div>

          <button type="button" class="button button--white button--pill" @click="toggleDetails(index)">{{ $t('news.readMore') }}</button>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
// components
import Carousel from "@/components/Sliders/Slider-wide/Carousel"
import Slide from "@/components/Sliders/Slider-wide/Slide"
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import {getData} from "@/helpers/getData"
import {getStaticPage} from "@/helpers/module"
import {NEWS_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";
import scroll from "@/mixins/scroll";

export default {
  name: 'News',

  mixins: [
      scroll
  ],

  components: {
    Carousel,
    Slide,
    PerfectScrollbar
  },

  data: () => {
    return {
      slides: [],
      details: false,
      show: '',
      close: false
    }
  },

  watch: {
    'details' (status) {
      if (status) {
        this.disableMobileScroll()
      } else {
        this.enableMobileScroll()
      }
    }
  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.slides = [];
      let responseData = getStaticPage(data, false);

      responseData.news.map((newsData) => {
        let allFields = newsData.fields;

        this.slides.push({
          image: allFields.image.value,
          description: allFields.description.value,
          short_description: allFields.short_description.value,
        });
      });
    },

    shortDesc (content, limit) {
      if (content.length > limit) {
        return content.substring(0, limit) + ' ...'
      }

      return content
    },

    onScroll(event) {
      if (event.srcElement.scrollTop > 40) {
        this.close = true
      } else {
        this.close = false
      }
    },

    toggleDetails (item) {
      this.show = item
      this.details = !this.details
    },

    changeSlide () {
      this.details = false
    }
  },

  beforeRouteEnter: (to, from, next) => {
    getData(NEWS_ROUTE).then(response => {
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.setData(response)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    getData(NEWS_ROUTE).then(response => {
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.setData(response)
      this.$Progress.finish()
    })
  }
}
</script>
