import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  userToken: null,
  userData: {},
  userRegisterForm: {},
  userConfirmation: {
    openManual: true
  },
  userLogout: {}
})

export default {
  state,
  getters,
  actions,
  mutations
}
