<template>
  <div class="page__sidebar">
    <ul class="nav page__list">
      <li class="page__item">
        <router-link :to="accountRoute" class="page__link" :class="isActive('MyProfile') ? 'is-active' : ''">{{ $t('account.myProfile')}}</router-link>
      </li>

      <li class="page__item">
        <router-link :to="cardRoute" class="page__link" :class="isActive('MyCard') ? 'is-active' : ''">{{ $t('account.myCard')}}</router-link>
      </li>

      <li class="page__item">
        <a @click="logout" class="page__link">{{ $t('account.logout')}}</a>
      </li>
    </ul>
  </div>
</template>

<script>

import {USER_LOGOUT} from "@/store/action-types";
import url from "@/mixins/url";

export default {
  mixins: [
    url
  ],

  computed: {
    accountRoute () {
      return this.goToUrl('account')
    },

    cardRoute () {
      return this.goToUrl('account/card')
    }
  },

  methods: {
    isActive(routeName) {
      return this.$route.name == routeName;
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async logout () {
      await this.$store.dispatch(USER_LOGOUT).then(() => {
        this.$Progress.finish();
        this.$router.push({name: 'Home'})
      });
    }
  }
}

</script>
