<template>
  <div class="page__content page__content--center">
    <div class="page__content-header">
      {{ $t('account.card.myCardTitle') }}

      <button class="header__burger-close" @click="close">
        <i class="icon icon-close"></i>
      </button>
    </div>

    <div class="club-card">
      <img src="/assets/betlive-logo.svg" class="club-card__brand" alt="Betlive Club">

      <div class="club-card__number">{{ $t('account.card.defaultNumber') }}</div>
    </div>

    <div class="text-center">
      <h4 class="page__title">{{ $t('account.card.whatIsMyCard') }}</h4>
      <p class="page__text">{{ $t('account.card.featureText') }}</p>

      <div class="list list--horizontal justify-content-center">
        <div class="list__item">
                <span class="badge badge--primary badge--pill">
                  <i class="icon icon-reload"></i>
                </span> {{ $t('account.card.featureCashback') }}
        </div>

        <div class="list__item">
                <span class="badge badge--primary badge--pill">
                  <i class="icon icon-cherry"></i>
                </span> {{ $t('account.card.featureFreePlays') }}
        </div>

        <div class="list__item">
                <span class="badge badge--primary badge--pill">
                  <i class="icon icon-trophy"></i>
                </span> {{ $t('account.card.featureTournaments') }}
        </div>
      </div>

      <template v-if="isPendingCardOrder">
        {{ $t('account.card.pendingCardOrder') }}
      </template>
      <template v-else>
        <a @click="orderCard" class="button button--danger">{{ $t('account.card.orderCard') }}</a>
      </template>

    </div>
  </div>
</template>

<script>
import emitter from "@/plugins/bus";

export default {
  props: {
    cardData: {
      type: Object,
      default: null
    }
  },

  computed: {
    isPendingCardOrder () {
      return this.cardData && this.cardData.status == 'pending'
    }
  },

  methods: {
    orderCard() {
      emitter.emit('showCardOrderModal')
    },

    close () {
      this.$router.push( { path: '/', params: { locale: this.$i18n.locale } })
    }
  }

}
</script>
