<template>
  <div class="modal" ref="modal" :class="customClass">
    <div class="modal__inner">
      <div class="modal__box" ref="modalBox" v-click-away="close">
        <div class="modal__header">
          <div class="modal__title">
            {{ title }}

            <slot name="title"></slot>
          </div>
          <button class="modal__close" @click="close">
            <i class="icon icon-close"></i>
          </button>
        </div>
        <!-- /.modal__header -->
        <div class="modal__body">
          <slot />
        </div>
        <!-- /.modal__body -->
      </div>
      <!-- /.modal__box -->
    </div>
    <!-- /.modal__inner -->
  </div>
  <!-- /.modal -->
</template>

<script>
// mixins
import { mixin as VueClickAway } from "vue3-click-away";

export default {
  mixins: [VueClickAway],

  props: {
    title: {
      type: String
    },

    customClass: {
      type: String
    },
  },

  watch: {
    '$parent.visible' (status) {
      if (status) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    }
  },

  methods: {
    /**
     * Close modal
     */
    close () {
      this.$parent.visible = false
    },

    disableScroll () {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    },

    enableScroll () {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  },

  mounted() {
    if (this.$parent.visible) {
      this.disableScroll()
    } else {
      this.enableScroll()
    }
  }
}
</script>
