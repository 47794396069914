<template>
  <modal
    :title="$t('card.orderTitle')"
    custom-class="card-modal"
    v-if="visible">
    <error-text :error-text="generalError" :excepted="expected"></error-text>
    <form @keyup.enter="orderCard" class="form" v-if="!success && options.branches">
      <div class="form__group" :class="validationErrors.branch_id ? 'has-error' : ''">
        <Multiselect
            v-model="form.branch_id"
            :options="options.branches"
            :placeholder="$t('card.branches')"
        />
      </div>
      <validation-texts :errors="validationErrors.branch_id"></validation-texts>

      <button type="button" class="button button--danger button--block login-modal__submit" @click="orderCard">
        {{  $t('card.orderCardButton') }}</button>
    </form>

    <template v-else>
      {{ successMessage }}
    </template>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// components
import Modal from "@/components/Modals/Modal"
import Multiselect from '@vueform/multiselect'
import {getData} from "@/helpers/getData";
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getValidationsTextWithKey} from "@/helpers/responseError";

export default {
  components: {
    ValidationTexts,
    ErrorText,
    Modal,
    Multiselect
  },

  data () {
    return {
      visible: false,
      success: false,
      form: {
        branch_id: '',
        address: 'address',
        country_iso_code: '',
        birthday: ''
      },
      expected: false,
      options: {},
      validationErrors: {},
      generalError: '',
      successMessage: ''
    }
  },

  watch: {
    'visible' () {
      if (this.visible) {
        this.getOrderData();
      }
    }
  },

  methods: {
    async orderCard () {
      this.validationErrors = {}
      this.generalError = ''
      /**
       * Send request
       */
      await getData({
        method: 'POST',
        url: 'card/order',
        data: this.form
      }).then((response) => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return
        } else if (response.status > 400) {
          this.generalError = response.data.message
          return
        }

        this.successMessage = response.message
        this.success = true
      })
    },

    async getOrderData () {
      this.validationErrors = {}
      this.generalError = ''
      /**
       * Send request
       */
      await getData({
        method: 'GET',
        url: 'card/order'
      }).then((response) => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return
        } else if (response.status > 400) {
          this.expected = true
          this.generalError = response.data.message
          return
        }

        this.options = response.data.options

        this.country_iso_code = this.options.country.db_name
        this.birthday = '1997-09-18'
      })
    }

  },

  mounted() {
    emitter.on('showCardOrderModal', () => this.visible = true)
  }
}
</script>
