<template>
  <div :class="`lang-${$i18n.locale}`">
      <Header />

      <router-view />

      <Footer />

      <default-popup />
  </div>
</template>

<script>
// components
import Header from "@/components/Partials/Header"
import Footer from "@/components/Partials/Footer"
// import {COOKIE_KEY__USER_TOKEN} from "@/store/constants";
// import * as Cookie from "js-cookie"
// import emitter from "@/plugins/bus";
import DefaultPopup from "@/components/Popups/DefaultPopup";

export default {
  components: {
    Header,
    Footer,
    DefaultPopup
  },

  mounted() {
    // if (!Cookie.get(COOKIE_KEY__USER_TOKEN) && this.$route.query.showLogin) {
    //   emitter.emit('showLoginModal')
    // } else {
    //   this.$router.replace({ 'query': { 'showLogin': undefined } });
    // }
    //
    // if (!Cookie.get(COOKIE_KEY__USER_TOKEN) && this.$route.query.showRegister) {
    //   emitter.emit('showRegisterModal')
    // } else {
    //   this.$router.replace({ 'query': { 'showRegister': undefined } });
    // }
  }
}
</script>
