<template>
  <div class="page page--account " :class="pageClass">
    <div class="container">
      <h2 class="page__title">{{ $t('account.myAccount')}}</h2>

      <div class="page__body">
      <sidebar></sidebar>

        <template v-if="isGetCardData">

          <template v-if="isActiveCard">
            <active-card :offers-data="offersData" :card-data="cardData"></active-card>
          </template>
          <template v-else>
            <not-active-card :card-data="cardData"></not-active-card>
          </template>

        </template>

        <card-order-modal/>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Account/Sidebar";
import ActiveCard from "@/components/Cards/Home/ActiveCard";
import NotActiveCard from "@/components/Cards/Home/NotActiveCard";
import {getData} from "@/helpers/getData";
import CardOrderModal from "@/components/Modals/CardOrderModal";
import {MYCARD_ROUTE, PROFILE_PAGE_DATA_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";
export default {
  name: 'MyProfile',
  components: {
    CardOrderModal,
    ActiveCard,
    Sidebar,
    NotActiveCard
  },
  data() {
    return {
      cardData: null,
      offersData: {
        activated: [],
        not_activated: []
      },
      isGetCardData: false,
      pageClass: ''
    }
  },

  computed: {

    isActiveCard () {
      return this.isGetCardData && this.cardData != null && this.cardData.status != 'pending'
    },

  },

  methods: {
    setResponseCardData (response) {
      this.isGetCardData = true;
      if (response && response.data) {
        this.cardData = response.data.card
        if (response.data.offers) {
          this.offersData = response.data.offers;
        }
      }

      if (this.isActiveCard) {
        this.pageClass = 'page--myoffers';
      } else {
        this.pageClass = 'page--mycard';
      }

      this.$Progress.finish()
    }

  },
  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(MYCARD_ROUTE).then(response => {
      /** Enter page and set data*/
      next(vm => {
        vm.setResponseCardData(response)

        getData(PROFILE_PAGE_DATA_ROUTE).then(response => {
          vm.$store.commit(PAGE_INFO, response.data)
        })
      })
    })
  }
}
</script>
