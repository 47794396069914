<template>
  <div class="carousel__pagination" :class="{ progress: $parent.options.paginationProgress, compact: pagination > 5 }">
    <button
        v-for="(page, index) in pagination"
        :key="index"
        class="carousel__pagination-button"
        :class="{ active: index === $parent.currentIndex }"
        @click="paginate(index)"
    >
      <span
          class="carousel__pagination-progress"
          ref="paginationProgress"
          v-if="index === $parent.currentIndex && $parent.options.paginationProgress"
      ></span>
    </button>
    <!-- /.carousel__arrow -->
  </div>
  <!-- /.carousel__pagination -->
</template>

<script>
export default {
  data: () => {
    return {
      progressInterval: ''
    }
  },

  watch: {
    '$parent.currentIndex': function() {
      clearInterval(this.progressInterval)

      this.progressAnimation()
    }
  },

  computed: {
    /**
     *
     * @returns {number}
     */
    pagination () {
      return this.$parent.slidesLength
    }
  },

  methods: {
    /**
     *
     * @param index
     */
    paginate (index) {
      this.$parent.currentIndex = index
    },

    progressAnimation () {
      let progressWidth = 0

      this.progressInterval = setInterval(() => {
        if (progressWidth < 100) {
          progressWidth++
        } else {
          progressWidth = 0
        }

        if (this.$refs.paginationProgress) {
          this.$refs.paginationProgress.style.width = progressWidth + "%"
        }
      }, this.$parent.options.autoPlaySeconds * 10)

      return this.progressInterval
    }
  },

  mounted() {
    this.progressAnimation()
  }
}
</script>
