<template>
  <li class="nav__item is-clickable" :class="activeClass">
    <slot name="parent" />

    <button type="button" class="nav__collapse-button" @click="toggle">
      <i :class="arrowClass"></i>
    </button>

    <div class="nav__dropdown header__dropdown" v-if="visible">
      <slot name="child" />
    </div>
  </li>
  <!-- /.nav__item -->
</template>

<script>
import url from "@/mixins/url";

export default {
  mixins: [
    url
  ],

  data () {
    return {
      visible: false
    }
  },

  computed: {
    activeClass () {
      return this.visible ? 'is-active' : ''
    },

    arrowClass () {
      return this.visible ? 'icon icon-arrow-up' : 'icon icon-arrow-down'
    }
  },

  methods: {
    toggle () {
      return this.visible = !this.visible
    }
  }
}
</script>
