import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import { getData } from '@/helpers/getData'

export default {
  [actionTypes.USER_LOGIN] (context, form) {
    /**
     * Sent User Login Request
     */
    return getData({
      method: 'POST',
      url: 'customer/auth/login',
      data: form
    }, context).then(response => {
      /**
       * Set Customer Token
       */

      context.commit(mutationTypes.USER_TOKEN, { token: response.data.access_token })
      context.commit(mutationTypes.USER_DATA, response.data.customer)

      /**
       * Return Response
       */
      return response
    })
  },

  [actionTypes.USER_LOGOUT] (context) {
    /**
     * Sent User Login Request
     */
    return getData({
      method: 'POST',
      url: 'customer/auth/logout'
    }, context).then(response => {
      /**
       * Set Customer Token
       */
      context.commit(mutationTypes.USER_TOKEN, {})
      context.commit(mutationTypes.USER_DATA, {})

      /**
       * Return Response
       */
      return response
    })
  },


  [actionTypes.USER_REGISTER_FORM] (context, form) {
    context.commit(mutationTypes.USER_REGISTER_FORM, form)
  },

  [actionTypes.USER_DATA] (context) {
    /**
     * Sent User Login Request
     */
    return getData({
      method: 'GET',
      url: 'customer'
    }, context).then(response => {
      if (response.data) {
        context.commit(mutationTypes.USER_DATA, response.data.customer)
      }
      /**
       * Return Response
       */
      return response
    })
  },

  [actionTypes.USER_CONFIRMATION] (context, form) {
    context.commit(mutationTypes.USER_CONFIRMATION, form)
  }

}
