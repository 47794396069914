export default {
  data () {
    return {
      regex: '/[^A-Za-z0-9\\s,.]/'
    }
  },

  methods: {
    validateInput (e) {
      let char = String.fromCharCode(e.keyCode);

      if (/^[A-Za-z0-9\s,.@_-]+$/.test(char)) {
        return true
      } else {
        e.preventDefault()
      }

      //e.target.value = e.target.value.replace(/[^A-Za-z0-9\s,.@_-]/, '')
    }
  }
}
