<template>
  <div class="card account__balance">
    <div class="card__body">
      <carousel :options="options" :grid="1">
        <slide
            :slide="0"
        >
          <span class="badge badge--success badge--opacity badge--pill">
            <i class="iconly iconly-cards"></i>
          </span>

          <div class="card__info">
            <h6 class="card__title">
              {{ $t('headerAccount.yourBalance') }}
            </h6>
            <p class="card__text">$ 1500.99</p>
          </div>
        </slide>

        <slide
            :slide="1"
        >
          <span class="badge badge--warning badge--opacity badge--pill">
            <i class="iconly iconly-bank"></i>
          </span>

          <div class="card__info">
            <h6 class="card__title">{{ $t('headerAccount.bonusBalance') }}</h6>
            <p class="card__text">250 Freespin</p>
          </div>
        </slide>

        <slide
            :slide="3"
        >
          <span class="badge badge--danger badge--opacity badge--pill">
            <i class="iconly iconly-gift"></i>
          </span>

          <div class="card__info">
            <h6 class="card__title">{{ $t('headerAccount.barBalance') }}</h6>
            <p class="card__text">$ 150</p>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
// components
import Carousel from "@/components/Sliders/Slider/Carousel"
import Slide from "@/components/Sliders/Slider/Slide"

export default {
  components: {
    Carousel,
    Slide
  },

  data () {
    return {
      options: {
        autoPlay: false,
        arrows: false,
        paginationProgress: false
      },
      hasProfile: false,
      hasPromotions: false
    }
  },
}
</script>
