import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  promotionOffersData: {},
  promotionJackpotData: {}
})

export default {
  state,
  getters,
  actions,
  mutations
}
