<template>
  <div class="page page--offers has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('promotions.title') }}</h1>

      <div class="page__body">
        <router-link
            v-for="(item,key) in offers"
            :key="key"
            class="card link-white"
            :to="singleRoute(item.id)"
        >
          <div class="card__body">
            <div class="card__image"><img :src="item.image_url"></div>
            <div class="card__info">
              <h3 class="card__title" v-html="item.title"></h3>
              <p v-html="item.sub_title">
              </p>
            </div>
          </div>
          <!-- /.card__body -->
        </router-link>
        <!-- /.card -->
      </div>
    </div>
  </div>
</template>

<script>

import {PROMOTION_OFFERS_DATA} from "@/store/mutation-types";
import url from "@/mixins/url";

export default {
  mixins: [
    url
  ],
  name: 'Promotions',
  data() {
    return {
      offers: []
    }
  },
  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotsRoute () {
      return this.goToUrl('promotions/jackpots')
    },
    /**
     *
     * @returns {string}
     */
    offersRoute () {
      return this.goToUrl('promotions/offers')
    }
  },
  methods: {
    setOffersData (data) {
      this.offers = Object.keys(data).map((key) => data[key]);
    },

    /**
     *
     * @returns {string}
     */
    singleRoute (id) {
      return this.goToUrl('promotions/offers/' + id)
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(PROMOTION_OFFERS_DATA).then((response) => {
        if (response.data) {
          vm.setOffersData(response.data.offers)
        }
        vm.$Progress.finish()
      })
    });

  },
  beforeRouteUpdate () {
    this.$store.dispatch(PROMOTION_OFFERS_DATA).then((response) => {
      if (response.data) {
        this.setOffersData(response.data.offers)
      }
      this.$Progress.finish()
    })
  }
}
</script>
