import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import {getData} from "@/helpers/getData";

export default {
    [actionTypes.PROMOTION_OFFERS_DATA](context, form = {}) {
        /**
         * Sent User Login Request
         */
        return getData({
            method: 'GET',
            url: 'promotion/offer',
            data: form
        }, context).then(response => {
            if (response.data) {
                context.commit(mutationTypes.PROMOTION_OFFERS_DATA, response.data)
                context.commit(mutationTypes.PAGE_INFO, response.data.page_data)
            }
            /**
             * Return Response
             */
            return response
        })
    },
    [actionTypes.PROMOTION_JACKPOT_DATA](context, form = {}) {
        /**
         * Sent User Login Request
         */
        return getData({
            method: 'GET',
            url: 'promotion/jackpot',
            data: form
        }, context).then(response => {
            if (response.data) {
                context.commit(mutationTypes.PROMOTION_JACKPOT_DATA, response.data.jackpots)
                context.commit(mutationTypes.PAGE_INFO, response.data.page_data)
            }
            /**
             * Return Response
             */
            return response
        })
    },
}
