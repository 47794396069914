<template>
  <div class="page page--device">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('devices.title') }}</h1>

      <div class="app-tape is-horizontal">
        <ul class="nav">
          <li class="nav__item">
            <a :href="appStoreUrl" class="nav__link" target="_blank">
              <img :src="appStoreIcon" alt="download from app store">
            </a>
          </li>

          <li class="nav__item">
            <a :href="playStoreUrl" class="nav__link" target="_blank">
              <img :src="googlePlayIcon" alt="download from google pay">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Device',

  data(){
    return {
      data: []
    }
  },

  computed: {
    appStoreIcon () {
      if (window.innerWidth <= 992) {
        return '/assets/app-store-small.svg'
      }

      return '/assets/app-store.svg'
    },

    appStoreUrl () {
      return process.env.VUE_APP_APP_STORE_URL
    },

    googlePlayIcon () {
      if (window.innerWidth <= 992) {
        return '/assets/play-store-small.svg'
      }

      return '/assets/play-store.svg'
    },

    playStoreUrl () {
      return process.env.VUE_APP_PLAY_STORE_URL
    }
  },

  created() {
    this.$Progress.finish()
  }
}
</script>
