<template>
  <div class="accordion__item">
    <button
        :style="{ 'backgroundColor': backgroundColor, 'color': textColor }"
        class="accordion__button"
        @click="showItem"
    >
      {{ title }}

      <div
          class="accordion__status"
          :style="collapseBtnBg"
      >
        <i class="icon" :class="visible ? 'icon-minus' : 'icon-plus'"></i>
      </div>
    </button>
    <!-- /.accordion__button -->

    <div
        class="accordion__content"
        :class="isActive"
        :style="{ 'backgroundColor': backgroundColor }"
    >
      <div :style="{ 'color': textColor }" v-html="content"></div>

      <button
          class="button button--danger"
          @click="cvModal"
          v-if="button"
      >
        {{ buttonText }}
      </button>
    </div>
    <!-- /.accordion__content -->
  </div>
  <!-- /.accordion__item -->
</template>

<script>
import emitter from "@/plugins/bus"
import { lightenDarkenColor } from "@/helpers/color"

export default {
  props: {
    id: {
      type: Number
    },

    title: {
      type: String,
      required: true
    },

    content: {
      type: Text,
      required: true
    },

    button: {
      type: Boolean
    },

    buttonText: {
      type: String
    },

    backgroundColor: {
      type: String
    },

    textColor: {
      type: String
    }
  },

  data () {
    return {
      visible: false
    }
  },

  computed: {
    /**
     * Check if visible
     */
    isActive () {
      return this.visible ? 'is-active' : ''
    },

    collapseBtnBg () {
      return this.backgroundColor ? {'backgroundColor': lightenDarkenColor(this.backgroundColor, -15)} : ''
    }
  },

  methods: {
    /**
     * Show accordion item
     */
    showItem () {
      this.visible = !this.visible
    },

    cvModal () {
      emitter.emit('showCvModal', {
        career_id: this.id
      })
    }
  }
}
</script>
