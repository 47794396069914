<template>
  <div class="page page--promotions has-bg">
    <div class="container">
      <h3 class="page__title text-center">{{ $t('promotions.title') }}</h3>

      <ul class="nav nav--horizontal page__nav">
        <li class="page__item">
          <router-link :to="offersRoute" class="page__link">{{ $t('promotions.offersTitle') }}</router-link>
        </li>

        <li class="page__item">
          <router-link :to="jackpotsRoute" class="page__link is-active">{{ $t('promotions.jackpotTitle') }}</router-link>
        </li>
      </ul>

      <div class="page__body">
        <template v-for="(item,key) in branches" :key="key">
          <router-link :to="viewRoute(item.id)">
            <div class="card">
              <div class="card__body">
            <span class="badge badge--danger badge--pill">
              <i class="icon icon-maps-and-flags"></i>
            </span>

                <h3 class="card__title text-center">{{ item.title }}</h3>
                <p class="text-center" v-html="item.address"></p>
              </div>
              <!-- /.card__body -->
            </div>
            <!-- /.card -->
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/mixins/url";
import {BRANCH_LIST} from "@/store/mutation-types";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Promotions',
  mixins: [
    url
  ],
  data() {
    return {
      branches: []
    }
  },

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotsRoute () {
      return this.goToUrl('promotions/jackpots')
    },
    /**
     *
     * @returns {string}
     */
    offersRoute () {
      return this.goToUrl('promotions/offers')
    },
  },
  methods: {
    /**
     * @param branchId
     */
    viewRoute (branchId) {
      return this.goToUrl('promotions/jackpots/' + branchId)
    },
    /**
     *
     * @param data
     */
    setBranchData (data) {
      this.branches = Object.keys(data).map((key) => data[key]);
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(BRANCH_LIST).then((response) => {
        vm.setBranchData(response.data);
        vm.$Progress.finish()
      })
      vm.$store.dispatch(PAGE_INFO, {
        path: 'jackpots'
      })
    });
  },
  beforeRouteUpdate () {
    this.$store.dispatch(BRANCH_LIST).then((response) => {
      this.setBranchData(response.data);
      this.$Progress.finish()
    })
    this.$store.dispatch(PAGE_INFO, {
      path: 'jackpots'
    })
  }
}
</script>
