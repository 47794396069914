import * as types from "@/store/mutation-types";

export default {
  [types.PAGE_INFO] (state, pageInfo) {
    if (!pageInfo) {
      state.pageInfo = {}
    }

    Object.keys(pageInfo).map((key) => {
      let value = pageInfo[key]

      switch(key) {
        case 'title':
          document.title = 'betlive.club - ' + value;
          break;
        case 'description':
          document.getElementsByTagName('meta')['description'].content = value
          break;
        case 'keywords':
          document.getElementsByTagName('meta')['keywords'].content = value
          break;
        case 'og_title':
          document.querySelector('meta[property="og:title"]').setAttribute("content", value);
          break;
        case 'og_description':
          document.querySelector('meta[property="og:description"]').setAttribute("content", value);
          break;
        case 'image':
          document.querySelector('meta[property="og:image"]').setAttribute("content", value);
          break;
        case 'locale':
          document.documentElement.setAttribute('lang', value)
          break;
      }
    });

    document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);

    state.pageInfo = pageInfo
  },
}
