<template>
  <div class="header__nav">
    <ul class="nav nav--horizontal has-line">
      <li class="nav__item">
        <router-link :to="aboutRoute" active="is-active" class="nav__link">{{ $t('menu.about') }}</router-link>
      </li>
      <!-- /.nav__item -->

      <li class="nav__item">
        <router-link :to="newsRoute" class="nav__link">{{ $t('menu.news') }}</router-link>
      </li>
      <!-- /.nav__item -->

      <li class="nav__item">
        <router-link :to="galleryRoute" class="nav__link">{{ $t('menu.gallery') }}</router-link>
      </li>
      <!-- /.nav__item -->

      <li class="nav__item is-dropdown">
        <router-link :to="offersRoute" class="nav__link">{{ $t('menu.promotions') }}</router-link>

        <div class="nav__dropdown header__dropdown">
          <ul class="nav">
            <li class="nav__item">
              <router-link :to="jackpotRoute" class="nav__link">{{ $t('menu.jackpot') }}</router-link>
            </li>
            <!-- /.nav__item -->
            <li class="nav__item">
              <router-link :to="leaderboardRoute" class="nav__link">{{ $t('menu.leaderboard') }}</router-link>
            </li>
            <!-- /.nav__item -->
          </ul>
        </div>
      </li>
      <!-- /.nav__item -->

      <li class="nav__item">
        <router-link :to="cardRoute" class="nav__link">{{ $t('menu.clubCard') }}</router-link>
      </li>

      <li class="nav__item">
        <router-link :to="blogRoute" class="nav__link">{{ $t('menu.blog') }}</router-link>
      </li>
      <!-- /.nav__item -->
    </ul>
  </div>
  <!-- /.header__nav -->
</template>

<script>
import url from "@/mixins/url";

export default {
  mixins: [
    url
  ],

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    aboutRoute () {
      return this.goToUrl('about-us')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    newsRoute () {
      return this.goToUrl('news')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    galleryRoute () {
      return this.goToUrl('gallery')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    offersRoute () {
      return this.goToUrl('promotions/offers')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotsRoute () {
      return this.goToUrl('promotions/jackpots')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    careerRoute () {
      return this.goToUrl('careers')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    cardRoute () {
      return this.goToUrl('club-card')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    leaderboardRoute () {
      return this.goToUrl('branch/leaderboard')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotRoute () {
      return this.goToUrl('stream/2')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    providersRoute () {
      return this.goToUrl('provider')
    },

    /**
     *
     * @returns {{params: {locale: *}}}
     */
    blogRoute () {
      return this.goToUrl('blog')
    },
  }
}
</script>
