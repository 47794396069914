<template>
  <transition :name="dir" :class="slideClass">
    <div
        class="carousel__slide"
        ref="carouselSlide"
        @touchstart="startTouch"
        @touchend="endTouch"
        @touchmove="touchMove"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    slide: {
      required: true
    }
  },

  watch: {
    'slide' () {
      alert('asd')
    }
  },

  data () {
    return {
      xDown: null,
      yDown: null,
      isMoving: '',
      direction: ''
    }
  },

  computed: {
    /**
     *
     * @returns {boolean}
     */
    isActive () {
      return this.slide === this.$parent.currentIndex
    },

    /**
     *
     * @returns {string}
     */
    dir () {
      return this.$parent.slideDirection;
    },

    slideClass () {
      let classes = []

      if (this.isActive) {
        classes.push('is-active')
      }

      if (this.isActive && this.isMoving) {
        classes.push('is-moving', this.isMoving)
      }

      return classes
    }
  },

  methods: {
    getTouches (e) {
      return e.touches
    },

    startTouch (e) {
      const touch = this.getTouches(e)
      this.xDown = touch[0].clientX
      this.yDown = touch[0].clientY
    },

    endTouch () {
      if (this.direction === 'slide-right') {
        this.$parent.changeSlide()
      } else {
        this.$parent.changeSlide('slide-left')
      }

      this.xDown = null
      this.yDown = null

      this.isMoving = ''
    },

    touchMove (e) {
      if (!this.xDown || !this.yDown) {
        return
      }

      let xUp = e.touches[0].clientX
      let yUp = e.touches[0].clientY

      let xDiff = this.xDown - xUp
      let yDiff = this.yDown - yUp

      if (Math.abs( xDiff ) > Math.abs( yDiff )) {
        if ( xDiff > 0 ) {
          this.isMoving = 'move-right'
          this.direction = 'slide-right'
        } else {
          this.isMoving = 'move-left'
          this.direction = 'slide-left'
        }
      }
    }
  },

  mounted() {
    if (window.innerWidth > 992) {
      this.$refs.carouselSlide.style.width = (this.$parent.$refs.carousel.clientWidth / this.$parent.grid) + 'px'
    } else {
      this.$refs.carouselSlide.style.width = this.$parent.$refs.carousel.clientWidth + 'px'
    }
  }
}
</script>
