<template>
  <p class="text text--danger is-error" v-if="errorText">
    <template v-if="excepted">
      {{ errorText }}
    </template>

    <template v-else>
      {{ $t('error.whoops') }}
    </template>
  </p>
</template>

<script>
export default {
  props: {
    errorText: String,
    excepted: Boolean
  }
}
</script>
