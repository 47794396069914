import * as actionTypes from '@/store/action-types'
import * as mutationTypes from '@/store/mutation-types'
import { getData } from '@/helpers/getData'
import {LOCAL_STORAGE_LOCALIZATION_TEXT} from "@/store/constants";

export default {
  [actionTypes.LOCALIZATION_TEXT] (context, form = {}){
    /**
     * Get Localization Text
     */
    return getData({
      method: 'GET',
      url: 'localization-text',
      data: form
    }, context).then(response => {

      context.commit(mutationTypes.SET_LOCALE, form.locale)

      if (response.data) {
        localStorage.setItem(LOCAL_STORAGE_LOCALIZATION_TEXT, JSON.stringify(response.data.text));
      }

      /** Return Response*/
      return response
    })
  },

}
