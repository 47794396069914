import { createI18n } from 'vue-i18n'

// import locales
// import en from './translations/en.json'
// import ka from './translations/ka.json'

/**
 * i18n config
 */
const i18n = createI18n({
    locale: process.env.VUE_APP_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE || 'en'
})

export default i18n
