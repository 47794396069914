<template>
  <div class="page page--account">
    <div class="container">
      <h2 class="page__title">{{ $t('account.myAccount')}}</h2>

      <div class="page__body">
        <sidebar></sidebar>

        <div class="page__content">
          <div class="page__content-header">
            {{ $t('profile.myProfileTitle') }}
            <button class="header__burger-close" @click="close">
              <i class="icon icon-close"></i>
            </button>
          </div>

          <div class="text text--success is-alert" v-if="anyChanges">
            {{ $t('alert.profile.success') }}
          </div>

          <error-text :error-text="generalError"></error-text>
          <div class="list list--border">
            <list-item
                :label="$t('profile.fullName')"
                :value="userData.full_name"
                :toggleable="false"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <div class="form__group">
                  <input v-model="form.first_name" type="text" class="form__control" :placeholder="$t('profile.firstName')">
                </div>

                <validation-texts :errors="validationErrors.first_name"></validation-texts>

                <div class="form__group">
                  <input v-model="form.last_name" type="text" class="form__control" :placeholder="$t('profile.lastName')">
                </div>

                <validation-texts :errors="validationErrors.last_name"></validation-texts>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateFullName') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.birthDate')"
                :value="userData.birth_date_format"
                :toggleable="false"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <div class="form__group">
                  <datepicker
                      v-model="datePicker"
                      placeholder="Birth Date"
                      inputFormat="yyyy-MM-dd"
                  />
                </div>

                <validation-texts :errors="validationErrors.birth_date"></validation-texts>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateBirthDate') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.idNumber')"
                :value="userData.id_number"
                :toggleable="false"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <div class="form__group">
                  <input v-model="form.id_number" type="text" class="form__control" :placeholder="$t('profile.idNumber')">
                </div>

                <validation-texts :errors="validationErrors.id_number"></validation-texts>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateIdNumber') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.password')"
                :value="$t('profile.passwordEditTitle')"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <div class="form__group">
                  <input v-model="form.password" type="password" class="form__control" :placeholder="$t('profile.password')">
                  <validation-texts :errors="validationErrors.password"></validation-texts>
                </div>

                <div class="form__group">
                  <input v-model="form.password_confirmation" type="password" class="form__control" :placeholder="$t('profile.repeatPassword')">
                  <validation-texts :errors="validationErrors.password_confirmation"></validation-texts>
                </div>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateFullName') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.email')"
                :value="userData.email"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <error-text :error-text="generalError"></error-text>


                <template v-if="!isSentConfirm">
                  <div class="form__group">
                    <input v-model="form.email" type="text" class="form__control" :placeholder="$t('profile.email')">
                    <validation-texts :errors="validationErrors.email"></validation-texts>
                  </div>
                </template>

                <!--<input v-model="form.verify_code" type="text" class="form__control" :placeholder="$t('profile.verify_code')">-->
                <div class="form__group form__group-digits" v-if="verifyCodeIsSent.email">
                  <input v-model="digits.first" type="text" :maxlength="1" class="form__control" ref="digitFirst" @keyup="changeDigit">
                  <input v-model="digits.second" type="text" :maxlength="1" class="form__control" ref="digitSecond" @keyup="changeDigit">
                  <input v-model="digits.third" type="text" :maxlength="1" class="form__control" ref="digitThird" @keyup="changeDigit">
                  <input v-model="digits.fourth" type="text" :maxlength="1" class="form__control" ref="digitFourth" @keyup="changeDigit">
                </div>

                <template v-if="isSentConfirm">
                  <div class="modal__digit-seconds" v-if="seconds > 0">
                    {{ $t('profile.didNotReceivedCode') }} {{ seconds }} {{ $t('profile.seconds') }}
                  </div>

                  <button type="button" :disabled="!editingMode" class="button button--link" @click="sendVerification('email')" v-if="seconds === 0">
                    {{ $t('profile.updateEmailReSendVerification') }}
                  </button>

                </template>

                <validation-texts :errors="validationErrors.verify_code"></validation-texts>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="sendVerification('email')" v-if="!verifyCodeIsSent.email">
                  {{ $t('profile.updateEmailSendVerification') }}
                </button>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit" v-if="verifyCodeIsSent.email">
                  {{ $t('profile.updateEmailNumber') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.mobileNumber')"
                :value="userData.phone_number"
                :toggleable="false"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content">
                <error-text :error-text="generalError"></error-text>
                <template v-if="!isSentConfirm">
                  <div class="form__group">
                    <input v-model="form.phone_number" type="text" class="form__control" :placeholder="$t('profile.mobileNumber')">
                    <validation-texts :errors="validationErrors.phone_number"></validation-texts>
                  </div>
                </template>

                <!--<input v-model="form.verify_code" type="text" class="form__control" :placeholder="$t('profile.verify_code')">-->
                <div class="form__group form__group-digits" v-if="verifyCodeIsSent.phone">
                  <input v-model="digits.first" type="text" :maxlength="1" class="form__control" ref="digitFirst" @keyup="changeDigit">
                  <input v-model="digits.second" type="text" :maxlength="1" class="form__control" ref="digitSecond" @keyup="changeDigit">
                  <input v-model="digits.third" type="text" :maxlength="1" class="form__control" ref="digitThird" @keyup="changeDigit">
                  <input v-model="digits.fourth" type="text" :maxlength="1" class="form__control" ref="digitFourth" @keyup="changeDigit">
                </div>

                <template v-if="isSentConfirm">
                  <div class="modal__digit-seconds" v-if="seconds > 0">
                    {{ $t('profile.didNotReceivedCode') }} {{ seconds }} {{ $t('profile.seconds') }}
                  </div>

                  <button type="button" :disabled="!editingMode" class="button button--link" @click="sendVerification('phone')" v-if="seconds === 0">
                    {{ $t('profile.updatePhoneReSendVerification') }}
                  </button>

                </template>

                <validation-texts :errors="validationErrors.verify_code"></validation-texts>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="sendVerification('phone')" v-if="!verifyCodeIsSent.phone">
                  {{ $t('profile.updatePhoneSendVerification') }}
                </button>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit" v-if="verifyCodeIsSent.phone">
                  {{ $t('profile.updateMobileNumber') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.country')"
                :value="countryLabel"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content" v-if="options.countries">
                <div class="form__group">
                  <Multiselect
                      v-model="userData.country_id"
                      :options="options.countries"
                      id="profileCountrySelect"
                      :placeholder="$t('profile.country')"/>
                  <validation-texts :errors="validationErrors.country_id"></validation-texts>
                </div>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateCountry') }}
                </button>
              </div>
            </list-item>

            <list-item
                :label="$t('profile.city')"
                :value="cityLabel"
                :toggle-off="$t('profile.edit')"
                :toggle-on="$t('profile.editCancel')"
            >
              <div class="list__item-content" v-if="options.cities">
                <div class="form__group">
                  <Multiselect
                      v-model="userData.city_id"
                      :options="options.cities"
                      :placeholder="$t('profile.city')"/>
                  <validation-texts :errors="validationErrors.city_id"></validation-texts>
                </div>

                <button type="button" :disabled="!editingMode" class="button button--danger" @click="profileEdit">
                  {{ $t('profile.updateCity') }}
                </button>
              </div>
            </list-item>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getData} from "@/helpers/getData";
import {getValidationsTextWithKey} from "@/helpers/responseError";
import * as mutationTypes from "@/store/mutation-types";
import ListItem from "@/components/Elements/List/ListItem";
import Sidebar from "@/components/Account/Sidebar";
import Multiselect from "@vueform/multiselect";
import {PROFILE_PAGE_DATA_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";
import Datepicker from "vue3-datepicker";
import {add} from "date-fns";
import emitter from "@/plugins/bus";

export default  {
  name: 'MyProfile',
  components: {
    Sidebar,
    ListItem,
    ValidationTexts,
    ErrorText,
    Multiselect,
    Datepicker
  },

  data () {
    return {
      loading: false,
      editingMode: true,
      anyChanges: false,
      datePicker: '',
      form: {
        first_name: '',
        last_name: '',
        email: '',
        id_number: '',
        password: '',
        password_confirmation: '',
        birth_date: '',
        phone_number: '',
        verify_code: ''
      },
      digits: {
        first: '',
        second: '',
        third: '',
        fourth: ''
      },
      userData: {},
      validationErrors: {},
      generalError: '',
      options: {},
      verifyCodeIsSent: {
        email: false,
        phone: false
      },
      seconds: 60,
    }
  },
  created () {
    if (this.getUserData) {
      this.setUserData();
    }
    this.getProfileData()
  },
  watch: {
    'getUserData'(){
      this.setUserData();
    },

    'datePicker' (value) {
      this.form.birth_date = ref(add(value, {}).toLocaleDateString())
    },

    'digits.first' (value) {
      if (value) {
        this.$refs.digitSecond.focus()
      }
    },

    'digits.second' (value) {
      if (value) {
        this.$refs.digitThird.focus()
      } else {
        this.$refs.digitFirst.focus()
      }
    },

    'digits.third' (value) {
      if (value) {
        this.$refs.digitFourth.focus()
      } else {
        this.$refs.digitSecond.focus()
      }
    },

    'digits.fourth' (value) {
      if (!value) {
        this.$refs.digitThird.focus()
      }
    }
  },
  computed: {
    getUserData () {
      return this.$store.getters.userData;
    },
    isSentConfirm () {
      return this.verifyCodeIsSent['email'] || this.verifyCodeIsSent['phone']
    },

    cityLabel () {
      return this.userData.city_label ? this.userData.city_label : this.$t('profile.cityUpdateTitle')
    },

    countryLabel () {
      return this.userData.country_label ? this.userData.country_label : this.$t('profile.countryUpdateTitle')
    }
  },
  methods: {

    getProfileData () {
      this.loading = true;
      getData({
        method: 'GET',
        url: 'customer/profile-data',
      }).then(response => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }
        this.$store.commit(mutationTypes.USER_DATA, response.data.customer)
        this.options = response.data.options;

      })
      this.loading = false;

    },

    sendVerification (method) {
      this.resetIsSentVerifyCodes();

      if (method == 'email') {
        this.form.verify_method = 'profile_email_update'
      } else if (method == 'phone') {
        this.form.verify_method = 'profile_phone_update'
      }

      getData({
        method: 'POST',
        url: 'customer/verification',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
        } else if (response.status > 400) {
          this.generalError = response.data.message;
        } else {
          this.seconds = response.data.verify.duration;
          this.verifyCodeIsSent[method] = true
          this.setSecondsInterval()
        }

        this.$Progress.finish();
      })
    },

    resetIsSentVerifyCodes () {
      this.form.verify_code = ''
      this.seconds = 0
      this.verifyCodeIsSent = {
          email: false,
          phone: false
      }
    },

    profileEdit () {
      this.loadingUpdate();

      getData({
        method: 'POST',
        url: 'customer/profile',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
        } else if (response.status > 400) {
          this.generalError = response.data.message;
        } else {
          this.resetIsSentVerifyCodes()
          this.$store.commit(mutationTypes.USER_DATA, response.data.customer)
          this.editingMode = false;
          this.anyChanges = true;
          window.scrollTo(0, 0);
          this.$Progress.finish();

          emitter.emit('closeListToggleable')
        }
      })

      this.loadingUpdate();
    },

    toggleEditForm () {

    },

    loadingUpdate() {
      this.loading = !this.loading;
    },

    close () {
      this.$router.push( { path: '/', params: { locale: this.$i18n.locale } })
    },

    setUserData () {
      this.form = this.getUserData;
      this.userData = this.form;
      this.datePicker = ref(new Date(this.form.birth_date_format))
      this.form.birth_date = this.form.birth_date_format;

      setTimeout(() => {
        this.$Progress.finish();
      }, 100);
    },
    setSecondsInterval () {
        setInterval(() => {
        if (this.seconds > 0) {
          --this.seconds
        }
      }, 1000);
    },
    changeDigit () {
      if (this.digits.first && this.digits.second && this.digits.third && this.digits.fourth) {
        this.form.code = this.digits.first + this.digits.second + this.digits.third + this.digits.fourth
      }
    }
  },
  mounted() {
    if (this.isSentConfirm) {
      this.setSecondsInterval()
    }
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(PROFILE_PAGE_DATA_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data)
      })
    })
  }
}
</script>
