import { createStore } from 'vuex';

/**
 * Modules
 */
import user from './modules/user'
import locale from './modules/locale'
import page from './modules/page'
import branch from './modules/branch'
import promotion from './modules/promotion'

const store = createStore({
  modules: {
    user,
    locale,
    page,
    branch,
    promotion
  },
})

export default store
