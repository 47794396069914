export default {
  methods: {
    /**
     *
     * @param ref
     * @param className
     * @returns {HTMLCollectionOf<Element>}
     */
    getByClassName (ref, className) {
      return this.$refs[ref].getElementsByClassName(className)
    }
  }
}
