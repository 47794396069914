import Cookie from 'js-cookie'
import i18n from "@/i18n";
import store from "@/store";
import {LOCALIZATION_TEXT} from "@/store/action-types";

export const getDefaultLocale = process.env.VUE_APP_LOCALE
export const getSupportedLocales = process.env.VUE_APP_SUPPORTED_LOCALES.split(',')
export const getCurrentLocale = Cookie.get('locale') ? Cookie.get('locale') : getDefaultLocale

/**
 *
 * @param locale
 */
export const loadLocaleFile = (locale) => {
  return import(`@/translations/${locale}.json`)
}

/**
 *
 * @param locale
 * @returns {*}
 */
export const isLocaleSupported = (locale) => {
  return getSupportedLocales.includes(locale)
}

/**
 *
 * @param to
 * @param from
 * @param next
 * @returns {*}
 */
export const routeMiddleware = (to, from, next) => {
  /**
   * Config object
   */
  const config = {
    localeParam: to.params.locale,
    defaultLocale: getDefaultLocale,
    supportedLocales: getSupportedLocales,
    localeCookie: Cookie.get('locale')
  }

  /**
   * Check if cookie exist
   */
  if (config.localeCookie) {
    /**
     * Check if new locale includes in supported locales
     */
    if (config.supportedLocales.includes(config.localeParam)) {
      /**
       * Check if current locale isn't equal new locale
       */
      if (config.localeCookie !== config.localeParam) {
        Cookie.set('locale', config.localeParam)
      }

      /**
       * Set i18n locale
       */
      i18n.locale = config.localeParam
    } else {
      if (to.fullPath !== '/' + config.localeCookie + to.fullPath) {
        next({ path: '/' + config.localeCookie + to.fullPath })
      }
    }
  } else {
    if (config.supportedLocales.includes(config.localeParam)) {
      /**
       * Set i18n locale
       */
      i18n.locale = config.localeParam

      /**
       * Save locale in cookie
       */
      Cookie.set('locale', config.localeParam)
    } else {
      /**
       * Set i18n locale
       */
      i18n.locale = config.defaultLocale

      /**
       * Save locale in cookie
       */
      Cookie.set('locale', config.defaultLocale)
    }

    /**
     * Check locale param
     */
    if (!config.supportedLocales.includes(config.localeParam)) {
      /**
       * Check full path
       */
      if (to.fullPath !== '/' + config.defaultLocale + to.fullPath) {
        next({ path: '/' + config.defaultLocale + to.fullPath })
      }
    }
  }

  next();
}

/**
 *
 * @param locale
 * @returns {Promise<any>}
 */
export const getTranslations = (locale) => {
  return store.dispatch(LOCALIZATION_TEXT, {
    locale: locale
  })
}

/**
 *
 * @param to
 * @returns {string}
 */
export const i18nRoute = (to) => {
  const path = {...to}.path
  const locale = {...to}.locale

  return '/' + locale + '/' + path
}
