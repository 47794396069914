<template>
  <div class="placeholder"
       :class="placeholderClass"
       :style="placeholderStyle">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Placeholder",

  props: {
    is: {
      type: String,
      default: "default"
    },

    customClass: {
      type: String
    },

    width: {
      type: String,
      required: true
    },

    height: {
      type: String,
      required: true
    }
  },

  computed: {
    placeholderClass () {
      const type = "placeholder--" + this.is;
      const customCLass = this.customClass

      return [
        type,
        customCLass
      ]
    },

    placeholderStyle () {
      return {
        "width": this.width,
        "height": this.height
      }
    }
  }
}
</script>
