import Axios from 'axios'
import Cookie from 'js-cookie'
import app from '@/main'
import { COOKIE_KEY__USER_TOKEN } from '@/store/constants'

/**
 * Sent Request To The Server
 *
 * @param request
 * @returns {Promise<any | null>}
 */
export const getData = (request) => {
  let baseUrl = process.env.VUE_APP_BACK_END_API_URL

  if (app) {
    app.$Progress.start()
  }

  /**
   * Set config
   */
  const config = {
    method: request.method,
    url: baseUrl + '/' + request.url
  }

  /**
   * Check request
   */
  request.method === 'GET' || request.method === 'DELETE'
    ? config.params = request.data
    : config.data = request.data

  /**
   * Check If Request Have Headers Parameter And If It Have Than Add It In Config
   */
  if (request.headers) {
    config.headers = request.headers
  }

  if (Cookie.get(COOKIE_KEY__USER_TOKEN)) {
    /**
     * Set Authorization Bearer Token To Header
     * @type {{Authorization: string}}
     */
    config.headers = {
      ...(config.headers || {}),
      Authorization: 'Bearer ' + Cookie.get(COOKIE_KEY__USER_TOKEN)
    }
  }

  if (Cookie.get('locale')) {
    config.headers = {
      ...(config.headers || {}),
      locale: Cookie.get('locale')
    }
  }

  /**
   * Send Request With Axios
   */
  return Axios(config).then(response => {
    return response.status ? response.data : null
  }).catch(error => {
    return error.response
  })
}

export const fetchData = (request) => {
  return getData({
    method: request.method,
    url: request.url,
    data: request.data
  }).then(response => {
    return response
  })
}
