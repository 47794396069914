<template>
  <div class="page page--player has-bg">
    <div class="container">
      <div class="page__title">Player</div>

      <div class="player-container">
        <vue3-video-player
            :src="sampleVideo"
        ></vue3-video-player>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Player",

  data () {
    return {
      sampleVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
    }
  }
}
</script>
