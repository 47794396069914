export default {
  methods: {
    hidden () {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    },

    visible () {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    },

    enableScroll () {
      this.visible()
    },

    disableMobileScroll () {
      if (window.innerWidth <= 992) {
        this.hidden()
      }
    },

    enableMobileScroll () {
      if (window.innerWidth <= 992) {
        this.visible()
      }
    },
  }
}
