<template>
  <header class="header" :class="fixedHeader">
    <div class="container">
      <div class="header__inner">
        <div class="header__left">
          <button type="button" class="header__burger-button" @click="showBurgerMenu"></button>

          <router-link :to="homeRoute">
            <img src="/assets/betlive-logo.svg" class="header__brand" alt="სლოტ კლუბი - სამორინე Betlive Club">
          </router-link>

          <HeaderNavigation />
        </div>
        <!-- /.header__left -->

        <div class="header__right">
          <header-account />
          <header-translation />
        </div>
        <!-- /.header__right -->
      </div>
      <!-- /.header__inner -->
    </div>
    <!-- /.container -->

    <login-modal />
    <register-modal />
    <confirm-modal />
    <password-reset-modal />
    <header-burger-menu />
  </header>
  <!-- /.header -->
</template>

<script>
// helpers
import emitter from "@/plugins/bus"

// components
import HeaderNavigation from "@/components/Partials/Header/HeaderNavigation"
//import HeaderSearch from "@/components/Partials/Header/HeaderSearch"
import HeaderAccount from "@/components/Partials/Header/HeaderAccount"
import HeaderTranslation from "@/components/Partials/Header/HeaderTranslation"
import LoginModal from "@/components/Modals/LoginModal"
import RegisterModal from "@/components/Modals/RegisterModal"
import ConfirmModal from "@/components/Modals/ConfirmModal"
import PasswordResetModal from "@/components/Modals/PasswordResetModal";
import HeaderBurgerMenu from "@/components/Partials/Header/HeaderBurgerMenu"
import Cookie from "js-cookie";
import url from "@/mixins/url";
import {COOKIE_KEY__USER_TOKEN} from "@/store/constants";
import * as actionTypes from "@/store/action-types";
import {USER_CONFIRMATION} from "@/store/action-types";

export default {
  mixins: [
    url
  ],

  components: {
    PasswordResetModal,
    ConfirmModal,
    HeaderNavigation,
//    HeaderSearch,
    HeaderAccount,
    HeaderTranslation,
    LoginModal,
    RegisterModal,
    HeaderBurgerMenu
  },

  data () {
    return {
      isFixed: false,
      customer: {}
    }
  },

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    homeRoute () {
      return this.goToUrl('')
    },

    loggedIn () {
      return this.$store.getters.userToken || Cookie.get('token')
    },

    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },

    fixedHeader () {
      return this.isFixed ? 'is-fixed' : ''
    }
  },

  methods: {
    showBurgerMenu () {
      emitter.emit('showBurgerMenu')
    },

    handleScroll () {
      this.isFixed = window.scrollY > 80;
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      this.customer = this.$store.getters.userData

      if (this.customer.verified_at === null) {
        this.$store.dispatch(USER_CONFIRMATION, { openManual: true })
        emitter.emit('showConfirmModal');
      }
    }, 1000);
  },

  mounted() {
    if(Cookie.get(COOKIE_KEY__USER_TOKEN)) {
      this.$store.dispatch(actionTypes.USER_DATA).then( () => {
        this.$Progress.finish()
      })
    }
  }
}
</script>
