import {LOCAL_STORAGE_LOCALIZATION_TEXT} from "@/store/constants";

export default {
  currentLocale: state => state.currentLocale,

  /**
   * Get Localization Text.
   */
  localizationText: () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_TEXT));
  },
}
