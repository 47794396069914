<template>
  <div class="app-tape">
    <ul class="nav">
      <li class="nav__item">
        <a :href="appStoreUrl" class="nav__link" target="_blank">
          <img :src="appStoreIcon" alt="download from app store">
        </a>
      </li>

      <li class="nav__item">
        <a :href="playStoreUrl" class="nav__link" target="_blank">
          <img :src="googlePlayIcon" alt="download from google pay">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    appStoreIcon () {
      if (window.innerWidth <= 992) {
        return '/assets/app-store-small.svg'
      }

      return '/assets/app-store.svg'
    },

    appStoreUrl () {
      return process.env.VUE_APP_APP_STORE_URL
    },

    googlePlayIcon () {
      if (window.innerWidth <= 992) {
        return '/assets/play-store-small.svg'
      }

      return '/assets/play-store.svg'
    },

    playStoreUrl () {
      return process.env.VUE_APP_PLAY_STORE_URL
    },
  }
}
</script>
