<template>
  <div
      class="drawer"
      :class="{'show': visible}"
  >
    <div class="drawer__inner" :style="{'width': width}">
      <div class="drawer__header">
        {{  title }}

        <button
            class="modal__close"
            @click="handleClose"
        >
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="drawer__body">
        <slot name="body" />
      </div>
    </div>
    <div
        class="drawer__overlay"
        @click="handleClose"
    ></div>
  </div>
</template>

<script>
import emitter from "@/plugins/bus";

export default {
  props: {
    title: {
      type: String
    },

    width: {
      type: String
    }
  },

  data () {
    return {
      visible: false
    }
  },

  methods: {
    handleClose () {
      this.visible = false
    }
  },

  mounted() {
    emitter.on('showDrawer', () => this.visible = true)
  }
}
</script>
