<template>
  <div class="popup" v-if="visible">
    <div class="popup__inner">
      <div class="popup__box">
        <button class="popup__close" @click="close">
          <i class="icon icon-close"></i>
        </button>

        <div class="popup__body">
          <img v-lazy="item.banner">

          <div class="popup__text text-center" v-html="item.content" />

          <button type="button" class="button button--danger" @click="handleButton">
            {{ item.button_text }}
          </button>
        </div>
        <!-- /.popup__body -->
      </div>
      <!-- /.popup__box -->
    </div>
    <!-- /.popup__inner -->
  </div>
  <!-- /.popup -->
</template>

<script>
import {getData} from "@/helpers/getData"
import Cookie from 'js-cookie'
import url from "@/mixins/url"
import {COOKIE_KEY__USER_TOKEN} from "@/store/constants";
import emitter from "@/plugins/bus";

export default {
  mixins: [
      url
  ],

  data () {
    return {
      visible: false,
      item: {}
    }
  },

  watch: {
    '$route' () {
      if (!Cookie.get(COOKIE_KEY__USER_TOKEN) && this.$route.query.showLogin) {
        emitter.emit('showLoginModal')
      } else {
        this.$router.replace({ 'query': { 'showLogin': undefined } });
      }

      if (!Cookie.get(COOKIE_KEY__USER_TOKEN) && this.$route.query.showRegister) {
        emitter.emit('showRegisterModal')
      } else {
        this.$router.replace({ 'query': { 'showRegister': undefined } });
      }
    }
  },

  methods: {
    /**
     * Close modal
     */
    close () {
      Cookie.set('popup', true)

      this.visible = false
    },

    handleButton () {
      this.close()

      if (this.item.url) {
        this.$router.push(this.goToUrl(this.item.url))
      }
    }
  },

  mounted() {
    if (!Cookie.get('popup')) {
      getData({
        method: 'GET',
        url: 'popup'
      }).then(response => {
        const data = response.data.item

        if (data) {
          data.images.map(item => {
            data.banner = item.full_src
          })

          this.item = data

          this.visible = true
        }

        Cookie.set('popup', true)

        return response
      })
    }
  }
}
</script>
