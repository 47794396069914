<template>
  <div class="layout" ref="layout">
    <component :is="layout">
      <router-view/>
    </component>

    <vue-progress-bar />
  </div>
</template>

<script>
// import {LOCAL_STORAGE_LOCALIZATION_TEXT} from "@/store/constants";

export default {
  data () {
    return {
      loading: true,
    }
  },

  computed: {
    /**
     *
     * @returns {*|string}
     */
    layout () {
      return (this.$route.meta.layout || 'Default');
    }
  },

  watch: {
    '$route' (to) {
      document.title = this.$t(to.meta.title) || process.env.VUE_APP_TITLE;

      window.scrollTo(0, 0);
    }
  },

  beforeCreate() {
    // if (!localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_TEXT)) {
    //   this.$store.dispatch(LOCALIZATION_TEXT,{
    //     locale: Cookie.get('locale')
    //   }).then( response => {
    //     this.$i18n.setLocaleMessage(process.env.VUE_APP_LOCALE, response.data.text)
    //   })
    // } else {
    //   this.$i18n.setLocaleMessage(process.env.VUE_APP_LOCALE, JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_TEXT)))
    // }
  },
}
</script>
