<template>
  <div class="card subscribe-card">
    <div class="card__body">
      <h3 class="card__title">{{  $t('subscribe.title') }}</h3>
      <error-text :error-text="generalError"></error-text>
      <form @keyup.enter="subscribe" class="form" v-if="!success">
        <div class="row">
          <div class="col-6">
            <div class="form__group" :class="validationErrors.first_name ? 'has-error' : ''">
              <input type="text" v-model="form.first_name" class="form__control" @keypress="validateInput($event)" :placeholder="$t('subscribe.firstName')">
            </div>
            <validation-texts :errors="validationErrors.first_name"></validation-texts>
          </div>

          <div class="col-6">
            <div class="form__group" :class="validationErrors.last_name ? 'has-error' : ''">
              <input type="text" v-model="form.last_name" class="form__control" @keypress="validateInput($event)" :placeholder="$t('subscribe.lastName')">
            </div>
            <validation-texts :errors="validationErrors.last_name"></validation-texts>
          </div>

          <div class="col-12">
            <div class="form__group" :class="validationErrors.email ? 'has-error' : ''">
              <input type="text" v-model="form.email" class="form__control"  @keypress="validateInput($event)" :placeholder="$t('subscribe.email')">
            </div>
            <validation-texts :errors="validationErrors.email"></validation-texts>
          </div>

          <div class="col-12">
            <button type="button" class="button" @click="subscribe">{{  $t('subscribe.buttonTitle') }}</button>
          </div>
        </div>
      </form>
      <template v-else>
        <div class="card__message">
          <i class="icon icon-checked"></i>
          <p>{{ this.successMessage }}</p>
        </div>
      </template>
    </div>
    <!-- /.card__body -->
  </div>
  <!-- /.card -->
</template>

<script>
import {getData} from "@/helpers/getData";
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getValidationsTextWithKey} from "@/helpers/responseError";
import form from "@/mixins/form";

export default {
  components: {ValidationTexts, ErrorText},
  mixins: [
    form
  ],
  data () {
    return {
      success: false,
      form: {
        first_name: '',
        last_name: '',
        email: ''
      },
      validationErrors: {},
      generalError: '',
      successMessage: ''
    }
  },

  methods: {
    async subscribe () {
      /**
       * Send request
       */
      await getData({
        method: 'POST',
        url: 'subscribe/active',
        data: this.form
      }).then((response) => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status > 400) {
          this.generalError = response.data.message;
          return;
        }

        this.successMessage = response.message;
        this.success = true
      })
    }
  }
}
</script>
