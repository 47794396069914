<template>
  <div class="card contact-card">
    <div class="card__body">
      <h3 class="card__title">{{ $t('home.contactCardTitle') }}</h3>


      <div class="card__carousel">
        <carousel :options="options" :grid="1">
          <slide
              v-for="item in contactData"
              :key="item"
              :slide="item">
            <div class="home__contact-item">
              <div class="home__contact-title">{{ item.title }}</div>
              <div class="home__contact-description" v-html="item.address"></div>
            </div>
          </slide>
        </carousel>
      </div>

      <div class="card__info">
        <div class="home__contact-item">
          <div class="home__contact-value">
            <span class="badge badge--danger badge--pill">
              <i class="icon icon-phone-call"></i>
            </span>
            <div class="home__contact-value" v-html="defaultContactData.phones">
            </div>
          </div>
        </div>

        <div class="home__contact-item">
          <div class="home__contact-value">
            <span class="badge badge--danger badge--pill">
              <i class="icon icon-envelope"></i>
            </span>
            <span v-html="defaultContactData.emails"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card__body -->
  </div>
  <!-- /.card -->
</template>

<script>
// components
import Carousel from "@/components/Sliders/Slider/Carousel"
import Slide from "@/components/Sliders/Slider/Slide"

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    contactData: {
      type: Array
    },
    defaultContactData: {
      type: Object
    }
  },

  data: () => {
    return {
      options: {
        autoPlay: false,
        pagination: false,
        arrows: true,
        grid: 2
      }
    }
  }
}
</script>
