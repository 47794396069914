<template>
  <div class="accordion" ref="accordion">
    <template v-for="item in data" :key="item.id">
      <accordion-item
          v-if="item.title"
          :id="item.id"
          :title="getValue('title', item)"
          :content="getValue('content', item)"
          :button="button"
          :button-text="buttonText"
          :background-color="backgroundColor"
          :text-color="textColor"
      ></accordion-item>
    </template>
  </div>
</template>

<script>
import AccordionItem from "@/components/Accordion/AccordionItem"

export default {
  components: {
    AccordionItem
  },

  props: {
    data: {
      type: Array,
      required: true
    },

    button: {
      type: Boolean
    },

    buttonText: {
      type: String
    },

    backgroundColor: {
      type: String
    },

    textColor: {
      type: String
    }
  },

  methods: {
    getValue(title, item) {
      return item[title]
    }
  }
}
</script>
