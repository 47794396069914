<template>
  <div class="page page--jackpot-show has-bg">
    <div class="container">
      <h3 class="page__title text-center">{{ $t('promotions.title') }}</h3>

      <div class="page__body">
        <div class="page__roll">
          <router-link :to="jackpotsRoute" class="button button--primary button--pill is-flex align-items-center justify-content-center">
            <i class="icon icon-arrow-line-left"></i>
          </router-link>

          <h4>{{ branchTitle }}</h4>
        </div>

        <div v-for="(item,key) in jackpots"
             :key="key"
            class="card">
          <div class="card__body align-items-center justify-content-space-between">
            <div class="card__left">
              <img src="/assets/betlive-logo.svg" class="header__brand" alt="სლოტ კლუბი - სამორინე Betlive Club">
            </div>

            <div class="card__right">
              <div class="card card--mega is-flex align-items-center justify-content-center text-center" v-if="item.mega">
                <div class="card__body">
                  <div class="card__title">{{ item.mega.title }}</div>
                  <div class="card__subtitle">{{ item.mega.value }} <i class="lari lari-bolder"></i></div>
                </div>
              </div>

              <div class="card card--mini is-flex align-items-center justify-content-center text-center" v-if="item.min">
                <div class="card__body">
                  <div class="card__title">{{ item.min.title }}</div>
                  <div class="card__subtitle">{{ item.min.value }} <i class="lari lari-bolder"></i></div>
                </div>
              </div>

              <div class="card card--max is-flex align-items-center justify-content-center text-center" v-if="item.max">
                <div class="card__body">
                  <div class="card__title">{{ item.max.title }}</div>
                  <div class="card__subtitle">{{ item.max.value }} <i class="lari lari-bolder"></i></div>
                </div>
              </div>

              <div class="card card--super is-flex align-items-center justify-content-center text-center" v-if="item.super">
                <div class="card__body">
                  <div class="card__title">{{ item.super.title }}</div>
                  <div class="card__subtitle">{{ item.super.value }} <i class="lari lari-bolder"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/mixins/url";
import {PROMOTION_JACKPOT_DATA} from "@/store/mutation-types";

export default {
  mixins: [
    url
  ],
  name: 'JackpotShow',
  data() {
    return {
      jackpots: [],
      branch: {}
    }
  },
  created () {
    setTimeout(() => this.$Progress.finish(), 200);
  },

  computed: {
    /**
     *
     * @returns {{params: {locale: *}}}
     */
    jackpotsRoute () {
      return this.goToUrl('promotions/jackpots')
    },
    /**
     *
     * @returns {string}
     */
    offersRoute () {
      return this.goToUrl('promotions/offers')
    },
    branchTitle () {
      return this.branch ? this.branch.title : ''
    }
  },
  methods: {
    setJackpotData(data) {
      this.branch = data.jackpots.branch;
      if (data.jackpots.total_items) {
        this.jackpots = Object.keys(data.jackpots.items).map((key) => data.jackpots.items[key]);
      }
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(PROMOTION_JACKPOT_DATA,{
        branch_id: vm.$route.params.branchId
      }).then((response) => {
        vm.setJackpotData(response.data);
        vm.$Progress.finish()
      })
    });
  },
  beforeRouteUpdate () {
    this.$store.dispatch(PROMOTION_JACKPOT_DATA,{
      branch_id: this.$route.params.branchId
    }).then((response) => {
      this.setJackpotData(response.data);
      this.$Progress.finish()
    })
  }
}
</script>
