<template>
  <div class="home page has-bg">
    <div class="container">
      <div class="row">
        <template v-for="(item, index) in data" :key="index">
          <template v-if="item.key === 'home_slider' && existSliders(item)">
            <div class="col-12">
              <single-carousel
                  :slides="slidesData"
              >
              </single-carousel>
            </div>
          </template>

          <template v-else-if="item.key === 'home_feature' && existSliders(item)">
            <div
                class="feature-card"
                :class="item.isTwin ? 'col-6' : 'col-12'">
              <feature-card
                  :items="getComponentData(item, item.key)"
              ></feature-card>
            </div>
          </template>
        </template>
      </div>

      <div class="home__contact">
        <contact-card v-if="getContacts.length" :default-contact-data="getDefaultContactData" :contact-data="getContacts"></contact-card>
        <subscribe-card />
      </div>

      <home-map
          v-if="coordinates.length"
          :city-maps="cityMaps"
      ></home-map>
    </div>
  </div>
</template>

<script>
// helpers
import {getStaticPage} from "@/helpers/module"
import {getData} from "@/helpers/getData"

// components
import SingleCarousel from "@/components/Sliders/SingleCarousel"
import ContactCard from "@/components/Cards/Home/ContactCard"
import SubscribeCard from "@/components/Cards/Home/SubscribeCard"
import FeatureCard from "@/components/Feature/FeatureCard"
import homeMap from "@/components/Home/Map"
import {HOME_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Home',

  components: {
    SingleCarousel,
    ContactCard,
    SubscribeCard,
    FeatureCard,
    homeMap
  },
  data () {
    return {
      contactData: {},
      defaultContactData: {},
      cityMaps: {},
      data: [],
      slidesData: [],
      items: [
        {
          id: 1,
          title: 'Explore our games'
        }
      ]
    }
  },
  computed: {
    slides (){
      return [];
    },
    coordinates () {
      var coordinates = [];

      Object.keys(this.contactData).map((key) => {
        var fields = this.contactData[key];
        coordinates.push({
          contactId: fields.id,
          location: fields.coordinates
        });
      })

      return coordinates;
    },

    getContacts () {
      var contactData = [];

      Object.keys(this.contactData).map((key) => {
        var fields = this.contactData[key];
        contactData.push({
          title: fields.title,
          address: fields.address
        });
      })

      return contactData;
    },

    getDefaultContactData () {
      if (this.defaultContactData && this.defaultContactData.fields) {
        return {
          phones: this.defaultContactData.fields.phones.value,
          emails: this.defaultContactData.fields.emails.value
        }
      }

      return {}
    }

  },

  methods: {
    getComponentData (data) {
      return this.getSliders(data);
    },
    existSliders (data) {
      return data.fields && data.fields.images && data.fields.images.value
    },
    getSliders (data) {
      let images = [];

      if (data.fields && data.fields.images && data.fields.images.value) {
        data.fields.images.value.map((imageData) => {
          images.push({
            image: imageData.full_src.full_src ? imageData.full_src.full_src : imageData.full_src.url,
            imageMobile: this.getFieldByKey(imageData.fields,'responsive_image').value,
            title: this.getFieldByKey(imageData.fields,'title').value,
            description: this.getFieldByKey(imageData.fields,'sub_title').value,
            buttonTitle: this.getFieldByKey(imageData.fields,'action_button_title').value,
            buttonUrl: this.getFieldByKey(imageData.fields,'action_button_url').value,
            actionUrl: this.getFieldByKey(imageData.fields,'action_url').value,
            outsideType: this.getFieldByKey(imageData.fields,'outside_type').value
          })
        });
      }

      return images;
    },

    getFieldByKey(fields, key){
      let field = {};

      fields.map((fieldData) => {
        if (fieldData['name'] == key) {
          field = fieldData;
        }
      });

      return field;
    },

    /**
     *
     * @param data
     */
    setData (data) {
      let list = getStaticPage(data, 'home');

      this.defaultContactData = data.data.items['default_contact'][0]
      this.contactData = data.data.branches
      this.cityMaps = data.data.grouped_branches

      list.map((fieldData, index) => {
        let twin = false

        if (fieldData.key === 'home_feature') {
          if (list[index + 1]) {
            twin = list[index + 1]['key'] === 'home_feature'
          } else if(list[index - 1]) {
            twin = list[index - 1]['key'] === 'home_feature'
          }
        } else if (fieldData.key === 'home_slider') {
          this.slidesData = [];

          fieldData.fields.images.value.map((imageData) => {
            const respoImage = this.getFieldByKey(imageData.fields,'responsive_image').value.image
            this.slidesData.push({
              image: imageData.full_src.full_src ? imageData.full_src.full_src : imageData.full_src.url,
              imageMobile: respoImage && respoImage.full_src ? respoImage.full_src : '',
              title: this.getFieldByKey(imageData.fields,'title').value,
              description: this.getFieldByKey(imageData.fields,'sub_title').value,
              buttonTitle: this.getFieldByKey(imageData.fields,'action_button_title').value,
              buttonUrl: this.getFieldByKey(imageData.fields,'action_button_url').value,
              actionUrl: this.getFieldByKey(imageData.fields,'action_url').value,
              outsideType: this.getFieldByKey(imageData.fields,'outside_type').value
            })
          })
        }

        console.log(this.slidesData)

        fieldData.isTwin = twin
      })

      this.data = list
    },
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(HOME_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(HOME_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }

}
</script>
