/**
 *
 * Home Routes
 */
export const HOME_ROUTE = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['home', 'branches','default-contact'],
    page: 'home'
  }
}

/**
 *
 * News Routes
 */
export const NEWS_ROUTE = {
  method: 'GET',
  url: 'page?modules[]=news',
  data: {
    page: 'news'
  }
}

/**
 *
 * Provider Routes
 */
export const PROVIDER_ROUTE = {
  method: 'GET',
  url: 'page?modules[]=provider',
  data: {
    page: 'provider'
  }
}

/**
 *
 * Gallery Routes
 */
export const GALLERY_ROUTE = {
  method: 'GET',
  url: 'page?modules[]=gallery',
  data: {
    page: 'gallery'
  }
}

/**
 *
 * Club Card Routes
 */
export const CLUB_CARD_ROUTE = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['card-terms-conditions','card-info'],
    page: 'club_card'
  }
}


/**
 *
 * FAQ Routes
 */
export const FAQ_ROUTE = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['faq'],
    page: 'faq'
  }
}

/**
 *
 * Terms&Conditions Routes
 */
export const TERMS_CONDITIONS = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['terms-conditions'],
    page: 'terms_conditions'
  }
}

/**
 *
 * Privacy Policy Routes
 */
export const PRIVACY_ROUTE = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['privacy-policy'],
    page: 'privacy_policy'
  }
}

/**
 *
 * Privacy Policy Routes
 */
export const ABOUT_ROUTE = {
  method: 'GET',
  url: 'page',
  data: {
    modules: ['about','branches','default-contact'],
    page: 'about'
  }
}

/**
 *
 * Privacy Policy Routes
 */
export const CAREER_ROUTE = {
  method: 'GET',
  url: 'career'
}

/**
 *
 * Profile Routes
 */
export const ACCOUNT_ROUTE = {
  method: 'GET',
  url: 'customer/profile-data'
}

/**
 *
 * Privacy Policy Routes
 */
export const MYCARD_ROUTE = {
  method: 'GET',
  url: 'card'
}

/**
 *
 * Home Routes
 */
export const PROFILE_PAGE_DATA_ROUTE = {
  method: 'GET',
  url: 'customer/page-data'
}

/**
 *
 * Provider Routes
 */
export const OFFER_SINGLE_ROUTE = {
  method: 'GET',
  url: 'promotion/offer/:id'
}
