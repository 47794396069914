<template>
  <div class="page page--club has-bg">
    <div class="container">
      <img class="page--club__image" :src="getBackImage">
      <h1 class="page__title">{{getTitle }}</h1>
      <p class="page__text" v-html="getSubTitle"></p>

      <ul class="list">
        <template v-for="(item,key) in getExpenses" :key="key">
          <li class="list__item">
            <i class="icon icon-circle-with-check-symbol"></i> {{ item.title }}
          </li>
        </template>
      </ul>

      <button type="button" class="button button--secondary page__button" @click="orderCard">{{  $t('clubCard.getCardTitle') }}</button>

      <div class="benefits">
        <h2 class="page__title">{{  getBenefitTitle }}</h2>

        <div class="page__items">
          <template v-for="(cardBenefit,key) in getBenefits" :key="key">
            <div class="card card--secondary card--shadow">
              <div class="card__body">
                <div class="card__title">{{  cardBenefit.title }}</div>
                <p class="card__text" v-html="cardBenefit.description"></p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <button class="button button--secondary page--club__button" @click="orderCard">{{  $t('clubCard.getCardTitle') }}</button>

      <h3 class="page__title">{{  getTermsConditionsTitle }}</h3>

      <accordion :data="getTermsConditions"></accordion>
      <card-order-modal/>
    </div>
  </div>
</template>

<script>
// components
import Accordion from "@/components/Accordion/Accordion"
import {getData} from "@/helpers/getData";
import {getStaticPage} from "@/helpers/module";
import CardOrderModal from "@/components/Modals/CardOrderModal";
import emitter from "@/plugins/bus";
import {CLUB_CARD_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";
import Cookie from "js-cookie";

export default {
  name: 'Club Card',
  components: {
    CardOrderModal,
    Accordion
  },
  data () {
    return {
      cardInfo: {},
      cardTermsConditions: {}
    }
  },

  computed: {
    getTitle () {
      return this.cardInfo.fields && this.cardInfo.fields.card_info_title ? this.cardInfo.fields.card_info_title.value : ''
    },

    getSubTitle () {
      return this.cardInfo.fields && this.cardInfo.fields.card_info_sub_title ? this.cardInfo.fields.card_info_sub_title.value : ''
    },

    getBenefitTitle () {
      return this.cardInfo.fields && this.cardInfo.fields.benefit_title ? this.cardInfo.fields.benefit_title.value : ''
    },

    getBackImage () {
      return this.cardInfo.fields && this.cardInfo.fields.front_card_image ? this.cardInfo.fields.front_card_image.value : ''
    },

    getBenefits () {
      if (!this.cardInfo.fields) {
        return [];
      }

      return this.cardInfo.fields.card_benefit.value.map((cardBenefit) => {
        var title = this.getFieldByKey(cardBenefit,'title')
        var description = this.getFieldByKey(cardBenefit,'sub_title')
        return {
          title: title ? title.value : '',
          description: description ? description.value : ''
        }
      });
    },

    getExpenses () {
      if (!this.cardInfo.fields) {
        return [];
      }

      return this.cardInfo.fields.checkboxes.value.map((cardBenefit) => {
        var title = this.getFieldByKey(cardBenefit,'title')
        return {
          title: title ? title.value : ''
        }
      });
    },

    getTermsConditionsTitle () {
      return this.cardTermsConditions.fields && this.cardTermsConditions.fields.card_terms_conditions_title ? this.cardTermsConditions.fields.card_terms_conditions_title.value : ''
    },

    getTermsConditions () {
      if (!this.cardTermsConditions.fields) {
        return [];
      }

      return this.cardTermsConditions.fields.terms.value.map((cardBenefit) => {
      var title = this.getFieldByKey(cardBenefit,'title')
      var description = this.getFieldByKey(cardBenefit,'sub_title')
        return {
          title: title ? title.value : '',
          content: description ? description.value : ''
        }
      });
    }

  },

  methods: {
    orderCard() {
      if (this.$store.getters.userToken || Cookie.get('token')) {
        emitter.emit('showCardOrderModal')
      } else {
        emitter.emit('showLoginModal')
      }
    },
    getFieldByKey(fields,key){
      var field = {};
      fields.map((fieldData) => {
        if (fieldData['name'] == key) {
          field = fieldData;
        }
      });

      return field;
    },

    async fetchData () {
      /**
       * Send request
       */
      return await getData({
        method: 'GET',
        url: 'page',
        data: {
          modules: ['card-terms-conditions','card-info']
        }
      }).then(response => {
        return response
      })
    },

    /**
     *
     * @param data
     */
    setData (data) {
      this.cardTermsConditions = getStaticPage(data, 'card_terms_conditions')[0];
      this.cardInfo = getStaticPage(data, 'card_info')[0];
    }
  },

  beforeRouteEnter: (to, from, next) => {
    getData(CLUB_CARD_ROUTE).then(response => {
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    getData(CLUB_CARD_ROUTE).then(response => {
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
