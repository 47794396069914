<template>
  <modal
    :title="$t('confirm.title')"
    custom-class="confirm-modal"
    v-if="visible"
  >
    <form class="form">
      <error-text :error-text="generalError"></error-text>

      <template v-if="isSentConfirm">
        <div class="modal__title">{{ $t('confirm.successfullySentVerificationCode') }}</div>
      </template>

      <template v-if="isSentConfirm">
        <div class="modal__digit">
          <div class="modal__digit-message">
            {{ $t('confirm.weSentCodeEmailAndPhone') }}
          </div>

          <div class="row">
            <div class="col-6">
              <div class="modal__digit-label">{{ $t('confirm.phoneCode') }}</div>
              <div class="form__group form__group-digits" :class="validationErrors.code ? 'has-error' : ''" ref="digitInputs">
                <input v-model="digits.first" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('first', $event)" @keyup.enter="confirm">
                <input v-model="digits.second" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('second', $event)" @keyup.enter="confirm">
                <input v-model="digits.third" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('third', $event)" @keyup.enter="confirm">
                <input v-model="digits.fourth" type="text" :maxlength="1" class="form__control" @keyup="changeDigit('fourth', $event)" @keyup.enter="confirm">
              </div>

              <validation-texts :errors="validationErrors.code"></validation-texts>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isSentConfirm">
        <div class="modal__digit-seconds">
          <template v-if="seconds > 0">
            <span class="text">{{ $t('confirm.didNotReceivedCode') }} {{ seconds }} {{ $t('confirm.seconds') }}</span>
          </template>

          <button type="button" class="button button--link" :disabled="seconds > 0" @click="resendConfirmation">{{ $t('confirm.resendConfirmation') }}</button>
        </div>
      </template>

      <template v-if="isSentConfirm">
        <button type="button" class="button button--danger button--block login-modal__submit" @click="confirm">{{ $t('confirm.confirmButton') }}</button>
      </template>

      <template v-if="isOpenManual && !isSentConfirm">
        <button type="button" class="button button--danger button--block login-modal__submit" @click="sendConfirmation">{{ $t('confirm.sendConfirmation') }}</button>
      </template>

    </form>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// types

// components
import Modal from "@/components/Modals/Modal"
import {getData} from "@/helpers/getData";
import ErrorText from "@/components/Error/ErrorText";
import {getValidationsTextWithKey} from "@/helpers/responseError";
import ValidationTexts from "@/components/Error/ValidationTexts";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";

export default {
  components: {
    ValidationTexts,
    Modal,
    ErrorText
  },

  data () {
    return {
      loading: false,
      generalError: '',
      visible: false,
      sentConfirm: false,
      seconds: 60,
      totalDigits: 3,
      form: {
        phone_number: '',
        id: '',
        code: ''
      },
      digits: {
        first: '',
        second: '',
        third: '',
        fourth: '',
        fifth: '',
        sixth: '',
        seventh: '',
        eighth: '',
      },
      validationErrors: {
        code: ''
      },
      interval: null
    }
  },

  watch: {
    'visible' () {
      if (this.visible) {
        if (!this.isOpenManual) {
          this.seconds = this.getCustomerRegisterData.verify.duration;
        }
        this.form = {
          phone_number: this.getCustomerPhone,
          id: '',
          code: ''
        }
        this.resetCode()
      } else {
        this.form = {
          phone_number: '',
            id: '',
            code: ''
        }
        this.resetCode()
      }
    }
  },

  computed: {
    isOpenManual () {
      return this.$store.getters.userConfirmation.openManual
    },

    isSentConfirm () {
      return this.sentConfirm || !this.isOpenManual
    },

    getConfirmText () {
      let confirmText = this.$t('confirm.confirmText');
      confirmText = confirmText.replace('{email}', this.getCustomerPhone);
      confirmText = confirmText.replace('{mobileNumber}', this.getCustomerEmail);
      return confirmText;
    },

    getCustomerEmail () {
      return this.getCustomerRegisterData ? this.getCustomerRegisterData.email : '';
    },
    getCustomerPhone () {
      if (this.form.phone_number) {
        return this.form.phone_number;
      }

      return this.getCustomerRegisterData ? this.getCustomerRegisterData.phone_number : '';
    },

    getCustomerRegisterData () {
      return this.$store.getters.userRegisterForm;
    },

    getCustomerId () {
      return this.$store.getters.userData ? this.$store.getters.userData.id : '';
    }

  },

  methods: {
    async resendConfirmation () {
      clearInterval(this.interval)
      this.seconds = 0
      this.resetCode()
      this.form.code = '';
      this.loadingUpdate();
      this.generalError = '';
      const response = await this.resendConfirm();
      this.loadingUpdate();
      if (response.status == 500) {
        this.generalError = response.data.message;
        return;
      }

      if (response.data && response.data.verify) {
        this.seconds = response.data.verify.duration;
        this.setSecondsInterval()
      }

      this.sentConfirm = true;
    },

    async sendConfirmation () {
      if (this.isOpenManual) {
        this.form.id = this.getCustomerId;
      }
      this.sentConfirm = false;
      this.loadingUpdate();
      this.generalError = '';
      var response = await this.resendConfirm();
      this.loadingUpdate();
      if (response.status == 500) {
        this.generalError = response.data.message;
        return;
      }

      if (response.data && response.data.verify) {
        this.seconds = response.data.verify.duration;
        this.setSecondsInterval()
      }

      this.sentConfirm = true;
    },

    async resendConfirm () {
      return getData({
        method: 'POST',
        url: 'customer/auth/verify/resend',
        data: this.form
      }).then(response => {
        this.$Progress.finish()

        return response
      })
    },

    confirm () {
      this.loadingUpdate();
      this.generalError = '';

      getData({
        method: 'POST',
        url: 'customer/auth/verify',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }

        if (response.data.access_token) {
          this.$store.commit(mutationTypes.USER_TOKEN, { token: response.data.access_token })
          // Cookie.set(COOKIE_KEY__USER_TOKEN, response.data.access_token)
        }

        this.visible = false;

        this.$store.dispatch(actionTypes.USER_DATA);
      })

      this.loadingUpdate();

      this.$Progress.finish()
    },

    loadingUpdate () {
      this.loading = !this.loading;
    },

    back () {
      this.visible = false;
      if (this.isOpenManual) {
        emitter.emit('showLoginModal');
      } else {
        emitter.emit('showRegisterModal');
      }
    },

    changeDigit (input, event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.digits[input] = event.key
      }

      this.form.code = this.digits.first + this.digits.second + this.digits.third + this.digits.fourth
    },
    resetCode () {
      this.digits = {
          first: '',
          second: '',
          third: '',
          fourth: ''
      }
    },
    setSecondsInterval () {
      this.interval = setInterval(() => {
        if (this.seconds > 0) {
          --this.seconds
        }
      }, 1000);
    },
  },

  mounted() {
    const vm = this

    this.setSecondsInterval()

    emitter.on('showConfirmModal', () => {
      this.visible = true

      if (this.visible) {
        setTimeout(() => {
          const digitInputs = this.$refs.digitInputs.querySelectorAll('input')

          digitInputs.forEach((input, index) => {
            input.addEventListener("keyup", () => {
              if (input.value.length > 0 && index !== vm.totalDigits) {
                digitInputs[index + 1].focus();
              } else if (!input.value.length && index !== 0) {
                digitInputs[index - 1].focus();
              }
            })
          })
        }, 500);
      }
    })
  }
}
</script>
