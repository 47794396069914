<template>
  <div class="home__maps">
    <div class="home__maps-title">{{ $t('home.mapTitle') }}</div>
    <template v-if="cityMaps.length > 1">
      <ul class="nav nav--horizontal page__nav">
        <template v-for="(city,key) in cityMaps" :key="key">
          <li class="page__item" v-if="city.branches.length">
            <span style="cursor: pointer" @click="changeCityTab(city)" class="page__link" :class="activeTab === city.id ? 'is-active' : ''">{{ city.label }}</span>
          </li>
        </template>
      </ul>
    </template>
    <GoogleMap
        api-key="AIzaSyAON4jF8mLwGb0WZ1yQIO0IAVPUPtj07h8"
        class="home__map"
        :center="center"
        :styles="mapStyle"
        :disableDefaultUi="true"
        :zoom="11">
        <Marker v-for="(item,key) in coordinates" :key="key" :options="{ position: item.location, icon: { url: '/assets/map-marker.svg' }}" />
    </GoogleMap>
  </div>
</template>

<script>

import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  components: {
    GoogleMap,
    Marker
  },

  props: {
    cityMaps: {
      type: Array
    }
  },
  created () {
    if (this.cityMaps.length) {
      this.setCityLocations(this.cityMaps[0])
    }
  },
  data () {
    return {
      coordinates: [],
      activeTab: this.cityMaps.length ? this.cityMaps[0].id : '',
      center: { lat: 41.70647378018225, lng: 44.78698393897497 },
      mapStyle: [
        {elementType: 'geometry', stylers: [{color: '#323648'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#1F1F22'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#49494D'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'poi',
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#2A2C38'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#2A3040'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#212a37'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#746855'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#1f2835'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#2f3948'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#17263c'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '#49494D'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#17263c'}]
        }
      ]
    }
  },
  methods: {
    changeCityTab (city) {
      this.activeTab = city.id
      this.coordinates = [];
      this.setCityLocations(city)
    },
    setCityLocations (city) {
      city.branches.map((branchItem,key) => {
        if (key == 0) {
          this.center = branchItem.coordinates
        }
        this.coordinates.push({
          contactId: branchItem.id,
          location: branchItem.coordinates
        })
      })
    }
  }
}
</script>
