import * as types from "@/store/mutation-types";

export default {
  [types.PROMOTION_OFFERS_DATA] (state, promotionOffersData) {
    state.promotionOffersData = promotionOffersData
  },
  [types.PROMOTION_JACKPOT_DATA] (state, promotionJackpotData) {
    state.promotionJackpotData = promotionJackpotData
  },
}
