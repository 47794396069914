<template>
  <div class="page page--account page--mybalance">
    <div class="container">
      <h2 class="page__title">My account</h2>

      <div class="page__body">
        <div class="page__sidebar">
          <ul class="nav page__list">
            <li class="page__item">
              <router-link to="/" class="page__link">My profile</router-link>
            </li>

            <li class="page__item">
              <router-link to="/" class="page__link">My card</router-link>
            </li>

            <li class="page__item">
              <router-link to="/" class="page__link is-active">Manage Balance</router-link>
            </li>

            <li class="page__item">
              <router-link to="/" class="page__link">Log out</router-link>
            </li>
          </ul>
        </div>

        <div class="page__right flex-1">
          <div class="page__right-header">
            Manage Balance
          </div>

          <div class="mobile__balance show-md-and-down">
            <balance />
            <router-link to="/" class="button button--danger">Deposit</router-link>
          </div>

          <div class="page__content page__content--sm hide-md-and-down">
            <div class="card card--secondary">
              <div class="card__body">
                <span class="badge badge--success badge--opacity badge--pill">
                  <i class="iconly iconly-cards"></i>
                </span>

                <div class="card__info">
                  <h6 class="card__title">Your Balance</h6>
                  <p class="card__text">$ 1500.99</p>
                </div>
              </div>
            </div>

            <div class="card card--secondary">
              <div class="card__body">
                <span class="badge badge--warning badge--opacity badge--pill">
                  <i class="iconly iconly-bank"></i>
                </span>

                <div class="card__info">
                  <h6 class="card__title">Bonus Balance</h6>
                  <p class="card__text">250 Freespin</p>
                </div>
              </div>
            </div>

            <div class="card card--secondary">
              <div class="card__body">
                <span class="badge badge--danger badge--opacity badge--pill">
                  <i class="iconly iconly-gift"></i>
                </span>

                <div class="card__info">
                  <h6 class="card__title">Bar Bonus</h6>
                  <p class="card__text">$ 150</p>
                </div>
              </div>
            </div>

            <div class="card card--transparent">
              <div class="card__body">
                <router-link to="/" class="button button--danger">Deposit</router-link>
              </div>
            </div>
          </div>

          <div class="page__content-title show-md-and-down">Last Transactions</div>

          <div class="page__content">
            <h3 class="page__title">Last Transactions</h3>

            <div class="list list--border">
              <div class="list__item list__item--horizontal">
                <div class="list__label">Deposit:</div>
                <div class="list__value">$ 1000</div>
                <div class="list__value margin-left-auto">27 November, 2019</div>
              </div>

              <div class="list__item list__item--horizontal">
                <div class="list__label">Deposit:</div>
                <div class="list__value">$ 1000</div>
                <div class="list__value margin-left-auto">27 November, 2019</div>
              </div>

              <div class="list__item list__item--horizontal">
                <div class="list__label">Deposit:</div>
                <div class="list__value">$ 1000</div>
                <div class="list__value margin-left-auto">27 November, 2019</div>
              </div>

              <div class="list__item list__item--horizontal">
                <div class="list__label">Deposit:</div>
                <div class="list__value">$ 1000</div>
                <div class="list__value margin-left-auto">27 November, 2019</div>
              </div>

              <div class="list__item list__item--horizontal">
                <div class="list__label">Deposit:</div>
                <div class="list__value">$ 1000</div>
                <div class="list__value margin-left-auto">27 November, 2019</div>
              </div>
            </div>
          </div>

          <div class="page__content-title show-md-and-down">Freespin History</div>

          <div class="page__content">
            <h3 class="page__title">Freespin History</h3>

            <div class="list list--border">
              <div class="list__item">
                <div class="list__label text-uppercase is-flex">
                  Freespin Deposited
                  <div class="margin-left-auto">27 November, 2019</div>
                </div>
                <div class="list__value">250 Freespins</div>
              </div>

              <div class="list__item">
                <div class="list__label text-uppercase is-flex">
                  Freespin Deposited
                  <div class="margin-left-auto">27 November, 2019</div>
                </div>
                <div class="list__value">250 Freespins</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Balance from "@/components/Account/Balance";

export default {
  name: 'MyProfile',

  components: {
    Balance
  }
}
</script>
