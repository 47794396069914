<template>
  <div class="page page--faq">
    <div class="container">
      <h1 class="page__title">{{ getTitle }}</h1>

      <template v-if="getAllTermsConditions.length">
        <accordion :data="getAllTermsConditions">
        </accordion>
      </template>

    </div>
  </div>
</template>

<script>
// components
import Accordion from "@/components/Accordion/Accordion"
import {getData} from "@/helpers/getData";
import { TERMS_CONDITIONS} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'TermsConditions',

  data(){
    return {
      data: []
    }
  },

  components: {
    Accordion
  },
  computed: {
    getAllTermsConditions () {
      if (this.data && this.data.fields) {
        return this.data.fields.terms.value.map((termsData,key) => {
          return {
            id: key,
            title: this.getFieldByKey(termsData,'title').value,
            content: this.getFieldByKey(termsData,'sub_title').value,
          }
        });
      }

      return [];
    },
    getTitle () {
      if (this.data && this.data.fields) {
        return this.data.fields.terms_conditions_title.value
      }

      return ''
    }
  },
  methods: {
    getFieldByKey(fields,key){
      var field = {};
      fields.map((fieldData) => {
        if (fieldData['name'] == key) {
          field = fieldData;
        }
      });

      return field;
    },
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = data.data.items.terms_conditions[0];
    }
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(TERMS_CONDITIONS).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.setData(response)
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(TERMS_CONDITIONS).then(response => {
      /**
       * Enter page and set data
       */
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
