<template>
  <div class="blog" v-if="blogData">
      <div class="blog__image" v-if="blogData.header">
          <img :src="blogData.header.desktop_image" class="desktop-blog-image"/>
          <img :src="blogData.header.mobile_image" class="mobile-blog-image"/>
      </div>
      <h1 class="blog__title">ბოლო ბლოგები</h1>
      <div class="blog__items">
          <BlogItem v-for="(blog,i) in blogData.items.data" :key="i" :data="blog"/>
      </div>
      <div class="blog__pagination">
          <Pagination v-if="blogData.items.last_page" :total-pages="blogData.items.last_page" :current-page="blogData.items.current_page"/>
      </div>
  </div>
</template>

<script>
import url from "@/mixins/url";
import BlogItem from '@/components/Blog/BlogItem.vue'
import Pagination from '../../components/Base-components/Pagination.vue'
import { getData } from "@/helpers/getData"
import {PAGE_INFO} from "../../store/action-types";

export default {
    mixins: [
        url
    ],
    data(){
        return{
            blogData: null
        }
    },
    computed:{
        pageUrl(){
            return this.goToUrl('blog');
        }
    },
    methods:{
        async fetchBlogs(){
            await getData({
                method: 'GET',
                url: 'blog',
                data: {
                    per_page: 6,
                    page: this.$route.query.page
                }
            })
            .then(({ data }) => {
                this.blogData = data;
            })
        },
        paginate(num){
            this.$router.replace({ path: this.pageUrl, 'query': { page: num } });
        }
    },
    watch:{
        '$i18n.locale'(){
            this.fetchBlogs();
        }
    },
    created(){
        // this.fetchBlogs();
    },
    components:{
        BlogItem,
        Pagination
    },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData({
      method: 'GET',
      url: `blog`,
      data: {
        per_page: 6,
        page: to.query.page
      }
    }).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.blogData = response.data;
        vm.$Progress.finish()
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.blog{
    margin-bottom: 60px;
    &__image{
        width: 100%;
        margin-top: -130px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width: 768px){
            margin-top: -100px;
        }
    }
    &__title{
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        margin-top: 20px;
    }
    &__items{
        width: 1100px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        .blog-item{
            flex: 0 1 30%;
            width: 100%;
            box-sizing: border-box;
            margin: 12px;
            margin-top: 40px;
            @media(max-width: 768px){
                flex-basis: 45%;
            }
            @media(max-width: 576px){
                flex-basis: 100%;
            }
        }
    }
    &__pagination{
        margin-top: 50px;
    }
}
.mobile-blog-image{
    display: none;
}
@media(max-width: 768px){
    .mobile-blog-image{
        display: block;
    }
    .desktop-blog-image{
        display: none;
    }
}
</style>
