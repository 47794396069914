<template>
  <div class="page page--leaderboard has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('leaderboard.title') }}</h1>

      <div class="page__roll">
        <router-link
            :to="leaderboardRoute"
            class="button button--primary button--pill is-flex align-items-center justify-content-center"
        >
          <i class="icon icon-arrow-line-left"></i>
        </router-link>

        <template v-if="!loading">
          <h4>{{ title }}</h4>
        </template>

        <template v-else>
          <placeholder
              width="100px"
              height="8px"
          ></placeholder>
        </template>
      </div>

      <template v-if="!loading">
        <ul class="list">
          <template v-for="(item,key) in items" :key="key">
            <li class="list__item">
              <div class="position">
                <span class="badge badge--primary badge--pill">{{ item.order_id }}</span>
              </div>
              <div class="name">
                <i class="iconly iconly-user-colored"></i> {{ item.user_name }}
              </div>
              <div class="prize">
                <i class="iconly iconly-trophy-colored"></i> {{ item.bonus }}
              </div>
              <div class="time">
                <i class="iconly iconly-time-colored"></i>
                <span class="show-md-up">
                {{ item.duration }}
              </span>
                <span class="show-md-and-down">
                {{ item.responsive_duration }}
              </span>
              </div>
            </li>
          </template>
        </ul>
      </template>

      <template v-else>
        <placeholder
            v-for="item in 20"
            :key="item"
            custom-class="leaderboard-placeholder"
            width="100%"
            height="92px"
        >
          <placeholder
              is="circle"
              width="32px"
              height="32px"
          ></placeholder>

          <placeholder
              width="15%"
              height="8px"
          ></placeholder>

          <placeholder
              width="15%"
              height="8px"
          ></placeholder>

          <placeholder
              width="15%"
              height="8px"
          ></placeholder>
        </placeholder>
      </template>
    </div>
  </div>
</template>

<script>
import {BRANCH_LEADERBOARD_LIST} from "@/store/mutation-types";
import {PAGE_INFO} from "@/store/action-types";
import Placeholder from "@/components/Placeholders/Placeholder";
import url from "@/mixins/url";

export default {
  name: 'branchLeaderboardInside',

  mixins: [
    url
  ],

  components: {
    Placeholder
  },

  data () {
    return {
      items: [],
      showItemsQty: 20,
      loading: true
    }
  },

  computed: {
    data () {
      return this.$store.getters.branchLeaderboardList
    },

    uniqueItems () {
      let items = this.$store.getters.branchLeaderboardList.items
      Object.keys(items).map((key) => {
        this.items.push(items[key])
      })
      return items;
    },

    title () {
      if (this.data && this.data.branch) {
        return this.$store.getters.branchLeaderboardList.branch.city_label
      }

      return ''
    },

    leaderboardRoute () {
      return this.goToUrl('branch/leaderboard')
    }
  },

  methods: {
    setShowItems (qty = 20) {
      let uniqueItems = {...this.uniqueItems}
      let itemsArray = Object.keys(uniqueItems).map((key) => uniqueItems[key]);

      if (qty == 20) {
        this.items = itemsArray.filter((item) => {
          return item.order_id <= 20;
        })
      } else if (qty == 50) {
        this.items = itemsArray.filter((item) => {
          return item.order_id > 20 && item.order_id <= qty;
        })
      }
      this.showItemsQty = qty

      this.$Progress.finish()

      this.loading = false
    },

    setLeaderboardData () {
      this.setShowItems();
    },

  },

  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch(BRANCH_LEADERBOARD_LIST,{
        branch_id: vm.$route.params.branchId
      }).then(response => {
        if (response.data){
          vm.setLeaderboardData(response.data)
        }
      })
      vm.$store.dispatch(PAGE_INFO, {
        path: 'leaderboard'
      })
    });
  },

  beforeRouteUpdate () {
    this.loading = true;

    this.$store.dispatch(BRANCH_LEADERBOARD_LIST,{
      branch_id: this.$route.params.branchId
    }).then(response => {
      if (response.data){
        this.setLeaderboardData(response.data)
      }
    })
    this.$store.dispatch(PAGE_INFO, {
      path: 'leaderboard'
    })
  }

}
</script>
