<template>
  <div class="slider slider--single">
    <carousel :options="options" :grid="1" v-if="slides.length">
      <slide
        v-for="(slide, index) in slides"
        :key="index"
        :slide="index"
        :class="slide.outsideType"
      >
        <template v-if="slide.actionUrl">
          <external-url :url="slide.actionUrl">
            <template v-if="!isVideo(slide)">
              <img :src="getImage(slide)" :class="slide.outsideType" :alt="slide.buttonTitle">
            </template>

            <template v-else>
              <video-player
                  :url="getImage(slide)"
              />
            </template>
          </external-url>
        </template>

        <template v-else>
          <template v-if="!isVideo(slide)">
            <img :src="getImage(slide)" :class="slide.outsideType" :alt="slide.buttonTitle">
          </template>

          <template v-else>
            <video-player
                :url="getImage(slide)"
            />
          </template>
        </template>

        <div class="carousel__content">
          <h3 class="slider__title" v-if="slide.title">{{ slide.title }}</h3>
          <template v-if="slide.description">
            <p class="slider__description" v-if="slide.description" v-html="slide.description"></p>
          </template>
          <template v-if="slide.buttonTitle">
            <!--<a :href="slide.buttonUrl" class="button button--white">{{ slide.buttonTitle }}</a>-->
          </template>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import Carousel from "@/components/Sliders/Slider/Carousel"
import Slide from "@/components/Sliders/Slider/Slide"
import VideoPlayer from "@/components/Video/VideoPlayer";
import ExternalUrl from "@/components/Link/ExternalUrl";

export default {
  components: {
    Carousel,
    Slide,
    VideoPlayer,
    ExternalUrl
  },
  props: {
    slides: Array
  },

  data: () => {
    return {
      options: {
        autoPlay: true,
        autoPlaySeconds: 10,
        arrows: true,
        paginationProgress: true,
        keyboard: true
      }
    }
  },

  computed: {
    isMobile () {
      return window.matchMedia("(max-width: 992px)").matches
    }
  },

  methods: {
    getImage (slide) {
      if (this.isMobile && slide.imageMobile) {
        return slide.imageMobile
      }

      return slide.image
    },

    isVideo (slide) {
      return slide.image.includes('.mp4')
    }
  }
}
</script>
