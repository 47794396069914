<template>
  <div class="carousel" ref="carousel">
    <div class="carousel__inner">
      <div class="carousel__track" ref="carouselTrack">
        <slot></slot>
      </div>
    </div>

    <navigation
        next-label=""
        prev-label=""
        v-if="options.arrows && slidesLength > 1"
    ></navigation>

    <pagination
        v-if="slidesLength > 1"
    ></pagination>
  </div>
  <!-- /.carousel -->
</template>

<script>
// mixins
import baseMixin from "@/mixins/base"

// components
import Navigation from "@/components/Sliders/Slider/Navigation"
import Pagination from "@/components/Sliders/Slider/Pagination"

export default {
  props: {
    grid: {
      type: Number
    }
  },

  components: {
    Navigation,
    Pagination
  },

  mixins: [
    baseMixin
  ],

  data () {
    return {
      options: {
        autoPlay: false,
        autoPlaySeconds: 3.5,
        arrows: false,
        paginationProgress: false,
        grid: 1,
        keyboard: false
      },
      slidesLength: 0,
      currentIndex: 0,
      interval: '',
      slideDirection: 'slide-right'
    }
  },

  watch: {
    currentIndex () {
      clearInterval(this.interval)

      if (this.options.autoPlay) {
        this.autoPlay()
      }

      this.$refs.carouselTrack.style.transform = 'translateX(-' + this.transformWidth + ')'
    }
  },

  computed: {
    /**
     *
     * @returns {HTMLCollectionOf<Element>}
     */
    getSlides () {
      return this.getByClassName('carousel', 'carousel__slide')
    },

    /**
     *
     * @returns {Element}
     */
    getCurrentSlide () {
      return this.getSlides[this.currentIndex]
    },

    transformWidth () {
      if (this.grid && this.grid > 1 && window.innerWidth > 992) {
        return (this.$refs.carousel.clientWidth / this.grid * this.currentIndex) + 'px'
      }

      return this.$refs.carousel.clientWidth  * this.currentIndex + 'px'
    },

    trackWidth () {
      if (this.grid > 1 && window.innerWidth > 992) {
        return (this.getSlides.length * this.$refs.carousel.clientWidth / this.grid) + 'px'
      }

      return (this.getSlides.length * this.$refs.carousel.clientWidth) + 'px'
    },

    hasArrows () {
      return this.options.arrows
    }
  },

  methods: {
    /**
     *
     */
    changeSlide (direction = 'slide-right') {
      if (direction === 'slide-left') {
        this.currentIndex--;

        if(this.currentIndex < 0){
          this.currentIndex = this.getSlides.length - 1;
        }
      } else {
        this.currentIndex++

        if(this.currentIndex >= this.getSlides.length) {
          this.currentIndex = 0;
        }
      }

      this.slideDirection = direction
    },

    /**
     *
     */
    autoPlay () {
      this.interval = setInterval(this.changeSlide, this.options.autoPlaySeconds * 1000)

      return this.interval
    }
  },

  mounted() {
    this.$refs.carouselTrack.style.width = this.trackWidth

    this.slidesLength = this.getSlides.length

    this.options = {
      autoPlay: this.$parent.options.autoPlay
          ? this.$parent.options.autoPlay
          : this.options.autoPlay,
      autoPlaySeconds: this.$parent.options.autoPlaySeconds
          ? this.$parent.options.autoPlaySeconds
          : this.options.autoPlaySeconds,
      arrows: this.$parent.options.arrows
          ? this.$parent.options.arrows
          : this.options.arrows,
      paginationProgress: this.$parent.options.paginationProgress
          ? this.$parent.options.paginationProgress
          : this.options.paginationProgress,
      grid: this.$parent.options.grid
          ? this.$parent.options.grid
          : this.options.grid,
      keyboard: this.$parent.options.keyboard
          ? this.$parent.options.keyboard
          : this.options.keyboard
    }

    if (this.options.autoPlay) {
      this.autoPlay()
    }

    if (this.options.keyboard) {
      const vm = this

      window.addEventListener('keydown', function(e) {
        if (e.code === 'ArrowRight') {
          vm.changeSlide()
        } else if (e.code === 'ArrowLeft') {
          vm.changeSlide('slide-left')
        }
      })
    }
  }
}
</script>
