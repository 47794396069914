<template>
  <div class="header__account is-flex">
    <template v-if="loggedIn">
      <div class="header__account-additions show-md-up">
        <router-link
            :to="promoRoute"
            class="button button--danger button--pill text-uppercase"
        >
          {{ $t('headerAccount.promoIcon') }}
        </router-link>
      </div>

<!--      <div class="header__account-promotions">-->
<!--        <ul class="nav nav&#45;&#45;horizontal">-->
<!--          <li class="nav__item is-dropdown has-bullet is-clickable" :class="showPromotions">-->
<!--            <button type="button" class="nav__link" @click="togglePromotions">-->
<!--              <div>-->
<!--                <span class="badge badge&#45;&#45;primary badge&#45;&#45;pill nav__icon">-->
<!--                  <i class="icon icon-bell"></i>-->
<!--                </span>-->
<!--              </div>-->
<!--            </button>-->

<!--            <div class="nav__dropdown header__dropdown" v-if="hasPromotions">-->
<!--              <ul class="nav">-->
<!--                <li class="nav__item">-->
<!--                  <router-link to="/en/" class="nav__link">-->
<!--                    You have new been deposited by 250 <strong>Freespins</strong>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                &lt;!&ndash; /.nav__item &ndash;&gt;-->

<!--                <li class="nav__item">-->
<!--                  <router-link to="/en/" class="nav__link">-->
<!--                    You have new been deposited by 250 <strong>Freespins</strong>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                &lt;!&ndash; /.nav__item &ndash;&gt;-->
<!--              </ul>-->
<!--            </div>-->
<!--          </li>-->
<!--          &lt;!&ndash; /.nav__item &ndash;&gt;-->
<!--        </ul>-->
<!--      </div>-->

      <div class="header__account-user" v-if="!isVerified" v-click-away="hide">
        <ul class="nav nav--horizontal">
          <li class="nav__item is-dropdown is-clickable" :class="showProfile">
            <button type="button" class="nav__link" @click="toggleProfile">
              <div class="is-flex align-items-center">
                <span class="badge badge--primary badge--pill nav__icon">
                  <i class="icon icon-user"></i>
                </span>

                <div class="hide-md-and-down">
                  <span class="text-uppercase text-left">{{ this.$t('headerAccount.welcome') }}</span>
                  {{ getUserName() }}.
                </div>
              </div>
            </button>

            <div class="nav__dropdown header__dropdown" v-if="hasProfile">
              <div class="nav__dropdown-header show-md-and-down">
                <div class="nav__dropdown-title">{{ $t('headerAccount.myProfile') }}</div>
                <button class="header__burger-close" @click="toggleProfile">
                  <i class="icon icon-close"></i>
                </button>
              </div>

              <div class="nav__dropdown-text show-md-and-down">Hello, {{ getUserName() }}</div>

              <div class="card header__balance">
                <div class="card__body">
                  <carousel :options="options" :grid="1">
                    <slide
                        :slide="0"
                    >
                      <span class="badge badge--success badge--opacity badge--pill">
                        <i class="iconly iconly-cards"></i>
                      </span>

                      <div class="card__info has-two-row">
                        <h6 class="card__title">
                          {{ $t('headerAccount.yourBalance') }}
                        </h6>
                        <template v-if="!loading">
                          <p class="card__text">{{ gel.amount }} ₾</p>
                          <p class="card__text is-small">{{ $t('headerAccount.freeSpin') }}: {{ gel.restricted }} ₾</p>
                        </template>

                        <template v-else>
                          <div class="is-flex">
                            <placeholder
                                width="26px"
                                height="10px"
                            ></placeholder>

                            <placeholder
                                width="52px"
                                height="10px"
                            ></placeholder>
                          </div>
                        </template>
                      </div>

<!--                      <button type="button" class="button button&#45;&#45;danger button&#45;&#45;pill">-->
<!--                        <i class="icon icon-plus"></i>-->
<!--                      </button>-->
                    </slide>

                    <slide
                        :slide="1"
                    >
                      <span class="badge badge--warning badge--opacity badge--pill">
                        <i class="iconly iconly-bank"></i>
                      </span>

                      <div class="card__info has-two-row">
                        <h6 class="card__title">{{ $t('headerAccount.bonusBalance') }}</h6>
                        <template v-if="!loading">
                          <p class="card__text">{{ point.amount }} ₾</p>
                          <p class="card__text is-small">{{ $t('headerAccount.freeSpin') }}: {{ point.restricted }} ₾</p>
                        </template>

                        <template v-else>
                          <div class="is-flex">
                            <placeholder
                                width="26px"
                                height="10px"
                            ></placeholder>

                            <placeholder
                                width="52px"
                                height="10px"
                            ></placeholder>
                          </div>
                        </template>
                      </div>
                    </slide>

                    <slide
                        :slide="3"
                    >
                      <span class="badge badge--danger badge--opacity badge--pill">
                        <i class="iconly iconly-gift"></i>
                      </span>

                      <div class="card__info">
                        <h6 class="card__title">{{ $t('headerAccount.barBalance') }}</h6>
                        <template v-if="!loading">
                          <p class="card__text">{{ barPoint.amount }} ₾</p>
                          <template v-if="barPoint.restricted">{{ barPoint.restricted }}</template>
                        </template>

                        <template v-else>
                          <div class="is-flex">
                            <placeholder
                                width="26px"
                                height="10px"
                            ></placeholder>

                            <placeholder
                                width="52px"
                                height="10px"
                            ></placeholder>
                          </div>
                        </template>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>

<!--              <router-link to="/" class="button button&#45;&#45;danger show-md-and-down">Deposit</router-link>-->

              <ul class="nav nav--vertical header__burger-nav">
                <li class="nav__item">
                  <router-link :to="accountRoute" class="nav__link">
                    <i class="iconly iconly-profile"></i> {{ $t('headerAccount.profile') }}
                  </router-link>
                </li>
                <!-- /.nav__item -->

                <li class="nav__item">
                  <router-link :to="cardRoute" class="nav__link">
                    <i class="iconly iconly-doc"></i> {{ $t('headerAccount.myCard') }}
                  </router-link>
                </li>
                <!-- /.nav__item -->

<!--                <li class="nav__item">-->
<!--                  <router-link to="/en/account" class="nav__link">-->
<!--                    <i class="iconly iconly-wallet"></i> {{ $t('headerAccount.manageBalance') }}-->
<!--                  </router-link>-->
<!--                </li>-->
                <!-- /.nav__item -->
              </ul>

              <button class="button button--primary button--outline button--block header__logout" @click="logout">{{ $t('headerAccount.logout') }}</button>
            </div>
          </li>
          <!-- /.nav__item -->
        </ul>
      </div>
      <!--<router-link to="/en/account" class="page__link">{{ getName }}</router-link>
      <button class="button button--primary button--pill" @click="logout">{{ $t('headerAccount.logout') }}</button>-->
    </template>

    <template v-else>
      <button type="button" class="nav__link show-md-and-down" @click="loginModal">
        <div class="is-flex align-items-center">
          <i class="icon icon-user"></i>
        </div>
      </button>

      <button class="button button--primary button--pill show-md-up" @click="loginModal">{{ $t('headerAccount.signIn') }}</button>
      <button class="button button--secondary button--pill show-md-up" @click="registerModal">{{ $t('headerAccount.singUp') }}</button>
    </template>
  </div>
  <!-- /.header__account -->
</template>

<script>
// plugins
import Cookie from "js-cookie";
import emitter from "@/plugins/bus"
import {USER_DATA, USER_LOGOUT} from "@/store/action-types";

// mixins
import { mixin as VueClickAway } from "vue3-click-away";
import scroll from "@/mixins/scroll";

// components
import Carousel from "@/components/Sliders/Slider/Carousel";
import Slide from "@/components/Sliders/Slider/Slide";
import Placeholder from "@/components/Placeholders/Placeholder";
import url from "@/mixins/url";

export default {
  mixins: [
    scroll,
    VueClickAway,
    url
  ],

  components: {
    Carousel,
    Slide,
    Placeholder
  },

  data () {
    return {
      options: {
        autoPlay: false,
        arrows: false,
        paginationProgress: false
      },
      loading: false,
      hasProfile: false,
      hasPromotions: false,
      balances: {
      },
      user: {}
    }
  },

  watch: {
    '$route' () {
      this.hasProfile = false
      this.hasPromotions  = false
    },

    'hasProfile' () {
      if (this.hasProfile) {
        this.disableMobileScroll()
        this.getUserBalances();
      } else {
        this.enableScroll()
      }
    },

    loggedIn (value) {
      if (!value) {
        localStorage.removeItem('user_data')
      }
    }
  },

  computed: {
    gel () {
      return this.balances && this.balances.gel ? this.balances.gel : {}
    },
    point () {
      return this.balances && this.balances.point ? this.balances.point : {}
    },
    barPoint () {
      return this.balances && this.balances.bar_point ? this.balances.bar_point : {}
    },
    /**
     * Check logged in status
     */
    loggedIn () {
      return this.$store.getters.userToken || Cookie.get('token')
    },

    showProfile () {
      return this.hasProfile ? 'active' : ''
    },

    showPromotions () {
      return this.hasPromotions ? 'active' : ''
    },

    getName () {
      return this.userData && this.userData.first_name ? this.userData.first_name + ' ' + this.userData.last_name.substring(0, 1) : ''
    },

    getFirstName () {
      return this.$store.getters.userData && this.$store.getters.userData.first_name ? this.$store.getters.userData.first_name : ''
    },

    userData () {
      return this.$store.getters.userData;
    },

    accountRoute () {
      return this.goToUrl('account')
    },

    cardRoute () {
      return this.goToUrl('account/card')
    },

    promoRoute () {
      return this.userData && this.userData.config && this.userData.config.promo_url ? this.goToUrl(this.userData.config.promo_url) : ''
    },

    isVerified () {
      return this.userData ? this.userData.verified_at === null : true
    }
  },

  methods: {
    async getUserBalances () {
      this.loading = true;
      this.setBalance();

      await this.$store.dispatch(USER_DATA).then(response => {
        if (response.data) {
          this.setBalance(response.data.customer.balances)
          this.loading = false;
        }
      });

      if (!this.loading) {
        this.$Progress.finish();
      }
    },

    getUserName () {
      const user = this.$store.getters.userData

      return user && user.first_name ? user.first_name + ' ' + user.last_name.substring(0, 1) : ''
    },

    setBalance (balances = null) {
      if (balances) {
        this.balances = balances
      } else {
        this.balances = this.$store.getters.userBalances
      }
    },

    /**
     * Show login modal
     */
    loginModal () {
      emitter.emit('showLoginModal')
    },

    /**
     * Show login modal
     */
    registerModal () {
      emitter.emit('showRegisterModal')
    },

    toggleProfile () {
      this.hasPromotions = false
      emitter.emit('showProfileDrop')
    },

    togglePromotions () {
      this.hasProfile = false
      emitter.emit('showPromDrop')
    },

    hide () {
      this.hasProfile = false
      this.$Progress.finish()
    }
,
    async logout () {
      await this.$store.dispatch(USER_LOGOUT).then(() => {
        this.$Progress.finish();
        this.hasProfile = false;
        this.$router.push({name: 'Home'})
      });
    }
  },

  mounted() {
    emitter.on('showProfileDrop', () => this.hasProfile = !this.hasProfile )
    emitter.on('showPromDrop', () => this.hasPromotions = !this.hasPromotions )
  }
}
</script>
