/**
 *
 * @param response
 * @param page
 * @returns {*}
 */
export const getStaticPage = ( response, page) => {
  if (response.data && response.data.items) {
    if (page) {
      return response.data.items[page]
    }

    return response.data.items
  }

  return null
}
