<template>
  <div class="page page--provider has-bg">
    <div class="container">
      <div class="page__item" v-for="item in data" :key="item.id">
        <div class="page__item-info">
          <h1 class="page__title">{{ item.fields.title.value }}</h1>
          <p class="page__text" v-html="item.fields.sub_title.value"></p>
        </div>

        <div class="page__item-image">
          <img :src="item.fields.image.value">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import {getData} from "@/helpers/getData"
import {getStaticPage} from "@/helpers/module"
import {PROVIDER_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";
import scroll from "@/mixins/scroll";

export default {
  name: 'Provider',

  mixins: [
      scroll
  ],

  components: {
  },

  data: () => {
    return {
      data: []
    }
  },

  computed: {
    getTitle () {
      return this.data.fields && this.data.fields.title ? this.data.fields.title.value : ''
    },

    getSubTitle () {
      return this.data.fields && this.data.fields.sub_title ? this.data.fields.sub_title.value : ''
    },

    getBackImage () {
      return this.data.fields && this.data.fields.image ? this.data.fields.image.value : ''
    }
  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      const pageData = getStaticPage(data, false)

      this.data = pageData.provider
    }
  },

  beforeRouteEnter: (to, from, next) => {
    getData(PROVIDER_ROUTE).then(response => {
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.setData(response)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    getData(PROVIDER_ROUTE).then(response => {
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.setData(response)
      this.$Progress.finish()
    })
  }
}
</script>
