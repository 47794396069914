<template>
  <div class="page page--about has-bg">
    <div class="container">
      <h1 class="page__title text-center">{{ $t('about.title') }}</h1>

      <template v-if="getFirstDescription">
        <div class="page__text d-block" v-html="getFirstDescription"></div>
      </template>

      <single-carousel
        :slides="getSlides"
      ></single-carousel>

      <template v-if="getSecondDescription">
        <div class="page__text text-center" v-html="getSecondDescription"></div>
      </template>

      <template v-if="getWorkTitle">
        <span class="badge badge--danger badge--pill page__badge">{{ getWorkTitle }}</span>
      </template>

      <template v-if="getWorkValue">
        <p class="text-center font-weight-bold">{{ getWorkValue }}</p>
      </template>
    </div>

    <div class="page--about__map">
      <about-map v-if="getAllCoordinates.length" :coordinates="getAllCoordinates"></about-map>
      <div class="container" v-if="getContacts.length">
        <contact-card :default-contact-data="getDefaultContactData" :contact-data="getContacts"></contact-card>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import {getStaticPage} from "@/helpers/module"
import {getData} from "@/helpers/getData"

// components
import SingleCarousel from "@/components/Sliders/SingleCarousel"
import aboutMap from "@/components/Elements/Map/Map"
import ContactCard from "@/components/Cards/Home/ContactCard"
import {ABOUT_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/mutation-types";

export default {
  name: 'About',

  components: {
    SingleCarousel,
    aboutMap,
    ContactCard
  },

  data () {
    return {
      defaultContactData: {},
      data: {},
      contactData: {}
    }
  },

  computed: {

    getSlides(){
      if (this.data && this.data.fields && this.data.fields.images.value.length) {
        return this.data.fields.images.value.map((imageData) => {
          return {
            image: imageData.full_src ? imageData.full_src.full_src : ''
          };
        });
      }

      return [];
    },

    /**
     *
     * Get title
     */
    getFirstDescription () {
      if (this.data && this.data.fields) {
        return this.data.fields.first_description.value
      }

      return null
    },

    /**
     *
     * Get text
     */
    getSecondDescription () {
      if (this.data && this.data.fields) {
        return this.data.fields.second_description.value
      }

      return null
    },

    /**
     *
     * Get text
     */
    getWorkTitle () {
      if (this.data && this.data.fields) {
        return this.data.fields.work_title.value
      }

      return null
    },

    /**
     *
     * Get text
     */
    getWorkValue () {
      if (this.data && this.data.fields) {
        return this.data.fields.work_value.value
      }

      return null
    },

    getAllCoordinates () {
      var coordinates = [];

      Object.keys(this.contactData).map((key) => {
        var fields = this.contactData[key];
        coordinates.push({
          contactId: fields.id,
          location: fields.coordinates
        });
      })

      return coordinates;
    },

    getContacts () {
      var contactData = [];

      Object.keys(this.contactData).map((key) => {
        var fields = this.contactData[key];
        contactData.push({
          title: fields.title,
          address: fields.address
        });
      })

      return contactData;
    },

    getDefaultContactData () {
      if (this.defaultContactData && this.defaultContactData.fields) {
        return {
          phones: this.defaultContactData.fields.phones.value,
          emails: this.defaultContactData.fields.emails.value
        }
      }

      return {}
    }

  },

  methods: {
    /**
     *
     * @param data
     */
    setData (data) {
      this.data = getStaticPage(data, 'about')[0]
      this.contactData = data.data.branches;
      this.defaultContactData = data.data.items['default_contact'][0]
    },
  },

  mounted() {
    document.getElementsByTagName('body')[0].classList.add('overflow-x-hidden')
  },

  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(ABOUT_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.setData(response)
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    /**
     * Send request
     */
    getData(ABOUT_ROUTE).then(response => {
      /**
       * Enter page and set data
       */
      this.setData(response)
      this.$store.commit(PAGE_INFO, response.data.page_data)
      this.$Progress.finish()
    })
  }
}
</script>
