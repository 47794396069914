<template>
  <modal
      :title="$t('register.title')"
      custom-class="register-modal modal__scroll"
      v-if="visible"
  >
    <template v-slot:title>
      <div class="modal__subtitle show-md-up">
        Do you have an account? <button type="button" class="button button--link" @click="goToSignIn">Sign in</button>
      </div>
    </template>
    <div class="modal__title show-md-and-down text-center">
      Create new account
    </div>

    <div class="modal__message show-md-and-down">
      Do you have an account? <button type="button" class="button button--link" @click="goToSignIn">Sign in</button>
    </div>

    <form @keyup.enter="signUp" class="form">
      <error-text :error-text="generalError" v-if="generalError"></error-text>
      <div class="row">
        <div class="col-12">
          <div class="form__group" :class="validationErrors.email ? 'has-error' : ''">
            <input v-model="form.email" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.email')">
          </div>

          <template v-if="validationErrors.email">
            <validation-texts :errors="validationErrors.email"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.id_number ? 'has-error' : ''">
            <input v-model="form.id_number" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.idNumber')">
          </div>

          <template v-if="validationErrors.id_number">
            <validation-texts :errors="validationErrors.id_number"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.phone_number ? 'has-error' : ''">
            <input v-model="form.phone_number" type="text" class="form__control" :placeholder="$t('register.mobileNumber')">
          </div>

          <template v-if="validationErrors.phone_number">
            <validation-texts :errors="validationErrors.phone_number"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.first_name ? 'has-error' : ''">
            <input v-model="form.first_name" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.firstName')">
          </div>

          <template v-if="validationErrors.first_name">
            <validation-texts :errors="validationErrors.first_name"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.last_name ? 'has-error' : ''">
            <input v-model="form.last_name" type="text" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.lastName')">
          </div>

          <template v-if="validationErrors.last_name">
            <validation-texts :errors="validationErrors.last_name"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.birth_date ? 'has-error' : ''">
            <datepicker
                v-model="datePicker"
                placeholder="Birth Date"
                inputFormat="yyyy-MM-dd"
            />
          </div>

          <template v-if="validationErrors.birth_date">
            <validation-texts :errors="validationErrors.birth_date"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.gender_id ? 'has-error' : ''">
            <Multiselect
                v-model="form.gender_id"
                :options="options.gender"
                :placeholder="$t('register.gender')"
            />
          </div>

          <template v-if="validationErrors.gender_id">
            <validation-texts :errors="validationErrors.gender_id"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.password ? 'has-error' : ''">
            <input v-model="form.password" type="password" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.password')">
          </div>

          <template v-if="validationErrors.password">
            <validation-texts :errors="validationErrors.password"></validation-texts>
          </template>
        </div>

        <div class="col-6">
          <div class="form__group" :class="validationErrors.password_confirmation ? 'has-error' : ''">
            <input v-model="form.password_confirmation" type="password" class="form__control" @keypress="validateInput($event)" :placeholder="$t('register.repeatPassword')">
          </div>

          <template v-if="validationErrors.password_confirmation">
            <validation-texts :errors="validationErrors.password_confirmation"></validation-texts>
          </template>
        </div>

        <div class="col-6" v-if="countryData">
          <div class="form__group" :class="validationErrors.country_id ? 'has-error' : ''">
            <Multiselect
                v-model="form.country_id"
                :options="countryData"
                :placeholder="$t('register.country')"
                :can-deselect="false"
                id="selectCountry"
                @change="handleCountryChange"
            />
          </div>

          <template v-if="validationErrors.country_id">
            <validation-texts :errors="validationErrors.country_id"></validation-texts>
          </template>
        </div>

        <div class="col-6" v-if="cityData">
          <div class="form__group" :class="validationErrors.city_id ? 'has-error' : ''">
            <Multiselect
                v-model="form.city_id"
                :options="cityData"
                :placeholder="$t('register.city')"
                id="selectCity"
            />
          </div>

          <template v-if="validationErrors.city_id">
            <validation-texts :errors="validationErrors.city_id"></validation-texts>
          </template>
        </div>

        <div class="col-12">
          <div class="form__group" :class="validationErrors.terms_conditions ? 'has-error' : ''">
            <label class="checkbox">
              {{ $t('register.terms.text') }}
              <input type="checkbox" v-model="form.terms_conditions" value="0">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <button type="button" class="button button--danger button--block register-modal__submit" @click="signUp">{{ $t('register.buttonTitle') }}</button>
    </form>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'
import { ref } from 'vue'
import { add } from 'date-fns'

// components
import Modal from "@/components/Modals/Modal"
import Datepicker from "vue3-datepicker"
import {getData} from "@/helpers/getData";
import {getValidationsTextWithKey} from "@/helpers/responseError";
import ValidationTexts from "@/components/Error/ValidationTexts";
import ErrorText from "@/components/Error/ErrorText";
import {USER_CONFIRMATION, USER_REGISTER_FORM} from "@/store/action-types";
import Multiselect from "@vueform/multiselect";
import form from "@/mixins/form";

export default {
  components: {
    ValidationTexts,
    Modal,
    Datepicker,
    ErrorText,
    Multiselect
  },

  mixins: [
    form
  ],

  data () {
    return {
      visible: false,
      loading: false,
      datePicker: '',
      countryData: [],
      cityData: [],
      form: {
        email: '',
        last_name: '',
        first_name: '',
        password: '',
        password_confirmation: '',
        id_number: '',
        phone_number: '',
        birth_date: '',
        terms_conditions: 0,
        country_id: '',
        city_id: '',
        gender_id: ''
      },
      validationErrors: {},
      generalError: '',
      options: {}
    }
  },

  watch: {
    'visible' () {
      if (this.visible) {
        this.getRegisterData();
        this.setOldData();
      }
    },

    'datePicker' (value) {
      this.form.birth_date = ref(add(value, {}).toLocaleDateString())
    }
  },

  methods: {
    signUp () {
      this.resetErrorData();
      this.$store.dispatch(USER_REGISTER_FORM, this.form);

      this.loading = true;
      getData({
        method: 'POST',
        url: 'customer/auth/register',
        data: this.form
      }).then(response => {
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
        } else if (response.status == 500) {
          this.generalError = response.data.message;
        } else if (response.code < 400) {
          let registerData = this.$store.getters.userRegisterForm;
          registerData.verify = response.data.verify;
          this.$store.dispatch(USER_REGISTER_FORM, registerData);
          this.visible = false;
          localStorage.setItem('verification', 'N')
          this.showConfirmModal();
        }
        this.$Progress.finish()
      })
      this.loading = false;
    },

    async getRegisterData () {
      this.loading = true;
      getData({
        method: 'GET',
        url: 'customer/auth/register-data',
      }).then(response => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return;
        } else if (response.status == 500) {
          this.generalError = response.data.message;
          return;
        }
        this.options = response.data.options;

        this.countryData = this.options.countries
        this.cityData = this.options.cities
      })
      this.loading = false;
    },

    setOldData () {
      if (this.$store.getters.userRegisterForm) {
        Object.keys(this.$store.getters.userRegisterForm).map((key) => {
          this.form[key] = this.$store.getters.userRegisterForm[key];
        });
      }
    },

    resetErrorData () {
      this.generalError = '';
      this.validationErrors = {}
    },

    showConfirmModal () {
      this.$store.dispatch(USER_CONFIRMATION, { openManual: false })
      emitter.emit('showConfirmModal')
    },

    goToSignIn () {
      this.visible = false;
      emitter.emit('showLoginModal');
    }
  },

  mounted() {
    emitter.on('showRegisterModal', () => this.visible = true)
  }
}
</script>
