<template>
  <div class="page page--gallery has-bg">
    <div class="container">
      <div class="page__roll">
<!--        <router-link-->
<!--            to="/stream"-->
<!--            class="button button&#45;&#45;primary button&#45;&#45;pill is-flex align-items-center justify-content-center"-->
<!--        >-->
<!--          <i class="icon icon-arrow-line-left"></i>-->
<!--        </router-link>-->

        <template v-if="!loading">
          <h4>{{ $t('streams.title') }}</h4>
        </template>

        <template v-else>
          <placeholder
              width="100px"
              height="8px"
          ></placeholder>
        </template>
      </div>

      <div class="stream-container" v-if="$route.params.branchId === '2'">
        <iframe width="100%" height="506" src="https://webstream.betlive.com/BLC-GLDANI-JACKPOT/embed.html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <!--<carousel :options="options" v-if="slides.length">
        <slide
            v-for="(slide, index) in slides"
            :key="index"
            :slide="index"
        >

        </slide>
      </carousel>-->
    </div>
  </div>
</template>

<script>
// import Carousel from "@/components/Sliders/Slider-3d/Carousel"
// import Slide from "@/components/Sliders/Slider-3d/Slide"
import {getData} from "@/helpers/getData";
import {GALLERY_ROUTE} from "@/router/constants";
import {PAGE_INFO} from "@/store/action-types";

export default {
  name: 'Gallery',

  components: {
    // Carousel,
    // Slide
  },

  data: () => {
    return {
      loading: false,
      slides: [],
      sampleImg: 'https://cms-betlive.perse.pro/storage/gallery_image/51/pl9p86BqTtrf7n0FWmszPdpxtBzvpnjaXK3ikGsx.jpg',
      sampleVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
    }
  },

  computed: {
    data () {
      return this.$store.getters.branchLeaderboardList
    },

    title () {
      if (this.data && this.data.branch) {
        return this.$store.getters.branchLeaderboardList.branch.city_label
      }

      return ''
    },
  },

  methods: {
    setData () {
      if (this.$route.params.branchId === '2') {
        this.slides.push({
          url: 'https://webstream.betlive.com/BLC-GLDANI-JACKPOT/embed.html'
        });
      }

    }
  },
  beforeRouteEnter: (to, from, next) => {
    /**
     * Send request
     */
    getData(GALLERY_ROUTE).then(response => {
      next(vm => {
        vm.$store.commit(PAGE_INFO, response.data.page_data)
        vm.setData()
        vm.$Progress.finish()
      })
    })
  },

  beforeRouteUpdate () {
    this.setData()
  }
}
</script>
