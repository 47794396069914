<template>
  <div class="carousel__navigation">
    <button
        class="carousel__arrow carousel__arrow--prev"
        @click="showPrev"
    >
      <i class="icon icon-arrow-left"></i>
    </button>
    <!-- /.carousel__arrow -->

    <button
        class="carousel__arrow carousel__arrow--next"
        @click="showNext"
    >
      <i class="icon icon-arrow-right"></i>
    </button>
    <!-- /.carousel__arrow -->
  </div>
  <!-- /.carouse__navigation -->
</template>

<script>
export default {
  props: [
    'nextLabel',
    'prevLabel'
  ],

  methods: {
    /**
     *
     */
    showNext () {
      this.$parent.currentIndex++

      if (this.$parent.grid > 1) {
        if(this.$parent.currentIndex >= this.$parent.getSlides.length - 1){
          this.$parent.currentIndex = 0;
        }
      } else {
        if(this.$parent.currentIndex >= this.$parent.getSlides.length){
          this.$parent.currentIndex = 0;
        }
      }

      this.$parent.slideDirection = 'slide-right'
    },

    /**
     *
     */
    showPrev () {
      this.$parent.currentIndex--
      if (this.$parent.grid > 1) {
        if(this.$parent.currentIndex < 0) {
          this.$parent.currentIndex = this.$parent.getSlides.length - 2
        }
      } else {
        if(this.$parent.currentIndex < 0) {
          this.$parent.currentIndex = this.$parent.getSlides.length - 1
        }
      }

      this.$parent.slideDirection = 'slide-left'
    }
  }
}
</script>
