<template>
  <modal
    :title="$t('subscribe.unsubscribeTitle')"
    custom-class="unsubscribe-modal"
    v-if="visible">
    <error-text :error-text="generalError"></error-text>
    <form class="form" @keyup.enter="unsubscribe" v-if="!success">
      <div class="form__group" :class="validationErrors.email ? 'has-error' : ''">
        <input v-model="form.email" type="text" class="form__control" :placeholder="$t('subscribe.email')">
      </div>

      <validation-texts :errors="validationErrors.email"></validation-texts>

      <button type="button" class="button button--danger button--block login-modal__submit" @click="unsubscribe">
        {{  $t('subscribe.unsubscribeButtonTitle') }}</button>
    </form>

    <template v-else>
      {{ successMessage }}
    </template>
  </modal>
</template>

<script>
// plugins
import emitter from '@/plugins/bus'

// components
import Modal from "@/components/Modals/Modal"
import {getData} from "@/helpers/getData";
import ErrorText from "@/components/Error/ErrorText";
import ValidationTexts from "@/components/Error/ValidationTexts";
import {getValidationsTextWithKey} from "@/helpers/responseError";

export default {
  components: {
    ValidationTexts,
    ErrorText,
    Modal
  },

  data () {
    return {
      visible: false,
      success: false,
      form: {
        phone_number: '',
        password: ''
      },
      validationErrors: {},
      generalError: '',
      successMessage: ''
    }
  },

  methods: {
    async unsubscribe () {
      /**
       * Send request
       */
      await getData({
        method: 'POST',
        url: 'subscribe/disable',
        data: this.form
      }).then((response) => {
        this.$Progress.finish()
        if (response.status == 422) {
          this.validationErrors = getValidationsTextWithKey(response.data.errors);
          return
        } else if (response.status > 400) {
          this.generalError = response.data.message
          return
        }

        this.successMessage = response.message
        this.success = true
      })
    }
  },

  mounted() {
    emitter.on('showUnsubscribeModal', () => this.visible = true)
  }
}
</script>
