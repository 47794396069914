import Cookie from 'js-cookie'
import * as types from '@/store/mutation-types'
import { COOKIE_KEY__USER_TOKEN } from '@/store/constants'

export default {
  /**
   * Save User Token
   *
   * @param state
   * @param token
   * @param saveInCookie
   */
  [types.USER_TOKEN] (state, { token }) {
    state.userToken = token

    if (token) {
      Cookie.set(COOKIE_KEY__USER_TOKEN, token)
    } else {
      Cookie.remove(COOKIE_KEY__USER_TOKEN)
    }
  },

  [types.USER_DATA] (state, userData) {
    state.userData = userData
  },

  [types.USER_LOGOUT] (state, userLogout) {
    state.userLogout = userLogout
  },

  [types.USER_REGISTER_FORM] (state, userRegisterForm) {
    state.userRegisterForm = userRegisterForm
  },

  [types.USER_CONFIRMATION] (state, userConfirmation) {
    state.userConfirmation = userConfirmation
  }

}
