/**
 *
 * @param colors
 */
export const setPromoPageColors = (colors) => {
  setHeaderFixedColor(colors?.headerFixedColor);
  setHeaderNavColors(colors?.contentColor);
  setAuthButtonColors(
    {
      bg: colors?.primary,
      text: colors?.primaryTextColor
    },
    {
      bg: colors?.secondary,
      text: colors?.secondaryTextColor
    }
  );
  setCssVariableColor('--lineColor', colors?.primary);
}

/**
 *
 * @param hex
 */
export const setHeaderFixedColor = (hex) => {
  document.addEventListener('scroll', () => {
    const header = document.querySelector('.header.is-fixed');

    if (window.scrollY > 80) {
      header.style = `background-color: ${hex}`;
    } else {
      header.removeAttribute('style')
    }
  });
}

/**
 *
 * @param hex
 */
export const setHeaderNavColors = (hex) => {
  const headerNavItems = document.querySelectorAll('.header .nav > .nav__item');

  headerNavItems.forEach((headerNavItem) => {
    headerNavItem.style = `color: ${hex}`;
    headerNavItem.querySelector('.nav__link').style = `color: ${hex}`;
  })
}

/**
 *
 * @param primaryHex
 * @param secondaryHex
 */
export const setAuthButtonColors = (primaryHex, secondaryHex) => {
  const primaryButton = document.querySelector('.header .button--secondary');
  const secondaryButton = document.querySelector('.header .button--primary');

  primaryButton.style = `background: ${primaryHex?.bg}; box-shadow: 0 0 1.25rem 0 ${primaryHex?.bg}; color: ${primaryHex?.text}`;
  secondaryButton.style = `background: ${secondaryHex?.bg}; color: ${secondaryHex?.text}`;
}

/**
 *
 * @param name
 * @param hex
 */
export const setCssVariableColor = (name, hex) => {
  document.documentElement.style.setProperty(name, hex)
}

/**
 *
 * @param color
 * @param amount
 * @returns {string}
 */
export const lightenDarkenColor = (color, amount) => {
  let colorWithoutHash = color.replace("#", "")
  if (colorWithoutHash.length === 3) {
    colorWithoutHash = colorWithoutHash
      .split("")
      .map(c => `${c}${c}`)
      .join("")
  }

  const getColorChannel = substring => {
    let colorChannel = parseInt(substring, 16) + amount
    colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16)

    if (colorChannel.length < 2) {
      colorChannel = `0${colorChannel}`
    }

    return colorChannel
  }

  const colorChannelRed = getColorChannel(colorWithoutHash.substring(0, 2))
  const colorChannelGreen = getColorChannel(colorWithoutHash.substring(2, 4))
  const colorChannelBlue = getColorChannel(colorWithoutHash.substring(4, 6))

  return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`
}
